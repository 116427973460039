import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { AppContext } from "../../contexts";
import { appTypes } from "../../reducers/types";
import CirculationCardServiceMainSection from "../../component/sections/CirculationCardServiceMainSection";
import EnvironmentalSanctionServiceMainSection from "../../component/sections/EnvironmentalSanctionServiceMainSection";
import FotocivicasServiceMainSection from "../../component/sections/FotocivicasServiceMainSection";
import InsureServiceMainSection from "../../component/sections/insure/InsureServiceMainSection";
import MaintenanceServiceMainSection from "../../component/sections/MaintenanceServiceMainSection";
import NoCirculateServiceMainSection from "../../component/sections/NoCirculateServiceMainSection";
import PenalityServiceMainSection from "../../component/sections/PenalityServiceMainSection";
import PlateRenewServiceMainSection from "../../component/sections/PlateRenewServiceMainSection";
import TenureServiceMainSection from "../../component/sections/TenureServiceMainSection";
import TheftReportServiceMainSection from "../../component/sections/TheftReportServiceMainSection";
import VerificationServiceMainSection from "../../component/sections/VerificationServiceMainSection";

import './VehicleServicePage.css'

const VehicleServicePage = ({ history }) => {
  const { vehicleId, serviceName } = useParams();
  const { app: { vehicles }, dispatch } = useContext(AppContext);
  const vehicle = vehicles.find(({ id }) => id == vehicleId)
  const [serviceControl, setServiceControl] = useState(false)
  useEffect(() => {
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: '',
      }
    })
  }, [])

  return (
    <div className="vehicle-service-page">
      <div className="vehicle-service-back" onClick={() => history.goBack()}>
        <FontAwesomeIcon className="icon" icon="chevron-left" />
        <span>Regresar</span>
      </div>
      {
        serviceControl && (
          <div className="view-service-controls">
            {
              serviceControl
            }
          </div>
        )
      }
      <div className="vehicle-service-container">
        {
          serviceName == 'no_circulate' ? <NoCirculateServiceMainSection vehicle={vehicle} /> :
            serviceName == 'verification' ? <VerificationServiceMainSection vehicle={vehicle} /> :
              serviceName == 'tenure' ? <TenureServiceMainSection vehicle={vehicle} setServiceControl={setServiceControl} /> :
                serviceName == 'penality' ? <PenalityServiceMainSection vehicle={vehicle} setServiceControl={setServiceControl} /> :
                  serviceName == 'environmental_sanction' ? <EnvironmentalSanctionServiceMainSection vehicle={vehicle} setServiceControl={setServiceControl} /> :
                    serviceName == 'fotocivicas' ? <FotocivicasServiceMainSection vehicle={vehicle} /> :
                      serviceName == 'circulation_card' ? <CirculationCardServiceMainSection vehicle={vehicle} /> :
                        serviceName == 'maintenance' ? <MaintenanceServiceMainSection vehicle={vehicle} /> :
                          serviceName == 'plate_renew' ? <PlateRenewServiceMainSection vehicle={vehicle} /> :
                            serviceName == 'insure' ? <InsureServiceMainSection vehicle={vehicle} /> :
                              serviceName == 'theft_report' ? <TheftReportServiceMainSection vehicle={vehicle} /> :
                                <Redirect to={'/alertas'} />
        }
      </div>
    </div>
  )
}

export default VehicleServicePage