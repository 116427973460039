import { useContext, useEffect, useState } from "react";

import "./ConfigNotificationsPage.css";
import { appTypes } from "../reducers/types";
import FooterSection from "../component/sections/FooterSection";
import ConfigNotificationSection from "../component/sections/ConfigNotificationSection";
import { AppContext } from "../contexts";

const ConfigNotificationsVehiclePage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        contract_alert: true,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: 'Configura tus <span>notificaciones</span>',
        back: () => {
          history.goBack()
        }
      }
    })
  }, [])
  return (
    <>
      <ConfigNotificationSection onSave={() => { history.goBack() }} />
      <FooterSection />
    </>
  );
};

export default ConfigNotificationsVehiclePage;
