import React from "react";
import ReactDOM from "react-dom";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

export const Paypal_Button_Molecule = ({ amount, onSuccess, onError }) => {
  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            currency_code: "MXN",
            value: amount,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    const capture = actions.order.capture();
    onSuccess(data, capture);
    return capture;
  };
  
  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onError={onError}
    />
  );
};
