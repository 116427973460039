import moment from "moment";

const PenalityCDMXSection = ({ multa, premium }) => {
  const { folio, situacion, sancion, motivo, fecha, fundamento } = multa;
  return (
    <>
      {
        !premium && (
          <p className="content-title">Folio: {folio}</p>
        )
      }
      <div className="content-section">
        <span className="main-label">Monto UMAs</span>
        <span className="main-variable">{sancion}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Estatus</span>
        <span className={`main-variable ${situacion.includes('No pagada') ? 'alert' : ''}`}>{situacion}</span>
      </div>
      <div className="penality-article">
        <div className="content-section">
          <span className="main-label">
            <span>Artículo: </span>
            <span className="main-sublabel">{fundamento}</span>
          </span>
        </div>
        <div className="content-section">
          <span className="main-label">
            <span>Motivo:</span><br />
            <span className="main-sublabel">{motivo}</span>
          </span>
        </div>
      </div>
      <div className="content-section">
        <span className="main-label">Fecha de infracción</span>
        <span className="main-variable">{moment(fecha).format('DD[ de ]MMMM[ de ]YYYY')}</span>
      </div>
    </>
  )
}

export default PenalityCDMXSection