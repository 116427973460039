import { useContext, useEffect, useImperativeHandle } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { addServiceVehicle } from '../../services/VehicleServices';
import './ServiceMainSection.css';
import SetMaintenanceServiceSection from './SetMaintenanceServiceSection';

const AddMaintenanceServiceMainSection = ({ vehicle, setEnableSave, AddServiceForm, setShowAddService, setList }) => {
  const { dispatch } = useContext(AppContext);
  const { id } = vehicle;
  const { handleSubmit, control } = useForm();
  const fields = useWatch({ control, name: ['serviceDay', 'serviceMonth', 'serviceYear', 'serviceKm'] });

  useEffect(() => {
    const [serviceDay, serviceMonth, serviceYear, serviceKm] = fields;
    if (serviceDay && serviceMonth && serviceYear && serviceKm)
      setEnableSave(true)
    else
      setEnableSave(false)
  }, [fields])

  useImperativeHandle(AddServiceForm, () => ({
    submit() {
      handleSubmit(onSubmit)();
    },
  }))

  const onSubmit = async (values) => {
    const { success, message, data } = await addServiceVehicle(id, values);
    if (success) {
      dispatch({
        type: vehicleTypes.addServiceVehicle,
        payload: {
          id,
          service: values
        }
      })
      setList((list) => [...list, values])
      setShowAddService(false)
    } else {
      console.error(message);
    }
  }

  return (
    <div className="service-main-content">
      <div className="update-no-circulate">
        <form ref={AddServiceForm} onSubmit={handleSubmit}>
          <SetMaintenanceServiceSection control={control} />
        </form>
      </div>
    </div>
  )
}

export default AddMaintenanceServiceMainSection
