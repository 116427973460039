import { useState } from 'react'
import { Images } from '../../util'
import ShareAlertaDialog from '../dialogs/ShareAlertaDialog'
import './LinkShareSection.css'

const LinkShareSection = () => {
  const [shareDialog, setShareDialog] = useState({
    open: false, data: {
      title: 'Recomendar Alertamiauto.com',
      linkToCopy: 'https://www.alertamiauto.com/',
      whatsapp: {
        url: 'https://www.alertamiauto.com/',
        title: 'Olvídate de las multas o recargos y registra tus vehículos en'
      },
      facebook: {
        url: 'https://www.alertamiauto.com/',
        quote: 'Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com',
        hashtag: '#alertamiauto'
      },
      twitter: {
        url: 'Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com',
        title: 'Alertamiauto.com',
        hashtags: ['multas', 'recargos', 'alertamiauto', 'cdmx'],
        related: ['@alertamiauto'],
      },
      email: {
        url: 'https://www.alertamiauto.com',
        subject: 'Alertamiauto.com',
        body: 'Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com',
      }
    }
  })
  return (
    <>
      <div className="link-share" >
        <span onClick={() => setShareDialog({ ...shareDialog, open: true })}>
          <img src={Images.LOGOUT}></img>
          <span>Recomendar Alertamiauto.com</span>
        </span>
      </div>
      <ShareAlertaDialog
        state={shareDialog}
        onClose={() => setShareDialog({ ...shareDialog, open: false })}
      />
    </>
  )
}

export default LinkShareSection