import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import { Images } from "../util";
import { appTypes } from "../reducers/types";
import "./EstateDataRecivedPage.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../contexts";

const EstateDataRecivedPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "<span>¡Gracias!</span><br>Hemos recibido tu información",
        subtitle: "En cuanto tengamos conectado a tu Estado te enviaremos un correo para que te puedas registrar.",
        back: () => history.push('/not_found_estate')
      }
    })
  }, [])

  return (
    <div className="estate-data-recived-container">
      <div className="estate-data-recived-content-button">
        <Button
          className="alerta-purple-button"
          onClick={() => history.push('/')}
        >
          <span className="alerta-purple-button-text">Regresar al Inicio</span>
        </Button>
      </div>
    </div>
  );
};

export default EstateDataRecivedPage;
