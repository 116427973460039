import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './RemoveAlertDialog.css';

const RemoveAlertDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="remove-alert-dialog"
    >
      <div className="remove-alert-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item xs={12} container justifyContent="flex-end">
            <img className="close-icon" onClick={onClose} src={Images.DIALOG_CLOSE_ICON} />
          </Grid>
          <Grid item >
            <p className="remove-alert-dialog-title">¿Estás seguro que deseas eliminar esta Alerta?</p>
          </Grid>
          <Grid item >
            <p className="remove-alert-dialog-subtitle">Si eliminas esta Alerta no se puede volver a recuperar</p>
          </Grid>
          <Grid className="remove-alert-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onSuccess}>
              <p className="alerta-purple-button-text">Eliminar</p>
            </Button>
            <Button className="alerta-white-button" onClick={onClose}>
              <p className="alerta-white-button-text">Regresar</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default RemoveAlertDialog
