import { useFormContext } from 'react-hook-form';
import SetVehicleHologramSection from './SetVehicleHologramSection';
import './ServiceMainSection.css';

const UpdateNoCirculateServiceMainSection = () => {
  const { handleSubmit } = useFormContext()

  return (
    <div className="service-main-content">
      <div className="update-no-circulate">
        <p>Puedes modificar el holograma de tu vehículo en caso de haber cometido algún error en el registro.</p>
        <form onSubmit={handleSubmit}>
          <SetVehicleHologramSection />
        </form>
      </div>
    </div>
  )
}

export default UpdateNoCirculateServiceMainSection
