import './ServiceMainSection.css';
import './NoCirculateServiceMainSection.css';
import UpdateNoCirculateServiceMainSection from './UpdateNoCirculateServiceMainSection';
import { useContext, useEffect, useRef, useState } from 'react';
import CalendarSection from './CalendarSection';
import moment from 'moment';
import { getGummedColor } from '../../util/helpers';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { updateVehicle } from '../../services/VehicleServices';
import useHeight from '../../hooks/useHeight';
const today = moment();

const NoCirculateServiceMainSection = ({ vehicle }) => {
  let { dispatch } = useContext(AppContext);
  let { id, services: { no_circula: { hologram, last_number, description, rules, custom_rules, template_description } } } = vehicle;
  const methods = useForm();
  const watch = useWatch({ control: methods.control, name: ['hologram', 'hologramMonth', 'hologramYear'] });
  const gummed_color = getGummedColor(description);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [update, setUpdate] = useState(false)
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [ref, height] = useHeight();
  rules = rules || [];

  useEffect(() => {
    const [hologram, hologramMonth, hologramYear] = watch;
    if (hologram == 'exempt')
      setDisableUpdate(false)
    else
      setDisableUpdate(!(hologram && hologramMonth && hologramYear && true))
  }, [watch])

  const handlerEdit = () => {
    setShowUpdateButton(true)
    setUpdate(true);
  }

  const handlerBack = () => {
    setShowUpdateButton(false)
    setUpdate(false);
  }

  const handlerUpdate = () => {
    methods.handleSubmit(onUpdate)();
  }

  const onUpdate = async (form) => {
    let { hologram, hologramMonth, hologramYear } = form;
    if (hologram == 'exempt') {
      hologramMonth = null;
      hologramYear = null;
    } else {
      if (!(hologram && hologramMonth && hologramYear))
        return;
    }
    const { success, data, message } = await updateVehicle(id, form);
    if (success) {
      dispatch({
        type: vehicleTypes.setServices,
        payload: {
          id: id,
          services: {
            no_circula: data.no_circula,
            verificacion: data.verificacion,
            tenencia_refrendo: data.tenencia_refrendo,
            multa_transito: data.multa_transito,
            mantenimiento: data.mantenimiento,
            tarjeta_circulacion: data.tarjeta_circulacion,
            fotocivica: data.fotocivica
          }
        }
      });
      setUpdate(false);
      setShowUpdateButton(false);
      methods.reset()
    } else {
      console.error(message);
    }
  }

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Hoy no circula</span>
        <div>
          {
            showUpdateButton ? (
              <>
                <button className='back' onClick={handlerBack}>
                  Regresar
                </button>
                <button className='update' disabled={disableUpdate} onClick={handlerUpdate}>
                  Actualizar
                </button>
              </>
            ) : (
              <button onClick={handlerEdit}>Editar</button>
            )
          }
        </div>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          update ? (
            <FormProvider {...methods}>
              <UpdateNoCirculateServiceMainSection />
            </FormProvider>
          ) : (
            <>
              <div className="service-main-content">
                <div className="content-section">
                  <span className="main-label">Holograma</span>
                  <span className="main-variable">{hologram == 'exempt' ? 'Exento' : hologram}</span>
                </div>
                <div className="content-section">
                  <span className="main-label">Terminación</span>
                  <span className="main-plate" style={{ backgroundColor: `${gummed_color}` }}>{last_number}</span>
                </div>
                <div className="content-section">
                  <span className="main-label">No circula</span>
                  <span className="main-variable">
                    {
                      rules.length != 0 ? (
                        rules.map(({ description, hours }) => (
                          <>
                            <span>{description}</span>
                            <span className="main-subvariable">{hours}</span>
                          </>
                        ))
                      ) : (<span>Circulas todos los días</span>)
                    }
                  </span>
                </div>
              </div>
              {
                custom_rules.length != 0 && (
                  <div className="alert-content-section">
                    <p className="alert-title">IMPORTANTE</p>
                    {
                      custom_rules.map(({ title, description, hours, duration }) => (
                        <>
                          <div className="content-section">
                            <span className="main-label">{title}</span>
                            <span className="main-variable">
                              <span>{description}</span>
                              <span className="main-subvariable">{hours}</span>
                            </span>
                          </div>
                          {
                            duration && (
                              <div className="content-section">
                                <span className="main-label">Duración</span>
                                <span className="main-variable">{duration}</span>
                              </div>
                            )
                          }
                        </>
                      ))
                    }

                  </div>
                )
              }
              <div className="no-circulate-calendar">
                <CalendarSection month={today.month()} year={today.year()} vehicle={vehicle} />
              </div>
              <div className="service-main-footer">
                <MarkdownPreview source={template_description} />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default NoCirculateServiceMainSection
