import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../contexts';
import { userTypes } from '../reducers/types';
import { validateToken } from '../services/UserServices';

const PrivateRoute = ({ component, ...rest }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const [validating, setValidating] = useState(true)
  const [isValid, setIsValid] = useState(false)
  useEffect(async () => {
    if (user && user.token) {
      const { success } = await validateToken();
      setIsValid(success);
      setValidating(false)
    }else{
      setValidating(false)
      dispatch({ type: userTypes.deleteUser });
    }
  }, [])
  return (
    <>
      {
        validating ? (
          <div className="view-loading-container">
            <CircularProgress size={60} />
          </div>
        ) : (
          isValid ? <Route {...rest} component={component} /> : <Redirect to="/login" />
        )
      }
    </>
  )
}
export default PrivateRoute
