import { useEffect } from 'react';
import MaintenanceServiceMainSection from './MaintenanceServiceMainSection';
import './ServiceSideSection.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const MaintenanceServiceSideSection = ({ vehicle, selected, setMainPanel }) => {
  let { services: { mantenimiento: { services } } } = vehicle;
  services = services ? services : [];
  const has_services = services.length != 0;
  const service = has_services ? services[services.length - 1] : {};
  const { serviceDay, serviceMonth, serviceYear, serviceKm } = service;
  const lastService = has_services && moment(`${serviceDay}-${serviceMonth}-${serviceYear}`, 'DD-MMMM-YYYY')
  const nextService = has_services && moment(lastService).add({ days: 1, years: 1 });
  const nextServiceKm = has_services && parseInt(serviceKm.replace(',', '')) + 6000;
  const nextServiceDays = has_services && nextService.diff(moment(), 'days');
  
  useEffect(() => {
    if (selected == 'maintenance')
      setMainPanel(<MaintenanceServiceMainSection vehicle={vehicle} />)
  }, [selected])

  return (
    <div className="service-side-section">
      <p className="title">Servicio / Mantenimiento</p>
      <p>
        <span className="side-label">Vencimiento</span>
        <span className={`side-variable ${has_services && nextServiceDays < 0 ? 'alert' : ''}`}>{has_services ? nextServiceDays + ' días': '-'}</span>
      </p>
      <p>
        <span className="side-label">Próximo Servicio</span>
        <span className="side-variable">{has_services ? nextService.format('DD[ de ]MMMM[ de ]YYYY') : '-'}</span>
      </p>
      <p>
        <span className="side-label">Próximo Servicio</span>
        <span className="side-variable">
          {
            has_services ?
              <NumberFormat
                value={nextServiceKm}
                displayType={'text'}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={0}
                suffix=' km (Aprox.)'
              />
              : '-'
          }
        </span>
      </p>
    </div>
  )
}

export default MaintenanceServiceSideSection
