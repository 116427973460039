import { useContext, useEffect, useState } from "react";
import "./MyAlertsPage.css";
import { AppContext } from "../contexts";
import { vehicleTypes, appTypes, paymentTypes, userTypes } from "../reducers/types";
import FooterSection from "../component/sections/FooterSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdditionalServicesSection from "../component/sections/AdditionalServicesSection";
import VehicleDataSection from "../component/sections/VehicleDataSection";
import PrincipalServicesSection from "../component/sections/PrincipalServicesSection";
import { scrollToElement } from "../util/helpers";
import { getUser } from "../services/UserServices";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import LinkShareSection from "../component/sections/LinkShareSection";

const MyAlertsPage = ({ history }) => {
  let { app: { user, vehicles }, dispatch } = useContext(AppContext);
  const [vehicleOpen, setVehicleOpen] = useState(null)
  const [loading, setLoading] = useState(true);

  if (user.addingVehicles) {
    vehicles = [];
    dispatch({ type: vehicleTypes.deleteVehicles });
    dispatch({ type: userTypes.updateUser, payload: { addingVehicles: false } });
  }

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        contract_alert: true,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: `<span>¡Hola ${user.name}!</span><br/>Estos son tus vehículos registrados`,
        back: () => {
          history.push('/login')
        }
      }
    })
    if (vehicles) {
      vehicles = vehicles.map((vehicle) => ({
        ...vehicle,
        paymentPending: vehicle.membership == null
      }));
    } else {
      vehicles = [];
    }
  }, [])

  useEffect(async () => {
    const { success, data } = await getUser(user.id);
    if (success) {
      dispatch({
        type: vehicleTypes.createVehicles,
        payload: data.vehicles.map((vehicle) => ({
          ...vehicle,
          paymentPending: vehicle.membership == null
        }))
      })
      if (data.vehicles.length == 1)
        setVehicleOpen(data.vehicles[0].id)
      setLoading(false);
    } else {
      history.push('/')
      dispatch({ type: userTypes.deleteUser });
    }
  }, []);

  const handlerOpen = (id) => {
    const currentId = vehicleOpen == id ? null : id
    if (currentId == null) {
      window.scroll({ top: 0, behavior: 'smooth' })
    } else {
      scrollToElement({ current: document.getElementById(isMobile ? `services_${currentId}` : currentId) }, 25);
    }
    setTimeout(() => {
      setVehicleOpen(currentId)
    }, 500);
  }
  return (
    <>
      <div className="my-alerts-container" >
        {
          loading ? (
            <div className="view-loading-container">
              <CircularProgress size={60} />
            </div>
          ) : (
            <>
              <div className='vehicles'>
                <LinkShareSection />
                {
                  vehicles.map((vehicle) => (
                    <div className="car-card" key={vehicle.id}>
                      {
                        vehicle.paymentPending && (
                          <div className="payment-pending-message">
                            <p>Pendiente de pago en efectivo</p>
                          </div>
                        )
                      }
                      <div className="card-content" id={vehicle.id}>
                        <VehicleDataSection
                          vehicle={vehicle}
                          open={vehicleOpen == vehicle.id}
                        />
                      </div>
                      {
                        vehicle.membership && (
                          <PrincipalServicesSection
                            vehicle={vehicle}
                            open={vehicleOpen == vehicle.id}
                            handlerOpen={handlerOpen}
                          />
                        )
                      }
                    </div>
                  ))
                }
              </div >
              <p className="note">* Las Alertas se renuevan automáticamente cada año y se pueden cancelar en cualquier momento</p>
              <AdditionalServicesSection />
              <p className="contract-alert" onClick={() => history.push("/contratar_alerta")}>
                Contratar una Alerta
                <FontAwesomeIcon className="icon" icon="arrow-right" />
              </p>

            </>
          )
        }
      </div >
      <FooterSection />
    </>
  );
};

export default MyAlertsPage;
