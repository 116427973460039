import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import '../ServiceMainSection.css';
import './InsureServiceMainSection.css';
import useHeight from '../../../hooks/useHeight';
import { getVehicleByNIV, updateVehicle } from '../../../services/VehicleServices';
import { AppContext } from '../../../contexts';
import { vehicleTypes } from '../../../reducers/types';

const InsureServiceMainSection = ({ vehicleId, niv, poliza, vigencia, estatus }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const [ref, height] = useHeight();
  const [currentNIV, setcurrentNIV] = useState(niv)
  const [searchNIV, setSearchNIV] = useState(false)
  const [errorNIV, setErrorNIV] = useState(false)
  const [confirmNIV, setConfirmNIV] = useState(false)
  const [loading, setLoading] = useState(true)

  const handlerRenewInsure = () => {
    window.open("https://alertamiauto.com", "_blank")
  }

  const handlerSearchNIV = async () => {
    setSearchNIV(true);
    const { success } = await getVehicleByNIV(user.id, currentNIV);
    if (success) {
      setConfirmNIV(currentNIV);
      setcurrentNIV('')
    } else {
      setErrorNIV('No se encontro el NIV o Número de serie en el REPUVE')
    }
    setSearchNIV(false);

  }

  const handlerChangeNIV = (e) => {
    setcurrentNIV(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''));
    setErrorNIV(false);
  }

  const handlerUpdateNIV = async () => {
    const { success } = await updateVehicle(vehicleId, { niv: confirmNIV })
    if (success) {
      dispatch({
        type: vehicleTypes.updateVehicle,
        payload: {
          id: vehicleId,
          niv: confirmNIV
        }
      })
      setLoading(true)
    }
  }

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Seguro Vehicular</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          niv == '' ? (
            <>
              <div className="alert-content-section" style={{ marginTop: '10px', marginBottom: '0px', backgroundColor: 'transparent' }}>
                <p className="alert-title">
                  <div>
                    <FontAwesomeIcon icon="times-circle" size="lg" style={{ marginRight: '10px' }} />
                    <span>
                      Nuestro sistema no encontró tu NIV o Número de Serie
                    </span>
                  </div>
                </p>
              </div>
              <div className="content-section">
                <span className="main-label" style={{ fontSize: '12px', padding: '10px 30px' }}>
                  Para poder activar este servicio, es necesario tener tu NIV / Número de Serie de tu vehículo:
                </span>
              </div>
              <div className="content-section">
                <TextField
                  label={'Ingresa tu NIV (Número de Serie)'}
                  fullWidth
                  variant="outlined"
                  value={currentNIV}
                  onChange={handlerChangeNIV}
                  error={!!errorNIV}
                  helperText={errorNIV && <span><FontAwesomeIcon icon='exclamation-circle' /> {errorNIV}</span>}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <button type="button" className='register-car-detail-search-plates-button'
                          onClick={() => handlerSearchNIV()}
                        >
                          Buscar
                          {searchNIV && <CircularProgress className="register-car-detail-search-plates-button-loading" size={20} color='inherit' />}
                        </button>
                      </InputAdornment>),
                  }}
                />
              </div>
              {
                confirmNIV && (
                  <div style={{ padding: '0px 20px' }}>
                    <div>
                      <FontAwesomeIcon icon="check" className='icon' size='sm' color='#17788D' style={{ marginRight: '10px' }} />
                      <span className="main-variable" style={{ fontWeight: 'normal' }}>Confirmado</span>
                    </div>
                    <div className="content-section" style={{ margin: '10px 0' }}>
                      <span className="main-variable">{confirmNIV}</span>
                    </div>
                    <div className="content-section">
                      <button
                        className="main-button"
                        style={{ marginTop: '20px' }}
                        onClick={handlerUpdateNIV}
                      >
                        Actualizar información
                      </button>
                    </div>
                  </div>
                )
              }
            </>
          ) : loading ? (
            <div className="insure-loading-container">
              <div className="loading">
                <CircularProgress className="loading-icon" size={40} />
              </div>
              <p className='title'>
                Este proceso puede tardar algunos minutos, en cuanto queden listo, recibirás un correo electrónico con la confirmación del servicio.
              </p>
            </div>
          ) : (
            <>
              <div className="service-main-content">
                <div className="content-section">
                  <span className="main-label">Póliza de Seguro</span>
                  <span className="main-variable">{poliza}</span>
                </div>
                <div className="content-section">
                  <span className="main-label">Vigencia de la Póliza</span>
                  <span className="main-variable">{vigencia}</span>
                </div>
                {
                  !estatus && (
                    <>
                      <div className="content-section">
                        <span className="main-label"></span>
                        <span className="main-variable alert">Vencida</span>
                      </div>
                      <div className="content-section">
                        <button
                          className="main-button"
                          style={{ marginTop: '20px' }}
                          onClick={handlerRenewInsure}
                        >
                          Renovar Seguro
                        </button>
                      </div>
                    </>
                  )
                }
              </div>
              <div className="alert-content-section" style={{ marginTop: '20px' }}>
                <p className="alert-title" style={{ fontSize: '14px' }}>
                  <div>
                    <FontAwesomeIcon icon="exclamation-circle" size="lg" style={{ marginRight: '10px' }} />
                    <span>
                      Informacion Importante
                    </span>
                  </div>
                </p>
              </div>
              <div className="service-main-footer">
                <p>
                  <strong>Seguro Obligatorio en México</strong>
                </p>
                <p>
                  A partir del 01 de Enero del año 2019, el seguro de auto es obligatorio
                  para todos los vehículos que cuenten con motor, como los autos y las
                  motos. En caso de no presentar dicha póliza ante una revisión de tránsito
                  o en un siniestro vial, serás acreedor a una multa.
                </p>
                <p>
                  Este seguro debe tener una cobertura contra daños a personas, por al
                  menos 100 mil pesos, y una cobertura contra daños materiales por al
                  menos 50 mil pesos.
                </p>
                <p>
                  Este decreto fue estipulado por la Ley de Caminos, Puentes y
                  Autotransporte Federal, la cual solicita como requisito obligatorio contar
                  con un seguro de auto con al menos la cobertura de “Responsabilidad
                  Civil de Daños a Terceros”, la cual pueda garantizar la indemnización de
                  daños materiales, ya sean públicos o privados así como lesiones
                  corporales de terceras personas en las que el vehículo asegurado tenga
                  responsabilidad de dichos daños.
                </p>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default InsureServiceMainSection
