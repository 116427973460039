import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';

const NIVTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid #dadde9',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)'
  },
}));

export default NIVTooltip;