import { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { Images } from "../util";
import "./PaymentPage.css";
import { AppContext } from "../contexts";
import { appTypes, paymentTypes, userTypes } from "../reducers/types";
import DigitalPaymentDialog from "../component/dialogs/DigitalPaymentDialog";
import BeforeExitMessage from "../helpers/BeforeExitMessage";
import CardPaymentSection from "../component/sections/payment/CardPaymentSection";
import DiscountPaymentSection from "../component/sections/payment/DiscountPaymentSection";
import PaypalPaymentSection from "../component/sections/payment/PaypalPaymentSection";
import OxxoPaymentSection from "../component/sections/payment/OxxoPaymentSection";
import SelectPaymentTypeSection from "../component/sections/payment/SelectPaymentTypeSection";
import { createCouponPayment, createCreditCarPaymentByToken, createOxxoPayment, createPaypalPayment, openPaymentPDF } from "../services/PaymentServices";
import PaymentAlertaDataSection from "../component/sections/payment/PaymentAlertaDataSection";
import PaymentDataSection from "../component/sections/payment/PaymentDataSection";

const PaymentPage = ({ history }) => {
  const { app: { user, vehicles }, dispatch } = useContext(AppContext);
  const price = 399.00;
  const totalVehicles = vehicles.length;

  const [amount, setAmount] = useState(0);
  const [years, setYears] = useState(1);
  const [coupon, setCoupon] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [showDigitalPaymentDialog, setShowDigitalPaymentDialog] = useState({ open: false });

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Ingresa tu <span>método de pago</span>",
        back: () => {
          if (paymentType != null)
            setPaymentType(null)
          else
            history.goBack()
        }
      }
    })
    if (user.showDigitalPaymentDialog == undefined) {
      dispatch({
        type: userTypes.updateUser,
        payload: {
          showDigitalPaymentDialog: false,
        }
      });
      setShowDigitalPaymentDialog({ open: true });
    }
  }, [paymentType])

  useEffect(() => {
    calculateAmount();
  }, [years, coupon])

  const calculateAmount = () => {
    let amount = totalVehicles * price * years;
    if (coupon) {
      let discountAmount = 0;
      const { type_discount, money, percentage, years: year_coupon } = coupon;
      const years_discount = years <= year_coupon ? years : year_coupon;
      switch (type_discount) {
        case 'money':
          discountAmount = money * years_discount;
          break;
        case 'percentage':
          discountAmount = percentage * totalVehicles * price * years_discount;
          break;
      }
      amount = amount - discountAmount;
    }
    setAmount(amount)
  }

  return (
    <>
      <div className={`payment-page-container ${amount == 0 ? 'zero-amount' : ''}`}>
        <Grid container columnSpacing={2}>
          {
            amount != 0 && (
              <Grid item container xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
                {
                  paymentType == 'card' ? (
                    <CardPaymentSection
                      amount={amount}
                      handlerSubmit={async ({ token, lastCardNumbers }) => {
                        const { success, message, data } = await createCreditCarPaymentByToken(user.id, {
                          code: coupon?.code,
                          years,
                          token,
                          vehicles: vehicles.map(({ id }) => ({ id }))
                        });
                        if (success) {
                          dispatch({
                            type: paymentTypes.createPayment,
                            payload: {
                              id: data.reference,
                              years,
                              amount,
                              type: 'card',
                              coupon: coupon?.code,
                              lastCardNumbers,
                            }
                          })
                          history.push("/processing_payment");
                        }
                        return success
                      }}
                    />
                  ) : paymentType == 'paypal' ? (
                    <PaypalPaymentSection
                      amount={amount}
                      handlerSubmit={async ({ paypalData }) => {
                        const { success, message, data } = await createPaypalPayment(user.id, {
                          ...paypalData,
                          code: coupon?.code,
                          years,
                          vehicles: vehicles.map(({ id }) => ({ id }))
                        });
                        if (success) {
                          dispatch({
                            type: paymentTypes.createPayment,
                            payload: {
                              id: data.id,
                              years,
                              amount,
                              type: 'paypal',
                              coupon: coupon?.code,
                            }
                          })
                          history.push("/processing_payment");
                        }
                        return success
                      }}
                    />
                  ) : paymentType == 'oxxo' ? (
                    <OxxoPaymentSection
                      handlerSubmit={async () => {
                        const { success, message, data } = await createOxxoPayment(user.id, {
                          code: coupon?.code,
                          years,
                          vehicles: vehicles.map(({ id }) => ({ id }))
                        });
                        if (success) {
                          dispatch({
                            type: paymentTypes.createPayment,
                            payload: {
                              id: data.id,
                              years,
                              amount,
                              type: 'oxxo',
                              coupon: coupon?.code,
                            }
                          })
                          openPaymentPDF(data.id);
                          history.push("/payment_processed");
                        }
                        return success;
                      }}
                    />
                  ) : paymentType == 'spei' ? (
                    <SpeiPaymentSection
                      amount={amount}
                      handlerSubmit={async ({ }) => {

                      }}
                    />
                  ) : (
                    <SelectPaymentTypeSection setPaymentType={setPaymentType} />
                  )
                }
              </Grid>
            )
          }
          <Grid item container xs={12} sm={amount == 0 ? 12 : 5} order={{ xs: 1, sm: 2 }}>
            <div className="payment-section">
              <PaymentDataSection couponState={[coupon, setCoupon]} >
                <PaymentAlertaDataSection
                  price={price}
                  amount={amount}
                  yearsState={[years, setYears]}
                />
              </PaymentDataSection>
            </div>
            {
              amount == 0 && <DiscountPaymentSection
                handlerSubmit={async () => {
                  const { success, message, data } = await createCouponPayment(user.id, {
                    code: coupon?.code,
                    years,
                    vehicles: vehicles.map(({ id }) => ({ id }))
                  });
                  if (success) {
                    dispatch({
                      type: paymentTypes.createPayment,
                      payload: {
                        id: data.id,
                        years,
                        amount,
                        type: 'discount',
                        coupon: coupon?.code
                      }
                    })
                    history.push("/payment_processed");
                  }
                  return success;
                }}
              />
            }
          </Grid>
        </Grid>
        <div className="payment-footer-container">
          <img className="payment-footer-img" src={Images.SHIELD_GREY}></img>
          <p className="payment-footer-text">
            Toda tu información Bancaria está protegida y nuestro sistema cumple
            con los niveles más altos de seguridad
          </p>
        </div>
      </div>
      <DigitalPaymentDialog
        state={showDigitalPaymentDialog}
        onClose={() => setShowDigitalPaymentDialog({ open: false })}
      />
      <BeforeExitMessage />
    </>
  );
};

export default PaymentPage;
