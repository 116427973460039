import { CircularProgress, InputAdornment, TextField } from "@mui/material"
import { useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { validateCoupon } from "../../../services/CouponService";
import './CouponInputSection.css'

const CouponInputSection = ({ setCoupon }) => {
  const { control, handleSubmit, setError, resetField } = useForm();
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [discount, setDiscount] = useState('')

  const onSubmit = async ({ coupon }) => {
    if (coupon == '')
      return;
    setLoading(true);
    const { success, message, data } = await validateCoupon(coupon);
    if (success) {
      setDiscount(data.percentage ? `${data.percentage * 100}%` : `$ ${data.money}`)
      setCoupon(data)
      setSuccess(true);
    } else {
      setError('coupon')
    }
    setLoading(false);
  }

  const handlerRemoveCoupon = () => {
    setSuccess(false);
    setDiscount('');
    setCoupon(null);
    resetField('coupon');
  }

  return (
    <>
      {
        success ? (
          <div className="coupon-input-success-container" onClick={handlerRemoveCoupon}>
            <span>Código aceptado: </span>
            <span className="coupon-input-success-discount">{discount} de descuento</span>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="coupon"
              control={control}
              render={
                ({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={(e) => onChange(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                    error={!!error}
                    placeholder={error ? "Cupón no válido" : "Ingresa tu cupón"}
                    sx={{
                      padding: '0px',
                      '& .MuiInputBase-input': {
                        padding: '9px 10px',
                        fontSize: '14px',
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            loading && (<CircularProgress className="coupon-input-button-loading" size={20} color='inherit' />)
                          }
                          <button className='coupon-input-button' type="submit" >
                            Validar
                          </button>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
            />
          </form>
        )
      }
    </>
  )
}

export default CouponInputSection