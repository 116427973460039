import './ServiceMainSection.css';
import './PenalityServiceMainSection.css';
import PenalityEstateServiceSection from './PenalityEstateServiceSection';
import useHeight from '../../hooks/useHeight';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const penalityStatus = [
  {name: "Todas", value: undefined},
  {name: "Pagadas", value: 'Pagada'},
  {name: "No Pagadas", value: 'No pagada'},
]
const PenalityServiceMainSection = ({ vehicle, status, setServiceControl }) => {
  let { estate, services: { multa_transito } } = vehicle;
  const [ref, height] = useHeight();
  const [current, setCurrent] = useState(status);

  useEffect(() => {
    if (setServiceControl)
      setServiceControl(
        <Grid container spacing={1}>
          {
            penalityStatus.map(({name, value}) => {
              return (
                <Grid container item xs={4}>
                  <span className={`tenure-year ${current == value ? 'selected' : ''}`}
                    onClick={(e) => setCurrent(value)}
                  >
                    {name}
                  </span>
                </Grid>
              )
            })
          }
        </Grid>
      )
  }, [current])

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          multa_transito.edomex && (
            <PenalityEstateServiceSection
              name={estate}
              status={current}
              multas={multa_transito.edomex}
            />
          )
        }
        {
          multa_transito.cdmx && (
            <PenalityEstateServiceSection
              name={'Ciudad de México'}
              status={current}
              multas={multa_transito.cdmx}
            />
          )
        }
      </div>
    </div >
  )
}

export default PenalityServiceMainSection
