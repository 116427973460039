import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Grid, LinearProgress, linearProgressClasses } from '@mui/material';
import { useEffect, useState } from 'react';
import alertaServices from '../../data/alertaServices'
import { Images } from '../../util';
import './ProcessingServicesSection.css';

const ProcessingServicesSection = ({ vehicleId, onSuccess, onFinish }) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(0)
  }, [vehicleId]);

  useEffect(() => {
    if (total == alertaServices.length * 100)
      setTimeout(() => {
        onFinish()
      }, 500);
  }, [total])

  return (
    <div className="processing-services-container">
      {
        alertaServices.map(({ name, service }) => (
          <ServiceProgress
            vehicleId={vehicleId}
            name={name}
            service={service}
            setTotal={setTotal}
            onSuccess={onSuccess}
          />
        ))
      }
    </div>
  )
}

const ServiceProgress = ({ vehicleId, name, service, setTotal, onSuccess }) => {
  const [progress, setProgress] = useState(0)
  const [times, setTimes] = useState(0)
  let limit = 100;

  useEffect(() => {
    reset();
  }, [vehicleId])

  useEffect(() => {
    if (progress != 100)
      getServiceData();
  }, [times])

  useEffect(() => {
    if (progress != 100)
      startProgress();
  }, [progress])

  const reset = () => {
    setProgress(0);
    setTimes(0);
  }

  const getServiceData = async () => {
    const { success, value } = await service(vehicleId);
    if (success && value != 0) {
      setProgress(100);
      setTotal((global) => global + 100)
      onSuccess(name, value);
      setTimes(times + 1);
    } else {
      setTimeout(() => {
        if (times <= limit)
          setTimes(times + 1)
      }, 5000)
    }
  }

  const startProgress = () => {
    setTimeout(() => {
      setProgress((progress) => {
        const random = Math.floor(Math.random() * 10);
        const result = progress + (random == 0 ? 1 : random)
        return result > 90 ? progress : result;
      })
    }, 5000)
  }

  return (
    <div className='service-container'>
      <Grid container >
        <Grid item xs={12} sm={4} className='service-section-name' >
          <img src={Images.ALERTA_MI_AUTO_GREEN} alt="alertamiauto logo" />
          <span className='service-text'>{name}</span>
        </Grid>
        <Grid item xs={11} sm={7} className='service-section-progress'>
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} sx={{
              height: 15,
              borderRadius: 1,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: '#cfe0e3',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 1,
                backgroundColor: '#17788d',
              },
            }} />
          </Box>
        </Grid>
        <Grid item xs={1} className='service-section-icon' >
          {
            progress == 100 ? (
              <FontAwesomeIcon className='service-icon' icon='check-circle' size="lg" />
            ) : (
              <CircularProgress size={20} color='inherit' />
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

ServiceProgress.defaultProps = {
  onSuccess: () => {}
};

export default ProcessingServicesSection