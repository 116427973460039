import { useEffect, useContext, useState } from "react";
import { Grid } from "@mui/material";
import { Images } from "../util";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import "./ProcessingAlertInfoPage.css";
import ProcessingServicesSection from "../component/sections/ProcessingServicesSection";

const ProcessingAlertInfoPage = ({ history }) => {
  const { app: { user, vehicles }, dispatch } = useContext(AppContext);
  const [vehicleList, setVehicleList] = useState(vehicles)
  const [vehicleId, setVehicleId] = useState(null)
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: 'Estamos procesando <span>tu información</span>',
        subtitle: 'Este proceso puede tardar algunos minutos, en cuanto queden todos los servicios, recibirás un correo electrónico y podrás ingresar a Mis Alertas',
      }
    })
    // setVehicleList()
    processNextVehicle();
  }, [])

  useEffect(() => {
  }, [vehicleId])

  const processNextVehicle = () => {
    const lastPending = vehicleList.find((vehicle) => vehicle.status == undefined);
    if (lastPending) {
      setVehicleList(vehicleList.map((vehicle) => {
        if (vehicle.id == lastPending.id)
          vehicle.status = 'inProcess';
        return vehicle
      }))
      setVehicleId(lastPending.id)
    } else {
      history.push('/alertas')
    }
  }

  const onFinishVehicle = () => {
    setVehicleList(vehicleList.map((vehicle) => {
      if (vehicle.id == vehicleId)
        vehicle.status = 'complete';
      return vehicle
    }))
    processNextVehicle();
  }

  return (
    <>
      <div className="processing-alert-info-container">
        <div className="processing-alert-info-section-cards">
          <Grid className="processing-alert-info-card-container" container spacing={2} alignItems="center" justifyContent="center" >
            {
              vehicleList.map(({ id, complete, type, estate, plate, status }) => {
                return (
                  <Grid key={id} item xs={6} md={3}>
                    <div className={`processing-alert-info-vehicle ${status}`}>
                      <div className='processing-alert-info-card'>
                        <div className="processing-alert-info-card-title-images">
                          <img className="card-img-car"
                            src={type == 0 ?
                              (complete ? Images.ACTIVE_CAR : Images.INACTIVE_CAR) :
                              (complete ? Images.ACTIVE_MOTO : Images.INACTIVE_MOTO)
                            }
                          />
                        </div>
                        <p className="processing-alert-info-card-title">
                          {estate}
                        </p>
                        <p className="processing-alert-info-card-text">
                          {plate}
                        </p>
                      </div>
                      <div className="status-label" >
                        <img src={Images.ACCOUNT_DONE_BLUE} />
                        <span>
                          {
                            status == undefined ? 'Pendiente' :
                              status == 'inProcess' ? 'En Proceso' : 'Completado'
                          }
                        </span>
                      </div>
                    </div>
                  </Grid>
                );
              })
            }
          </Grid>
        </div>
        <div className="processing-alert-info-services">
          {
            vehicleId && (
              <ProcessingServicesSection
                vehicleId={vehicleId}
                onFinish={() => {
                  onFinishVehicle();
                }}
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default ProcessingAlertInfoPage;
