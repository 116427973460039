import { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import "./IntroductionSection.css";
import { Images } from "../../util";
import { Button } from "@mui/material";
import { Animated } from "react-animated-css";

const IntroductionSection = () => {
  const text = useSelector((store) => store.page.body.header);
  const [isOpen, setOpen] = useState(false);
  const [message, setMessage] = useState(1)
  const history = useHistory();

  useEffect(() => {
    setInterval(() => {
      setMessage(Math.floor(Math.random() * (4 - 1)) + 1)
    }, 5000);
  }, [])

  return (
    <div className="introduction-section">
      <article className="principal-introduction-organism">
        <div className="wrap-general wrap-introduction-organism">
          <div className="wrap-message">
            <h1 className="title">
              Alertas para tu <br className="desktop"/>control vehicular
            </h1>
            <div className="wrap-general wrap-introduction-organism mobile">
              <img className="introduction-banner" src={Images.INTRODUCTION_BANNER} />
              <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={message == 1}>
                <img className="into-message whats" src={Images.MESSAGE_WHATS} />
              </Animated>
              <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={message == 2}>
                <img className="into-message sms" src={Images.MESSAGE_SMS} />
              </Animated>
              <Animated animationIn="fadeInLeft" animationOut="fadeOutLeft" isVisible={message == 3}>
                <img className="into-message email" src={Images.MESSAGE_MAIL} />
              </Animated>
            </div>
            <h2 className="subtitle">
              Ponte al día con tu auto o motocicleta
            </h2>
            <div className="content-message">
              <h3 className="message">
                Contrata una Alerta y recibe notificaciones cuando te pongan una multa o fotocívica, tengas que pagar tu tenencia, verificar tu auto, renovar la tarjeta de circulación o simplemente cuando no circules.
              </h3>
            </div>
          </div>
          <p className="pay-message mobile">{parse(text.price)}</p>
          <div className="wrap-actions">
            <div className="content-actions desktop">
              <div className="pay">
                <Button className="alerta-purple-button" onClick={() => history.push('/contratar_alerta')}>
                  <span className="alerta-purple-button-text">{text.button}</span>
                </Button>
                <p className="pay-message desktop">{parse(text.price)}</p>
              </div>
            </div>
            <div className="content-actions">
              <div className="play" onClick={() => setOpen(true)}>
                <img src={Images.PLAY_ICON} />
              </div>
              <div className="wrap-modal-video">
                <ModalVideo
                  channel="youtube"
                  youtube={{
                    autoplay: 1,
                    mute: 0,
                  }}
                  isOpen={isOpen}
                  videoId="vIS2Fls9fRs"
                  onClose={() => setOpen(false)}
                />
              </div>
            </div>
          </div>

        </div>
        <div className="wrap-general wrap-introduction-organism desktop">
          <img className="introduction-banner" src={Images.INTRODUCTION_BANNER} />
          <Animated animationIn="fadeInRight" animationOut="fadeOutRight" isVisible={message == 1}>
            <img className="into-message whats" src={Images.MESSAGE_WHATS} />
          </Animated>
          <Animated animationIn="fadeInRight" animationOut="fadeOutRight" isVisible={message == 2}>
            <img className="into-message sms" src={Images.MESSAGE_SMS} />
          </Animated>
          <Animated animationIn="fadeInRight" animationOut="fadeOutRight" isVisible={message == 3}>
            <img className="into-message email" src={Images.MESSAGE_MAIL} />
          </Animated>
        </div>
      </article>
      <div className="fixed-button-container mobile">
        <Button className="alerta-purple-button" onClick={() => { history.push('/contratar_alerta') }}>
          <span className="alerta-purple-button-text">
            Contratar una Alerta
          </span>
        </Button>
      </div>
    </div>
  );
};

export default IntroductionSection;