import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";

import "./TermsConditionsPage.css";

const TermsConditionsPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.body.terms_and_conditions);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title,
        back: () => history.goBack()
      }
    })
  }, [])
  return (
    <div className="terms-conditions-page">
      <div className="container">
        <div className="terms_conditions_text_block">
          <p>{texts.intro}</p>
        </div>
        {texts.clauses.map((clause) => (
          <div className="terms_conditions_text_block">
            <h4>{clause.title}</h4>
            {
              Array.isArray(clause.content) ?
                clause.content.map((item) => (
                  Array.isArray(item) ? (
                    <>
                      <ul>
                        {
                          item.map((list_item) => <li>{list_item}</li>)
                        }
                      </ul>
                      <br />
                    </>
                  ) : (
                    <>
                      <p>{item}</p>
                      <br />
                    </>
                  )
                )) : <p>{clause.content}</p>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsConditionsPage;
