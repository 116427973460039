import { Grid } from "@mui/material";
import CouponInputSection from "./CouponInputSection";
import "./PaymentDataSection.css";

const PaymentDataSection = ({ couponState, children }) => {
  const [coupon, setCoupon] = couponState
  return (
    <div className="payment-data-container">
      {
        coupon && coupon.image && (
          <div className="coupon-container">
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <p className="coupon-title">¡Felicidades!</p>
                <p className="coupon-subtitle">Este descuento es cortesía de:</p>
              </Grid>
              <Grid item>
                <img src={coupon.image} />
              </Grid>
            </Grid>
          </div>
        )
      }
      {
        children
      }
      <div className="payment-data-coupon-container">
        <p className="payment-data-coupon-title">
          Tengo cupón de descuento
        </p>
        <CouponInputSection setCoupon={setCoupon} />
      </div>
    </div>
  );
};

export default PaymentDataSection;
