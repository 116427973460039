import axios from "axios";
import { URL_API } from "../util/Configuration";
import { authHeader, handlerError, handlerResponse } from "./helpers/serviceConfig"

export const createCreditCarPayment = async (userId, body) => {
  return axios.post(`${URL_API}/payment/card/user/${userId}`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const createCreditCarPaymentByToken = async (userId, body) => {  
  return axios.post(`${URL_API}/payment/card/token/user/${userId}`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const createOxxoPayment = async (userId, body) => {
  return axios.post(`${URL_API}/payment/oxxo/user/${userId}`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const createPaypalPayment = async (userId, body) => {
  return axios.post(`${URL_API}/payment/paypal/user/${userId}`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const createCouponPayment = async (userId, body) => {
  return axios.post(`${URL_API}/payment/coupon/user/${userId}`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const openPaymentPDF = (paymentId) => {
  window.open(`${URL_API}/payment/${paymentId}/pdf`, "_blank")
}

