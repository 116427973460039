const CirculationCardSection = ({ premium, expiration, expire }) => {
  const handlerOnlineProcedure = () => {
    window.open("https://tramites.cdmx.gob.mx/tarjeta-circulacion/public/consulta-vigencia", "_blank")
  }
  return (
    <div className={`circulation-card-section ${premium ? 'premium' : ''}`}>
      <div className="content-section">
        <span className={`main-label ${premium ? 'premium' : ''}`}>
          Vigencia de la Tarjeta de Circulación
        </span>
        <span className="main-variable">
          {
            expiration.isValid() ? expiration.format('DD-MM-YYYY') : '-'
          }
        </span>
      </div>
      {
        expire && (
          <>
            <div className="content-section">
              <span className="main-label">
                {
                  premium && 'Estatus actual:'
                }
              </span>
              <span className="main-variable alert">
                Vencida
              </span>
            </div>
            {
              !premium && (
                <div className="content-section">
                  <button
                    className="main-button"
                    style={{ marginTop: '20px' }}
                    onClick={handlerOnlineProcedure}
                  >Realizar trámite en línea</button>
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default CirculationCardSection