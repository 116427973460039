import { useContext, useEffect } from "react";
import IntroductionSection from "../component/sections/IntroductionSection";
import HowSection from "../component/sections/HowSection";
import WhySection from "../component/sections/WhySection";
import AttentionSection from "../component/sections/AttentionSection";
import OpinionSection from "../component/sections/OpinionSection";
import DoubtsSection from "../component/sections/DoubtsSection";
import HomeCompaniesSection from "../component/sections/HomeCompaniesSection";
import HomeAdditionalServicesSection from "../component/sections/HomeAdditionalServicesSection";
import FooterSection from "../component/sections/FooterSection";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import './HomePage.css'
import HomeBlogSection from "../component/sections/HomeBlogSection";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import HomeSingleReportSection from "../component/sections/singleReport/HomeSingleReportSection";

const HomePage = () => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: true,
        listMenu: true,
        alerts: true,
        burger: true
      }
    })
  }, [])
  return (
    <>
      <div className="home-container">
        <IntroductionSection />
        <HomeCompaniesSection />
        <HowSection />
        <HomeSingleReportSection />
        <HomeAdditionalServicesSection />
        <WhySection />
        <OpinionSection />
        <AttentionSection />
        <DoubtsSection />
        <HomeBlogSection />
        <div className="content-button desktop">
          <Button className="alerta-purple-button" onClick={() => {
            history.push('/contratar_alerta')
          }}>
            <span className="alerta-purple-button-text">
              Contratar una Alerta
            </span>
          </Button>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default HomePage;