import { useEffect, useRef, useState } from 'react';
import SingleReportSection from './SingleReportSection';
import './HomeSingleReportSection.css'

const HomeSingleReportSection = () => {

  const background = useRef(null)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    setHeight(background.current.clientHeight)
  }, [background.current])
  return (
    <div className='home-single-report' >
      <div className='space' style={{ height: height }}>
        <div className='background' ref={background}>
          <div className='content' >
            <p className='title'>
              Informe vehicular GRATIS
            </p>
            <p className='subtitle'>
              ¿Quieres saber el estatus actual de tu vehículo con placas de la <b>CDMX</b> o <b>Estado de México</b>?<br /> <br className='mobile'/>
              Ingresa tus placas o NIV (No. de Serie) y recibe el informe en tu correo.
            </p>
            <SingleReportSection />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSingleReportSection