import './ServiceMainSection.css';
import './EnvironmentalSanctionServiceMainSection.css';
import useHeight from '../../hooks/useHeight';
import NoPenalityEnvironmentalSanctionSection from './environmentalSanction/NoPenalityEnvironmentalSanctionSection';
import FooterEnvironmentalSanctionSection from './environmentalSanction/FooterEnvironmentalSanctionSection';
import EnvironmentalSanctionSection from './environmentalSanction/EnvironmentalSanctionSection';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const environmentalSanctionStatus = [
  { name: "Todas", value: undefined },
  { name: "Pagadas", value: 'Pagada' },
  { name: "No Pagadas", value: 'No pagada' },
]

const EnvironmentalSanctionServiceMainSection = ({ vehicle, status, setServiceControl }) => {
  const [ref, height] = useHeight();
  let { services: { medioambiente } } = vehicle;
  const multas = Array.isArray(medioambiente) ? medioambiente.filter(({ situacion }) => situacion) : [];
  const noPenality = multas.length == 0;
  const [current, setCurrent] = useState(status);
  if (status)
    multas = multas.filter(({ situacion }) => situacion.includes(status))

  useEffect(() => {
    if (setServiceControl)
      setServiceControl(
        <Grid container spacing={1}>
          {
            environmentalSanctionStatus.map(({ name, value }) => {
              return (
                <Grid container item xs={4}>
                  <span className={`tenure-year ${current == value ? 'selected' : ''}`}
                    onClick={(e) => setCurrent(value)}
                  >
                    {name}
                  </span>
                </Grid>
              )
            })
          }
        </Grid>
      )
  }, [current])

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Sanción ambiental en la Ciudad de México</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          noPenality ? (
            <NoPenalityEnvironmentalSanctionSection />
          ) : (
            multas.map((item) => <EnvironmentalSanctionSection {...item} />)
          )
        }
        <FooterEnvironmentalSanctionSection />
      </div>
    </div>
  )
}

export default EnvironmentalSanctionServiceMainSection
