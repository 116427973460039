import { useEffect } from 'react';
import { getGummedColor } from '../../util/helpers';
import NoCirculateServiceMainSection from './NoCirculateServiceMainSection';
import './ServiceSideSection.css';

const NoCirculateServiceSideSection = ({ vehicle, selected, setMainPanel }) => {
  const { services: { no_circula: { hologram, comment, description } } } = vehicle;
  const gummed_color = getGummedColor(description);

  useEffect(() => {
    if (selected == 'no_circulate')
      setMainPanel(<NoCirculateServiceMainSection vehicle={vehicle} />)
  }, [selected, vehicle])

  return (
    <div className="service-side-section">
      <div>
        <p className="title">Holograma {hologram == 'exempt' ? 'Exento' : hologram}</p>
        <div className="tag" style={{ backgroundColor: `${gummed_color}` }}><span>Terminación {description}</span></div>
        <p>
          <span className="side-label">Circulación</span>
          <span className="side-variable">{comment}</span>
        </p>
      </div>
    </div>
  )
}

export default NoCirculateServiceSideSection
