import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, MenuItem, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import moment from "moment";
import { Controller, useFormContext, useWatch } from "react-hook-form"
import monthNames from '../../data/monthNames';
import { getYears } from '../../util/helpers';
import './SetVehicleHologramSection.css';

const years = getYears(moment().year() - 6, moment().year());

const SetVehicleHologramSection = () => {
  const { control } = useFormContext()
  const hologram = useWatch({ control, name: 'hologram' });
  return (
    <div className="vehicle-hologram-section">
      <Grid container spacing={3} >
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <p>
              Selecciona el holograma de verificación
            </p>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="hologram"
              control={control}
              rules={{
                required: "Debes seleccionar un tipo de verificación"
              }}
              render={({ field: { value, onChange } }) => (
                <ToggleButtonGroup className="hologram-buttons" size="small" value={value} onChange={onChange} exclusive={true}>
                  <ToggleButton className="hologram-button" value="exempt" key="exempt">Exento</ToggleButton>,
                  <ToggleButton className="hologram-button" value="00" key="00">00</ToggleButton>,
                  <ToggleButton className="hologram-button" value="0" key="0">0</ToggleButton>,
                  <ToggleButton className="hologram-button" value="1" key="1">1</ToggleButton>
                  <ToggleButton className="hologram-button" value="2" key="2">2</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Grid>
        </Grid>
        {
          hologram != 'exempt' && (
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <p >
                  Ingresa la fecha de la última verificación
                </p>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="hologramMonth"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      select
                      size="small"
                      label="Mes"
                      className={value && "hologram-input-selected"}
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                    >
                      {monthNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="hologramYear"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextField
                      select
                      size="small"
                      label="Año"
                      className={value && "hologram-input-selected"}
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                    >
                      {years.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </div>
  )
}

export default SetVehicleHologramSection
