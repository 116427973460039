import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../contexts';
import { estateTypes } from '../../reducers/types';
import { Images } from '../../util';
import { scrollToElement } from '../../util/helpers';

import "./EstateSection.css";

const EstateSection = ({ id, name, image, imageBold, setShowLimitVehiclesByEstate }) => {
  const { app: { vehicles }, dispatch } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const [imageError, setImageError] = useState(false)
  const minCar = vehicles ? vehicles.filter(({ estate, complete, type }) => estate == name && complete && type == 0).length : null;
  const minMoto = vehicles ? vehicles.filter(({ estate, complete, type }) => estate == name && complete && type == 1).length : null;
  const [counterCar, setCounterCar] = useState(minCar || 0);
  const [counterMoto, setCounterMoto] = useState(minMoto || 0);
  const vehicleTypes = [
    {
      name: 'car',
      logo: Images.ESTATE_LOGO_CAR,
      counter: counterCar,
      onFocus: (e) => handlerFocus(e.currentTarget, setCounterCar),
      onSetValue: (e) => handlerSetValue(e.currentTarget.value, setCounterCar, minCar),
      onBlur: (e) => handlerBlur(e.currentTarget.value, setCounterCar, counterCar),
      onCounterUp: () => handlerCounterUp(setCounterCar, counterCar, counterMoto),
      onCounterDown: () => handlerCounterDown(setCounterCar, counterCar, counterMoto, minCar)
    },
    {
      name: 'moto',
      logo: Images.ESTATE_LOGO_MOTO,
      counter: counterMoto,
      onFocus: (e) => handlerFocus(e.currentTarget, setCounterMoto),
      onSetValue: (e) => handlerSetValue(e.currentTarget.value, setCounterMoto, minMoto),
      onBlur: (e) => handlerBlur(e.currentTarget.value, setCounterMoto, counterMoto),
      onCounterUp: () => handlerCounterUp(setCounterMoto, counterMoto, counterCar),
      onCounterDown: () => handlerCounterDown(setCounterMoto, counterMoto, counterCar, minMoto)
    }
  ]

  useEffect(() => {
    if (vehicles) {
      setCounterCar(vehicles.filter(({ estate, type }) => estate == name && type == 0).length)
      setCounterMoto(vehicles.filter(({ estate, type }) => estate == name && type == 1).length)
    }
  }, [vehicles])

  useEffect(() => {
    if (counterCar == null && counterMoto == null)
      return;
    if (counterCar == 0 && counterMoto == 0) {
      dispatch({
        type: estateTypes.deleteEstate,
        payload: id
      })
    } else {
      if ((counterCar + counterMoto) == 10)
        setShowLimitVehiclesByEstate({ open: true })
      setActive(true);
      dispatch({
        type: estateTypes.createEstate,
        payload: {
          id,
          name,
          autos: counterCar,
          motos: counterMoto
        }
      })
    }
  }, [counterCar, counterMoto])

  const handlerSelectEstate = () => {
    if (minCar || minMoto) {
      setCounterCar(minCar);
      setCounterMoto(minMoto);
    } else {
      setActive(!active)
      if (!active) {
        setCounterCar(1);
      } else {
        setCounterCar(0);
        setCounterMoto(0);
      }
    }

  }

  const handlerBlur = (value, setCounter, counter) => {
    setCounter(value ? parseInt(value) : 0);
    if (value == 0) {
      if (counter == 0)
        setActive(false);
    } else
      setActive(true);
  }

  const handlerFocus = (input, setCounter) => {
    scrollToElement({ current: document.getElementById(id) }, 25);
    if (input.value == 0) {
      setCounter('');
    } else {
      input.setSelectionRange(input.value.length, input.value.length);
    }
    setActive(true);
  }

  const handlerSetValue = (value, setCounter, min) => {
    if (value?.length > 2)
      return;
    if (value < min)
      return;
    setCounter(value ? parseInt(value) : 0);
  }

  const handlerCounterUp = (setCounter, counter) => {
    setCounter(counter + 1);
    setActive(true);
  }

  const handlerCounterDown = (setCounter, counter1, counter2, min) => {
    if (counter1 > 0) {
      if (counter1 - 1 < min)
        return;
      setCounter(counter1 - 1);
      if (counter1 - 1 == 0 && counter2 == 0) {
        setActive(false);
      }
    }
  }

  const onErrorEstateImage = () => {
    setImageError(true);
  }

  return (
    <div id={id} className={`estate-section ${active ? 'active' : 'inactive'}`}>
      <div className="estate-section-container" onClick={handlerSelectEstate} >
        <div className={`estate-section-container-card ${active ? 'active' : 'inactive'}`}>
          {
            active && <img className="estate-section-arrow-done" src={Images.ACCOUNT_DONE_BLUE} />
          }
          <br></br>
          {
            imageError ?
              <FontAwesomeIcon className='estate-section-img-icon' icon="image" size="5x" /> :
              <img className='estate-section-img' src={active ? imageBold : image} onError={onErrorEstateImage} />
          }

          <p className="estate-section-text">{name}</p>
        </div>
      </div>
      {
        vehicleTypes.map((vehicle, i) => (
          <div
            key={`vehicleEstateSection${i}`}
            className={`estate-section-counter-container ${active ? 'active' : 'inactive'}`}
            onClick={() => !active && vehicle.onCounterUp()}
          >
            <img className={`estate-section-counter-img ${active ? 'active' : 'inactive'}`}
              src={vehicle.logo}
            />
            <div className="estate-section-arrows_container desktop">
              <img className={`estate-section-arrow-up ${active ? 'active' : 'inactive'}`}
                src={Images.COUNTER_UP_ARROW}
                onClick={vehicle.onCounterUp}
              />
              <img className={`estate-section-arrow-down ${active ? 'active' : 'inactive'}`}
                src={Images.COUNTER_DOWN_ARROW}
                onClick={vehicle.onCounterDown}
              />
            </div>
            <input className={`desktop estate-section-counter-input ${active ? 'active' : 'inactive'}`}
              value={vehicle.counter}
              readOnly
            />
            <input inputMode="decimal" pattern="\d*" className={`mobile estate-section-counter-input ${active ? 'active' : 'inactive'}`}
              value={vehicle.counter}
              onChange={vehicle.onSetValue}
              onBlur={vehicle.onBlur}
              onFocus={vehicle.onFocus}
            />
          </div>
        ))
      }
    </div>
  )
}

export default EstateSection
