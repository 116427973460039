import { useState, useEffect, useContext } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import { appTypes, userTypes, vehicleTypes } from "../reducers/types";
import { AppContext } from "../contexts";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./SuscriberPage.css";
import parse from "html-react-parser";
import { isMobile } from 'react-device-detect';
import { createUser } from "../services/UserServices";
import { rawListeners } from "process";
import { toTitleCase } from "../util/helpers";

const SuscriberPage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const { handleSubmit, control, setError, setValue } = useForm();
  const texts = useSelector((store) => store.page.register_flow.who_suscriber);

  const [loading, setLoading] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const allWatcher = useWatch({
    control,
    name: ['name', 'lastname', 'email'],
    defaultValue: ['', '', '']
  });
  const emailWatcher = useWatch({ control, name: 'email' });

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        back: () => history.push('/contratar_alerta')
      }
    })
    if (user) {
      setValue('name', user.name);
      setValue('lastname', user.lastname);
      setValue('email', user.email);
    }
  }, [])

  useEffect(() => {
    const [name, lastname, email] = allWatcher
    setActivateButton(name != '' && lastname != '' && email != '')
  }, [allWatcher])

  useEffect(() => {
    setShowMessage(false);
  }, [emailWatcher])

  const onSubmit = async (user) => {
    setLoading(true)
    const { success, message, data } = await createUser(user);
    setLoading(false)
    if (success) {
      dispatch({
        type: userTypes.createUser,
        payload: {
          ...user,
          id: data.id,
          token: data.token
        }
      })
      if (data.vehicles && data.vehicles?.length != 0) {
        dispatch({
          type: vehicleTypes.createVehicles,
          payload: data.vehicles.map((vehicle) => {
            vehicle.complete = true
            return vehicle
          })
        })
      }
      history.push("/find_estate");
    } else {
      if (message == 'email_exists') {
        setError("email", {
          message: 'El correo ya está registrado en una cuenta'
        });
        setShowMessage(true);
      }
    }
  };

  return (
    <div className="suscriber-container">
      <div className="container">
        <div className="suscriber-form-container">
          <form onSubmit={handleSubmit(onSubmit)} className="who_suscriber-form-container">
            <div className="suscriber-content-input">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Debes llenar el campo de 'Nombre(s)'"
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    label="Nombre(s)"
                    variant="outlined"
                    fullWidth
                    autoFocus={!isMobile}
                    value={value}
                    onChange={(e) => onChange(toTitleCase(e.target.value))}
                    onBlur={(e) => setValue(name, e.target.value.trim())}
                    error={!!error}
                    helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                  />
                )}
              />
            </div>
            <div className="suscriber-content-input">
              <Controller
                name="lastname"
                control={control}
                defaultValue=""
                rules={{ required: "Debes llenar el campo de 'Apellidos'" }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    label="Apellidos"
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(toTitleCase(e.target.value))}
                    onBlur={(e) => setValue(name, e.target.value.trim())}
                    error={!!error}
                    helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                  />
                )}
              />
            </div>
            <div className="suscriber-content-input">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Debes llenar el campo de 'Correo Electrónico'",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'El correo electrónico no es valido'
                  }
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    label="Correo electrónico"
                    variant="outlined"
                    name={name}
                    fullWidth
                    value={value.toLowerCase()}
                    onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                    error={!!error}
                    helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                  />
                )}
              />
              {
                showMessage && (
                  <div className="suscriber-find-alerts-container">
                    <div>
                      <p className="suscriber-find-alerts-p-title">
                        {parse(texts.note_title)}
                      </p>

                      <p className="suscriber-find-alerts-p-subtitle">
                        {parse(texts.note_body)}
                      </p>
                    </div>
                    <div className="suscriber-find-alerts-container-button">
                      <button className="suscriber-find-alerts-button" onClick={() => history.push('/alertas')}>
                        {parse(texts.note_button)}
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="suscriber-button-container">
              <Button
                disabled={!activateButton}
                className="alerta-purple-button"
                type="submit"
              >
                <span className="alerta-purple-button-text">Siguiente</span>
                {
                  loading && (
                    <span className="alerta-purple-button-progress">
                      <CircularProgress
                        size={30}
                        color='inherit'
                      />
                    </span>
                  )
                }
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuscriberPage;
