import { Grid } from '@mui/material'
import { useHistory } from 'react-router'
import { Images } from '../../util'
import './AdditionalServicesSection.css'
const additionalServices = [
  {
    label: 'Contrata un Seguro',
    // path: '/insurance'
  },
  {
    label: 'Reglamento de Tránsito',
    // path: '/traffic_regulation'
  },
  {
    label: 'Corralón',
    // path: '/corralon'
  },
  {
    label: 'Blog',
    path: '/blog'
  },
  {
    label: 'Vende tu Auto',
    // path: '/sell_car'
  },
  {
    label: 'Asistencia Vial',
    // path: '/roadside_assistance'
  },
  {
    label: 'Traza tu Ruta',
    // path: '/trace_route'
  },
]

const AdditionalServicesSection = () => {
  let history = useHistory()
  return (
    <div className="additional-services-section">
      <p className="additional-services-section-title">Servicios adicionales <span>(Próximamente)</span></p>
      <Grid container spacing={3}>
        {
          additionalServices.map(({ label, path }, i) => (
            <Grid item key={`additional_service_${i}`} xs={12} sm={6} md={4}>
              <div className="additional-service-container" onClick={() => path && history.push(path)}>
                <img src={Images.ALERTA_MI_AUTO_WHITE} alt="alertamiauto logo" />
                <span>{label}</span>
              </div>
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

export default AdditionalServicesSection
