import { Button, CardContent, Dialog, Grid, Typography } from "@mui/material";
import { Images } from "../../util";

const PendingVehiclesDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="register-car-close-dialog-container"
      
    >
      <Grid container justifyContent="center" rowSpacing={2} >
        <Grid item xs={12} container justifyContent="flex-end">
          <img
            onClick={onClose}
            src={Images.DIALOG_CLOSE_ICON}
            style={{
              marginRight: "15px",
              marginTop: "15px",
              cursor: "pointer",
            }}
          ></img>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          className="register-car-close-dialog-title"
        >
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "15px",
              fontWeight: "bold",
              lineHeight: "20px",
              textAlign: "center",
              color: "#434362",
              margin: '0'
            }}
          >
            Faltó completar la información de los <br />
            siguientes vehículos:
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          spacing={1}
          style={{maxHeight: '255px', overflow: 'auto', marginTop: '10px'}}
        >
          {
            data.filter((v) => !v.complete).map((e, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={5}
                  container
                  justifyContent="center"
                >
                  <CardContent
                    style={{
                      height: "50px",
                      background: "#F0F1F4",
                      width: "100px",
                      marginBottom: "15px",
                      borderRadius: "7px",
                    }}
                  >
                    <Typography style={{ textAlign: "center" }}>
                      <img
                        style={{
                          height: "18px",
                        }}
                        src={
                          e.type === 0
                            ? Images.INACTIVE_CAR
                            : Images.INACTIVE_MOTO
                        }
                      ></img>
                    </Typography>
                    <Typography
                      className="register-car-card-title"
                      style={{
                        fontFamily: "Roboto",
                        textAlign: "center",
                        fontSize: "11px",
                        lineHeight: "19px",
                        fontWeight: 500,
                        color: "#434362",
                      }}
                    >
                      {e.estate}
                    </Typography>
                    <Typography
                      className="register-car-card-text"
                      style={{
                        fontFamily: "Roboto",
                        textAlign: "center",
                        fontSize: "9px",
                        lineHeight: "16px",
                        fontWeight: 400,
                        color: "#707c89",
                      }}
                    >
                      {e.type === 0
                        ? `Auto ${e.number}`
                        : `Moto ${e.number}`}
                    </Typography>
                  </CardContent>
                </Grid>
              );
            })
          }
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button className="alerta-purple-button" onClick={() => { onSuccess(data) }}>
            <span className="alerta-purple-button-text">Borrar y continuar</span>
          </Button>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button className="alerta-white-button" onClick={onClose} style={{ marginBottom: '20px' }}>
            <span className="alerta-white-button-text">Cancelar</span>
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PendingVehiclesDialog
