import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import './CalendarSection.css';
import moment from 'moment';
import { toTitleCase } from '../../util/helpers';
import { useEffect, useState } from 'react';

const dayNames = [1, 2, 3, 4, 5, 6, 0]

const colors = ['#F8EFC7', '#F7DEEB', '#FFCBCB', '#D0FACF', '#BEF0FF', '#E0DFED']

const CalendarSection = ({ month, year, vehicle }) => {
  let { services: { no_circula: { description, rules, custom_rules } } } = vehicle;
  rules = rules || [];
  const [current, setCurrent] = useState({ month, year });
  const [showDays, setShowDays] = useState([])
  const [saturday, setSaturday] = useState(false)
  const [colorDay, setColorDay] = useState(null)
  const dateControl = moment({ ...current });
  let days = []
  let colorDays = {}
  
  useEffect(() => {
    dateControl.date(1);
    days = [];
    colorDays = {}
    setColorDays();
    week(1);
    setShowDays(days);
  }, [current])

  const week = (weekCount) => {
    dayNames.forEach((day) => {
      const dayInMonth = dateControl.weekday() == day && dateControl.month() == current.month;
      days = [...days, {
        date: dayInMonth ? dateControl.format('DDMMYYYY') : `w${weekCount}d${day}`,
        name: dayInMonth ? dateControl.format('D') : '',
        today: dayInMonth ? moment().format('DDMMYYYY') == dateControl.format('DDMMYYYY') : false,
        color: dayInMonth ? colorDays[dateControl.format('DDMMYYYY')] : null
      }]
      if (dayInMonth)
        dateControl.add(1, 'days')
    })
    if (dateControl.month() == current.month)
      week(++weekCount);
  }

  const handlerNextMonth = () => {
    const next = moment({ ...current }).add(1, 'months')
    if (next.month() > (moment().month() + 6))
      return;
    setCurrent({ month: next.month(), year: next.year() })
  }

  const handlerPrevMonth = () => {
    const prev = moment({ ...current }).subtract(1, 'months')
    if (prev.month() < moment().month())
      return;
    setCurrent({ month: prev.month(), year: prev.year() })
  }

  const setColorDays = () => {
    rules.map((rule) => {
      rule.calendar_days.forEach(day => {
        const colorDay = moment({ day: day }).day() - 1;
        if (!saturday && rule.day == 'saturday')
          setSaturday(true)
        else
          setColorDay(colors[colorDay]);
        colorDays[moment({ day: day }).format('DDMMYYYY')] = colors[colorDay]
      })
      return rule;
    })
    custom_rules.map((rule) => {
      rule.calendar_days.forEach(day => {
        if (moment({ day: day }).format('DDMMYYYY') in colorDays)
          return;
        colorDays[moment({ day: day }).format('DDMMYYYY')] = colors[moment({ day: day }).day() - 1]
      })
      rule.color = colors[moment({ day: rule.calendar_days[0] }).day() - 1];
      return rule;
    })
  }

  return (
    <div className="calendar-section">
      <Grid container >
        <Grid container item className="calendar-title">
          <Grid container item xs={3} justifyContent="center">
            <div className="next-month" onClick={() => handlerPrevMonth()}>
              <FontAwesomeIcon icon="chevron-left" />
            </div>
          </Grid>
          <Grid container item xs={6} justifyContent="center">
            <span className="current-month" style={{whiteSpace: "nowrap"}}>
              {toTitleCase(dateControl.format('MMMM'))} {dateControl.year()}
            </span>
          </Grid>
          <Grid container item xs={3} justifyContent="center">
            <div className="prev-month" onClick={() => handlerNextMonth()}>
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </Grid>
        </Grid>
        <Grid container item className="calendar-main-section">
          <Grid container item columns={7}>
            {
              dayNames.map((day) => (
                <Grid container item xs={1} key={day} justifyContent="center">
                  <span className="day-name-title">{toTitleCase(moment().weekday(day).format('dd'))}</span>
                </Grid>
              ))
            }
          </Grid>
          <Grid container item columns={7}>
            {showDays.map(({ name, date, today, color }) => (
              <Grid container item xs={1} key={date} justifyContent="center">
                <span className={`day-number ${today ? 'today' : ''}`} style={{ backgroundColor: color }}>{name}</span>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item className="calendar-footer">
          {
            rules.length != 0 && (
              <Grid item xs={12} >
                <div className="rule-description-container">
                  <div className="rule-color" style={{ backgroundColor: colorDay }}></div>
                  {
                    saturday && <div className="rule-color" style={{ backgroundColor: colors[5] }}></div>
                  }
                  <span className="rule-description">Días que no circulas en un horario de las 5 a las 22 hrs</span>
                </div>
              </Grid>
            )
          } 
          {
            custom_rules.map(({ title, color }, i) => (
              <Grid item xs={12} >
                <div className="rule-description-container">
                  <div className="rule-color" style={{ backgroundColor: color }}></div>
                  <span className="rule-description">{title}</span>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default CalendarSection
