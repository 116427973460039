import { useContext, useEffect } from "react";
import ProcessingServicesSection from "../../component/sections/ProcessingServicesSection";
import { AppContext } from "../../contexts";
import BeforeExitMessage from "../../helpers/BeforeExitMessage";
import { appTypes, singleReportTypes } from "../../reducers/types";
import { sendBasicSingleReportEmail } from "../../services/singleReportService";
import "./SingleReportLoadingPage.css";

const SingleReportLoadingPage = ({ history }) => {
  const { app: { singleReport: { vehicle, type } }, dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        listMenu: true,
        burger: false,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "¡Espera un <span>momento!</span>",
        subtitle: "Estamos procesando la información de tu informe",
      }
    })
  }, [])

  return (
    <div className="single-report-loading-page">
      <div className="container">
        <ProcessingServicesSection
          vehicleId={vehicle.id}
          onSuccess={(name, value) => {
            dispatch({
              type: singleReportTypes.createService,
              payload: { name, value }
            });
          }}
          onFinish={() => {
            if (type == 'basic') {
              sendBasicSingleReportEmail(vehicle.id)
              history.push('/resultado_informe_basico')
            } else {
              history.push('/pago_informe_premium')
            }
          }}
        />
      </div>
      <BeforeExitMessage />
    </div>
  );
};

export default SingleReportLoadingPage;
