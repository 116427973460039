import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './ErrorCardPaymentDialog.css';

const ErrorCardPaymentDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="error-card-payment-dialog"
    >
      <div className="error-card-payment-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.ERROR_CARD_PAYMENT} alt="" />
          </Grid>
          <Grid item >
            <p className="error-card-payment-dialog-title">
              Se ha producido un error
            </p>
          </Grid>
          <Grid item >
            <p className="error-card-payment-dialog-subtitle">
              Revise la información de la tarjeta y vuelva a intentarlo o póngase en contacto con su Banco para obtener más ayuda
            </p>
          </Grid>
          <Grid item className="error-card-payment-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onSuccess} >
              <p className="alerta-purple-button-text">Volver a intentarlo</p>
            </Button>
          </Grid>
          <Grid item>
            <Button className="alerta-white-button" onClick={onClose}>
              <p className="alerta-white-button-text">Cancelar</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default ErrorCardPaymentDialog
