import { useState, useEffect, useContext } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";

import { appTypes, userTypes } from "../reducers/types";
import { AppContext } from "../contexts";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobile } from 'react-device-detect';
import { login } from "../services/UserServices";
import Moment from "react-moment";
import "./LoginPage.css";
import moment from "moment";

const LoginPage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const { handleSubmit, control, setError } = useForm();
  const [loading, setLoading] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const email = useWatch({ name: 'email', control, defaultValue: '' });
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Ingresa tu <span>correo electrónico</span> para ingresar a tu cuenta",
        back: () => history.push('/contratar_alerta')
      }
    })
  }, [])

  useEffect(() => {
    if (email != '')
      setActivateButton(true);
  }, [email])


  const onSubmit = async ({ email }) => {
    setLoading(true);
    const { success, message, data } = await login(email);
    if (success) {
      const attemps = user && user.loginAttemps ? user.loginAttemps + 1 : 1;
      dispatch({
        type: userTypes.createUser,
        payload: {
          email,
          loginToken: data.message,
          loginAttemps: attemps,
          timeToResendEmail: attemps >= 3 ? moment().add(1, 'm') : null
        }
      })
      history.push("/login_confirm_code");
    } else {
      setError("email", {
        message: 'Usuario no encontrado'
      });
    }
    setLoading(false);
  };

  const onHandlerTimer = (time) => {
    if (time == '00') {
      dispatch({
        type: userTypes.updateUser,
        payload: {
          loginAttemps: 0,
          timeToResendEmail: null
        }
      })
    }
  }

  const toFilterTime = (time) => {
    return time.replace('-', '');
  }

  return (
    <div className="login-container">
      <div className="container">
        <div className="login-form-container">
          <form onSubmit={handleSubmit(onSubmit)} className="who_login-form-container">
            <div className="login-content-input">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Debes llenar el campo de 'Correo Electrónico'",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'El correo electrónico no es valido'
                  }
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <TextField
                    label="Correo electrónico"
                    variant="outlined"
                    fullWidth
                    name={name}
                    value={value}
                    autoFocus={!isMobile}
                    onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                    style={{ backgroundColor: "#fff" }}
                    error={!!error}
                    helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                    inputProps={{
                      style: {
                        paddingLeft: 30,
                        fontSize: "16px",
                        color: "#434362",
                        borderColor: "#CA0F4A",
                      },
                    }}
                  />
                )}
              />
            </div>
            <div className="login-button-container">
              {
                user && user.timeToResendEmail ? (
                  <div style={{ color: '#434362', fontSize: '14px' }}>
                    Solicita otro envío en
                    <Moment durationFromNow trim style={{margin: '0px 3px'}}
                      format="mm:ss"
                      interval={1000}
                      onChange={onHandlerTimer}
                      filter={toFilterTime}
                    >{user.timeToResendEmail}</Moment>
                    segs
                  </div>
                ) : (
                  <Button
                    disabled={!activateButton}
                    className="alerta-purple-button"
                    type="submit"
                  >
                    <span className="alerta-purple-button-text">Siguiente</span>
                    {
                      loading && (
                        <span className="alerta-purple-button-progress">
                          <CircularProgress
                            size={30}
                            color='inherit'
                          />
                        </span>
                      )
                    }
                  </Button>
                )
              }
            </div>
          </form>
        </div>
        <p className="contract-alert" onClick={() => history.push("/contratar_alerta")}>
          Contratar una Alerta
          <FontAwesomeIcon className="icon" icon="arrow-right" />
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
