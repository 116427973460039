import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ConfigNotificationSection.css";
import { AppContext } from "../../contexts";
import { userTypes } from "../../reducers/types";
import { Button, CircularProgress, Grid } from "@mui/material";
import { getUserNotificationsConfig, updateUserNotificationsConfig } from "../../services/UserServices";
import { scrollToElement } from "../../util/helpers";

const notificationsDefault = {
  whats: false,
  sms: false,
  email: false,
  none: false
}

const ConfigNotificationSection = ({ onSave }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [notifications, setNotifications] = useState([])

  useEffect(async () => {
    const { success, data, message } = await getUserNotificationsConfig(user.id);
    if (success)
      setNotifications(data.alerts.filter(({ key }) => key != "seguro"))
  }, [])

  useEffect(() => {
    if (notifications.length == 0)
      return;
    const counter = notifications.reduce((prev, { whats, sms, email, none }) => {
      if (whats || sms || email || none) return prev + 1;
      return prev;
    }, 0)
    setEnableButton(counter == notifications.length)
  }, [notifications])

  const handlerClickNotification = (id, type, value) => {
    let scrollToNext = false;
    setNotifications(notifications.map((notification) => {
      if (scrollToNext) {
        scrollToNext = false;
        const isVisible = window.pageYOffset + window.innerHeight - 100 >= document.getElementById(notification.id).offsetTop;
        if (!isVisible)
          window.scroll({
            top: window.pageYOffset + 200,
            behavior: 'smooth'
          })
      }
      if (notification.id == id) {
        notification = { ...notification, ...notificationsDefault }
        notification[type] = value
        scrollToNext = true;
      }
      return notification
    }))
  }

  const saveNotifications = async () => {
    setLoading(true)
    const { success, data, message } = await updateUserNotificationsConfig(user.id, notifications);
    if (success) {
      dispatch({
        type: userTypes.updateUser,
        payload: {
          notifications
        }
      })
      onSave();
    }
    setLoading(false)
  }
  return (
    <div className="config-notifications-container">
      <div className="header-message">
        <div className="header-message-icon">
          <FontAwesomeIcon icon="check-circle" size="lg" />
        </div>
        <div>
          Selecciona el método de envío de notificaciones para cada uno de los servicios que ofrecemos:
        </div>
      </div>
      <div className='main-panel'>
        <div className="notification-container desktop">
          <Grid container flexDirection="row">
            <Grid container item xs={8}></Grid>
            <Grid container item xs={1} justifyContent="center">
              <p className="title">WhatsApp</p>
            </Grid>
            <Grid container item xs={1} justifyContent="center">
              <p className="title">SMS</p>
            </Grid>
            <Grid container item xs={1} justifyContent="center">
              <p className="title">Email</p>
            </Grid>
            <Grid container item xs={1} justifyContent="center">
              <p className="title none-option">Ninguno</p>
            </Grid>
          </Grid>
        </div>
        {
          notifications.map(({ id, label, whats, sms, email, none }, i) => (
            <div className="notification-container">
              <Grid container flexDirection="row">
                <Grid container item xs={12} sm={8}>
                  <p className="label">{label}</p>
                </Grid>
                <Grid container item xs={3} sm={1} justifyContent="center">
                  <p className="title mobile">WhatsApp</p>
                  <p>
                    <FontAwesomeIcon onClick={() => handlerClickNotification(id, 'whats', !whats)} className="icon" icon={[(whats ? "fas" : "far"), "circle"]} size="2x" />
                  </p>
                </Grid>
                <Grid container item xs={3} sm={1} justifyContent="center">
                  <p className="title mobile">SMS</p>
                  <p>
                    <FontAwesomeIcon onClick={() => handlerClickNotification(id, 'sms', !sms)} className="icon" icon={[(sms ? "fas" : "far"), "circle"]} size="2x" />
                  </p>
                </Grid>
                <Grid container item xs={3} sm={1} justifyContent="center">
                  <p className="title mobile">Email</p>
                  <p>
                    <FontAwesomeIcon onClick={() => handlerClickNotification(id, 'email', !email)} className="icon" icon={[(email ? "fas" : "far"), "circle"]} size="2x" />
                  </p>
                </Grid>
                <Grid container item xs={3} sm={1} justifyContent="center">
                  <p className="none-option title mobile">Ninguno</p>
                  <p className="none-option">
                    <FontAwesomeIcon onClick={() => handlerClickNotification(id, 'none', !none)} className={`icon ${none ? 'selected' : ''}`} icon={[(none ? "fas" : "far"), "circle"]} size="2x" />
                  </p>
                </Grid>
              </Grid>
              <div id={id}></div>
            </div>
          ))
        }
      </div>
      <p className="footer-message">* Puedes modificar el método de envío de las notificaciones en cualquier momento dentro del Menú en Mis Alertas.</p>
      <div className="config-notifications-button-container">
        <Button className="alerta-purple-button" onClick={saveNotifications} disabled={!enableButton}>
          <span className="alerta-purple-button-text">Guardar</span>
          {
            loading && (
              <span className="alerta-purple-button-progress">
                <CircularProgress size={30} color='inherit' />
              </span>
            )
          }
        </Button>
      </div>
    </div>
  );
};
export default ConfigNotificationSection;
