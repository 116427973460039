const descriptions = [
  {
    id: 1,
    description: '¿Cuánto cuesta contratar una Alerta para mi auto?',
    answer: 'La suscripción anual por motocicleta o Automóvil es de $399 pesos (IVA incluido). Puedes cancelar la suscripción en cualquier momento.'
  },
  {
    id: 2,
    description: '¿Hay límite sobre cuántos vehículos puedo registrar?',
    answer: 'Si, puedes registrar hasta 20 vehículos en la plataforma. Si quieres registrar más de 20 vehículos, contáctanos y te ofrecemos un precio especial de flotilla.'
  },
  {
    id: 3,
    description: '¿Cómo me envían las notificaciones para cada Alerta?',
    answer: 'En el momento que tu vehículo genere una Alerta, te enviaremos notificaciones por WhatsApp, mensaje SMS o correo electrónico. Cada usuario selecciona el método de envío por cada servicio.'
  },
  {
    id: 4,
    description: '¿En qué momento mi vehículo genera una Alerta?',
    answer: 'Una vez registrado tu vehículo, te notificaremos cuando tu vehículo le pongan una infracción o fotocívica, tengas que pagar la Tenencia, esté en periodo de verificación, tengas que actualizar tu tarjeta de circulación o simplemente cuando no circule.'
  },
  {
    id: 5,
    description: '¿Puedo registrar cualquier automóvil a nivel nacional?',
    answer: 'El servicio no está disponible a nivel nacional. Antes de registrar un vehículo, tienes que seleccionar el Estado de donde son tus placas. Puedes contratar el servicio “Foráneo” y recibes el servicio de otro Estado al de tus placas. Si tu Estado no está activo, puedes ingresar tu correo electrónico y te notificaremos cuando esté listo y puedas registrarlo.'
  },
  {
    id: 6,
    description: '¿Cómo funciona el registro “Foráneo”?',
    answer: 'Si tus placas son de otro Estado de donde vives y te interesa tener el servicio de ese Estado, puedes seleccionar el Estado de tu interés, ingresar las placas de tu vehículo y registrarlo para recibir las Alertas (notificaciones) del Estado seleccionado.'
  },
  {
    id: 7,
    description: '¿Puedo registrar a otro usuario para recibir notificaciones?',
    answer: 'Si, una vez que registres tus vehículos, en Mis Alertas dale clic en Editar y registra al usuario que deseas que reciba las notificaciones de ese vehículo.'
  },
  {
    id: 8,
    description: '¿Cuáles son los métodos de pago disponibles?',
    answer: 'Puedes pagar la suscripción anual con cualquier tarjeta de crédito o débito (VISA, Mastercard o Amex), Paypal o en efectivo (OXXO Pay)'
  },
  {
    id: 9,
    description: '¿Qué pasa con mi pago si cancelo la suscripción?',
    answer: 'La suscripción es anual y no es transferible. Si realizas una cancelación, se verá reflejado hasta el siguiente año ya que de momento no hay devoluciones. '
  },
  {
    id: 10,
    description: '¿Puedo pagar los impuestos desde la plataforma?',
    answer: 'Por el momento no tenemos las ligas habilitadas para que puedas pagar impuestos como la Tenencia o tus infracciones vehiculares al Gobierno. Estamos trabajando para que lo puedas realizar más adelante.'
  },
  {
    id: 11,
    description: '¿Hay descuento si registro más de un automóvil?',
    answer: 'Si quieres registrar más de 20 vehículos, contáctanos y te hacemos un precio especial de flotilla. Además, todos los meses tenemos campañas de publicidad en distintos medios (tradicionales y digitales) con algunas promociones y códigos de descuento.'
  },
  {
    id: 12,
    description: '¿Si tengo un problema, que hago?',
    answer: 'Tenemos un Centro de Atención Digital en donde nos podrás contactar en nuestro Chat o enviándonos un correo electrónico a: hola@alertamiauto.com y con mucho gusto te ayudaremos a resolver tus dudas o problemas con la plataforma.'
  },
]

export default descriptions;