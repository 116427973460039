import { Button, Grid } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../contexts";
import { Images } from "../../util";
import './WhySection.css';

const WhySection = () => {
  const { app: { home: { why } } } = useContext(AppContext);
  const history = useHistory();
  return (
    <div ref={why} className="home-why-section">
      <Grid container>
        <Grid container item xs={12} md={6} flexDirection="column" justifyContent="flex-start" alignContent="center">
          <p className="title">¿Es buena idea contratar una Alerta?</p>
          <div className="subtitle">
            <p>
              Nosotros te ayudamos para que no tengas que pagar multas o recargos en cualquiera de tus vehículos (automóvil o motocicleta).
            </p>
            <br />
            <p>
              Las notificaciones que te enviamos son mensajes de WhatsApp, SMS o correo electrónico
            </p>
          </div>
          <div className="images">
            <div className="image-content whats">
              <img src={Images.NOTIFICATION_ICON_WHATS} />
            </div>
            <div className="image-content sms">
              <img src={Images.NOTIFICATION_ICON_MSN} />
            </div>
            <div className="image-content email">
              <img src={Images.NOTIFICATION_ICON_EMAIL} />
            </div>
          </div>
          <Button className="alerta-purple-button desktop" onClick={() => history.push('/contratar_alerta')}>
            <span className="alerta-purple-button-text">Contratar una Alerta</span>
          </Button>
        </Grid>
        <Grid container item xs={12} md={6}>
          <img style={{ maxHeight: '530px' }} className="side-image" src={Images.WHY_HOME_SECTION} alt="Buena Idea" />
        </Grid>
      </Grid>
      <p className="note">
        * La información que recibirá a través de este servicio, únicamente te notifica sobre el pago de impuestos, servicios, alguna recomendación o noticia relacionada con tu vehículo.
      </p>
    </div>
  )
};

export default WhySection;
