import { CircularProgress, Grid } from '@mui/material'
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getBlogList } from '../../services/BlogServices';
import { Images } from '../../util';
import BlogArticleSection from './BlogArticleSection'
import './HomeBlogSection.css';

const HomeBlogSection = () => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const history = useHistory();

  useEffect(async () => {
    const { success, data } = await getBlogList(0, 2);
    if (success) {
      setArticles(data.blogs)
      setLoading(false)
    }
  }, []);

  return (
    <div className="home-blog-container">
      <p className="title">Nuestro Blog</p>
      <p className="subtitle">No te pierdas las últimas noticias sobre lo más relevante para tus vehículos en México</p>
      {
        loading ? (
          <div className="view-loading-container">
            <CircularProgress size={60} />
          </div>
        ) : (
          <>
            <Grid container spacing={3}>
              {
                articles.map((article, i) => (
                  <Grid key={`article_${article.id}`} item xs={12} md={6}>
                    <BlogArticleSection  {...article} />
                  </Grid>
                ))
              }
            </Grid>
            <div className="link-container" onClick={() => { history.push('/blog') }} >
              <p className="link-text">
                Ver más noticias
              </p>
              <img className="link-image" src={Images.ARROW_RIGHT} />
            </div>
          </>
        )
      }
    </div>
  )
}

export default HomeBlogSection