import { useState } from "react";
import FinishPaymentSection from "./FinishPaymentSection";

const DiscountPaymentSection = ({ handlerSubmit }) => {
  const [loading, setLoading] = useState(false)
  const processPayment = async () => {
    setLoading(true);
    await handlerSubmit();
    setLoading(false)
  }
  return (
    <FinishPaymentSection
      buttonLabel={'Continuar'}
      enable={true}
      loading={loading}
      processPayment={() => processPayment()}
    />
  )
}

export default DiscountPaymentSection