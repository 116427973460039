import parse from "html-react-parser";
import { useContext } from "react";
import { AppContext } from "../../contexts";
import { Images } from "../../util";
import "./ContentHeaderSection.css";

const ContentHeaderSection = () => {
  const { app } = useContext(AppContext)
  const { title, subtitle, back } = app.titles || {};
  return (
    <div className="content-header">
      <div className="container">
        {
          back && (
            <div className="content-header-arrow">
              <img
                className="content-header-arrow-back"
                onClick={back}
                src={Images.ARROW_BACK}
              ></img>
            </div>
          )
        }

        {
          title && (
            <div className="content-header-title">
              <p>{typeof title == 'string' ? parse(title) : title}</p>
            </div>
          )
        }
        {
          subtitle && (
            <div className="content-header-subtitle">
              <div className="content-header-subtitle">
                <p>{parse(subtitle)}</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ContentHeaderSection
