import { useState, useEffect, useContext } from "react";
import { TextField, Button, CircularProgress, InputAdornment, Grid } from "@mui/material";

import { appTypes, userTypes, vehicleTypes } from "../reducers/types";
import { AppContext } from "../contexts";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./EditAlertPage.css";
import { isMobile } from 'react-device-detect';
import NumberFormat from "react-number-format";
import ConfirmRemoveAdditionalUserDialog from "../component/dialogs/ConfirmRemoveAdditionalUserDialog";
import { useParams } from "react-router";
import { toTitleCase } from "../util/helpers";
import { addAdditionalUserToVehicle, removeAdditionalUserToVehicle } from "../services/VehicleServices";
import { updateUser } from "../services/UserServices";

const EditAlertPage = ({ history }) => {
  const { app: { user: { id, name, lastname, email, phone }, vehicles },
    dispatch
  } = useContext(AppContext);
  const { vehicleId } = useParams();
  const { additional_user: additionalUser } = vehicles.find((vehicle) => vehicle.id == vehicleId);
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name, lastname, email, phone,
      additionalUserName: additionalUser?.name,
      additionalUserEmail: additionalUser?.email,
      additionalUserPhone: additionalUser?.phone
    }
  });
  const [activateSpinner, setActivateSpinner] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const [showAdditionalUser, setShowAdditionalUser] = useState(additionalUser != undefined)
  const [showConfirmRemoveAdditionalUserDialog, setShowConfirmRemoveAdditionalUserDialog] = useState({ open: false })

  const allWatcher = useWatch({ control, name: ['name', 'lastname', 'email', 'phone', 'additionalUserName', 'additionalUserEmail', 'additionalUserPhone'] });

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: '¿Quieres <span>editar tus datos?</span>',
        back: () => history.push('/alertas')
      }
    })
  }, [])

  useEffect(() => {
    const [_name, _lastname, _email, _phone, _additionalUserName, _additionalUserEmail, _additionalUserPhone] = allWatcher;
    const updateUser = _name != name || _lastname != lastname || _email != email || _phone != phone;
    const updateAdditionalUser = _additionalUserName != additionalUser?.name || _additionalUserEmail != additionalUser?.email || _additionalUserPhone != additionalUser?.phone;
    if (showAdditionalUser)
      setActivateButton(updateUser || updateAdditionalUser)
    else
      setActivateButton(updateUser)
  }, [allWatcher])

  const handlerShowConfirmRemoveAdditionalUserDialog = () => {
    if (additionalUser) {
      setShowConfirmRemoveAdditionalUserDialog({ open: true })
    } else {
      setShowAdditionalUser(false);
      setValue('additionalUserName', '');
      setValue('additionalUserEmail', '');
      setValue('additionalUserPhone', '');
    }
  }

  const handlerRemoveAdditionalUser = async () => {
    const { success } = await removeAdditionalUserToVehicle(vehicleId);
    if (success) {
      dispatch({
        type: vehicleTypes.deleteAdditionalUser,
        payload: {
          id: vehicleId
        }
      })
      setShowConfirmRemoveAdditionalUserDialog({ open: false });
      setShowAdditionalUser(false);
    }
  }

  const onSubmit = async (data) => {
    const { name, lastname, email, phone, additionalUserName, additionalUserEmail, additionalUserPhone } = data
    const { success } = await updateUser(id, { name, lastname, email, phone })
    if (success) {
      dispatch({
        type: userTypes.updateUser,
        payload: { name, lastname, email, phone }
      })
      if (showAdditionalUser) {
        const { success, message, data } = await addAdditionalUserToVehicle(vehicleId, {
          name: additionalUserName,
          email: additionalUserEmail,
          phone: additionalUserPhone
        })
        if (success) {
          dispatch({
            type: vehicleTypes.createAdditionalUser,
            payload: {
              id: vehicleId,
              additionalUser: {
                email: additionalUserName,
                email: additionalUserEmail,
                phone: additionalUserPhone
              }
            }
          })
        }
      }
    }
    history.push("/alertas");
  };

  return (
    <>
      <div className="edit-alert-container">
        <form onSubmit={handleSubmit(onSubmit)} >
          <Grid container>
            <Grid container item xs={12} md={6}>
              <div className="edit-alert-form-container">
                <div className="edit-alert-content-input">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Debes llenar el campo de 'Nombre(s)'"
                    }}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                      <TextField
                        label="Nombre(s)"
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={(e) => onChange(toTitleCase(e.target.value))}
                        onBlur={(e) => setValue(name, e.target.value.trim())}
                        error={!!error}
                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                        inputProps={{
                          style: {
                            paddingLeft: 30,
                            fontSize: "16px",
                            color: "#434362",
                            borderColor: "#CA0F4A",
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="edit-alert-content-input">
                  <Controller
                    name="lastname"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Debes llenar el campo de 'Nombre(s)'"
                    }}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                      <TextField
                        label="Apellido(s)"
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={(e) => onChange(toTitleCase(e.target.value))}
                        onBlur={(e) => setValue(name, e.target.value.trim())}
                        error={!!error}
                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                        inputProps={{
                          style: {
                            paddingLeft: 30,
                            fontSize: "16px",
                            color: "#434362",
                            borderColor: "#CA0F4A",
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="edit-alert-content-input">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Debes llenar el campo de 'Correo Electrónico'",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'El correo electrónico no es valido'
                      }
                    }}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                      <TextField
                        label="Correo electrónico"
                        variant="outlined"
                        fullWidth
                        value={value.toLowerCase()}
                        onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                        error={!!error}
                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                        inputProps={{
                          style: {
                            paddingLeft: 30,
                            fontSize: "16px",
                            color: "#434362",
                            borderColor: "#CA0F4A",
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="edit-alert-content-input">
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Debes llenar el campo de 'Número celular'",
                      pattern: {
                        value: /^\d{10}$/,
                        message: 'El número celular no es válido'
                      }
                    }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <NumberFormat
                        customInput={TextField}
                        format="##########"
                        label="Número celular (10 dígitos)"
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        inputProps={{ 
                          inputMode: "tel", 
                          style: {
                            paddingLeft: 30,
                            fontSize: "16px",
                            color: "#434362",
                            borderColor: "#CA0F4A",
                          },
                        }}
                        error={!!error}
                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
            {
              showAdditionalUser ? (
                <Grid container item xs={12} md={6}>
                  <div className="additional-user-form-container">
                    <div className="additional-user-form">
                      <FontAwesomeIcon className="icon" icon="times" onClick={() => handlerShowConfirmRemoveAdditionalUserDialog()} />
                      <p className="title">
                        Datos del usuario adicional
                      </p>
                      <div className="edit-alert-content-input">
                        <Controller
                          name="additionalUserName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Debes llenar el campo de 'Nombre'",
                          }}
                          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <TextField
                              label="Nombre"
                              variant="outlined"
                              fullWidth
                              value={value}
                              onChange={(e) => onChange(toTitleCase(e.target.value))}
                              onBlur={(e) => setValue(name, e.target.value.trim())}
                              error={!!error}
                              helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                              inputProps={{
                                style: {
                                  paddingLeft: 30,
                                  fontSize: "16px",
                                  color: "#434362",
                                  borderColor: "#CA0F4A",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="edit-alert-content-input">
                        <Controller
                          name="additionalUserEmail"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Debes llenar el campo de 'Correo Electrónico'",
                            pattern: {
                              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: 'El correo electrónico no es valido'
                            }
                          }}
                          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <TextField
                              label="Correo electrónico"
                              variant="outlined"
                              fullWidth
                              value={value.toLowerCase()}
                              onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                              error={!!error}
                              helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                              inputProps={{
                                style: {
                                  paddingLeft: 30,
                                  fontSize: "16px",
                                  color: "#434362",
                                  borderColor: "#CA0F4A",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="edit-alert-content-input">
                        <Controller
                          name="additionalUserPhone"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Debes llenar el campo de 'Número celular'",
                            pattern: {
                              value: /^\d{10}$/,
                              message: 'El número celular no es válido'
                            }
                          }}
                          render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <NumberFormat
                              customInput={TextField}
                              format="##########"
                              label="Número celular (10 dígitos)"
                              variant="outlined"
                              fullWidth
                              value={value}
                              onChange={onChange}
                              inputProps={{
                                inputMode: "tel",
                                style: {
                                  paddingLeft: 30,
                                  fontSize: "16px",
                                  color: "#434362",
                                  borderColor: "#CA0F4A",
                                },
                              }}
                              error={!!error}
                              helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <p className="text">
                      El usuario adicional no tiene acceso a Mis Alertas y sólo podrá recibirá las notificaciones
                    </p>
                  </div>
                </Grid>
              ) : (
                <Grid container item xs={12} md={6}>
                  <div className="additional-user-container">
                    <div>
                      <p className="title">
                        ¿Le quieres agregar un usuario adicional a esta Alerta?
                      </p>
                      <p className="text">
                        Tu seguirás siendo el usuario principal, pero no recibirás las notificaciones de este vehículo.
                      </p>
                      <p className="text">
                        El usuario adicional no tiene acceso a Mis Alertas y sólo podrá recibirá las notificaciones
                      </p>
                      <Button className="alerta-white-button small short" onClick={() => setShowAdditionalUser(!showAdditionalUser)}>
                        <span className="alerta-white-button-text">Agregar usuario</span>
                      </Button>
                    </div>
                  </div>
                </Grid>
              )
            }
          </Grid>
          <div className="edit-alert-button-container">
            <Button
              disabled={!activateButton}
              className="alerta-purple-button"
              type="submit"
            >
              <span className="alerta-purple-button-text">Guardar</span>
              {
                activateSpinner && (
                  <span className="alerta-purple-button-progress">
                    <CircularProgress
                      size={30}
                      color='inherit'
                    />
                  </span>
                )
              }
            </Button>
          </div>
        </form>
      </div>
      <ConfirmRemoveAdditionalUserDialog
        state={showConfirmRemoveAdditionalUserDialog}
        onSuccess={handlerRemoveAdditionalUser}
        onClose={() => setShowConfirmRemoveAdditionalUserDialog({ open: false })}
      />
    </>
  );
};

export default EditAlertPage;
