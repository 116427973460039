import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";

import "./PrivacyNoticePage.css";

const PrivacyNoticePage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.body.privacy_notice);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title,
        back: () => history.goBack()
      }
    })
  }, [])
  return (
    <div className="privacy-notice-page">
      <div className="container">
        <div className="terms_conditions_text_block">
          {
            Array.isArray(texts.intro) ?
              texts.intro.map((item) => (
                <>
                  <p dangerouslySetInnerHTML={{ __html: item }}></p>
                  <br />
                </>
              )) : <p dangerouslySetInnerHTML={{ __html: texts.intro }}></p>
          }
        </div>
        {texts.clauses.map((clause) => (
          <div className="terms_conditions_text_block">
            <h4>{clause.title}</h4>
            {
              Array.isArray(clause.content) ?
                clause.content.map((item) => (
                  <>
                    <p dangerouslySetInnerHTML={{ __html: item }}></p>
                    <br />
                  </>
                )) : <p dangerouslySetInnerHTML={{ __html: clause.content }}></p>
            }
          </div>
        ))}
      </div>

    </div>
  );
};
export default PrivacyNoticePage;
