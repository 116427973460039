import { Grid } from '@mui/material';
import { useContext, useState } from 'react'
import { AppContext } from '../../../contexts';
import { Images } from '../../../util';
import { Steps_Payment_Oxxo_Molecules } from '../../molecules/Steps_Payment_Oxxo_Molecules';
import FinishPaymentSection from './FinishPaymentSection';
import './OxxoPaymentSection.css';

const steps = [
  {
    number: "1",
    message_desktop: "Descarga el PDF con la Orden de Pago",
    message_mobile:
      "El Folio Real está al final de la  Escritura (sello) o en la Constancia  de Finalización de Trámite",
  },
  {
    number: "2",
    message_desktop: "Acude a cualquier tienda OXXO",
    message_mobile:
      "Registra tu correo y celular para  notificarte en caso de recibir una  Alerta de tu propiedad",
  },
  {
    number: "3",
    message_desktop: "Muéstrale al cajero el código de barras ",
    message_mobile:
      "La suscripción es anual y pagas con  tarjeta de crédito, débito, PayPal o  en efectivo (OXXO Pay).",
  },
  {
    number: "4",
    message_desktop: "Realiza el pago en efectivo.",
    message_mobile:
      "La suscripción es anual y pagas con  tarjeta de crédito, débito, PayPal o  en efectivo (OXXO Pay).",
  },
];

const OxxoPaymentSection = ({ handlerSubmit }) => {
  const [loading, setLoading] = useState(false)
  const onSubmit = async () => {
    setLoading(true);
    const success = await handlerSubmit();
    if (!success) {
      console.log(message);
    }
    setLoading(false);
  }

  return (
    <>
      <div className="payment-section">
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <p className='payment-section-title'>Pago en efectivo (Tiendas OXXO)</p>
            </Grid>
            <Grid>
              <img className='payment-section-title-img' src={Images.PAYMENT_OXXO} alt="oxxo" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div>
              <p className="payment-section-oxxo-message">
                Debes de seguir los siguientes pasos <br /> para realizar tu
                pago:
              </p>
            </div>
            <div className="payment-section-oxxo-steps">
              {
                steps.map((step) => <Steps_Payment_Oxxo_Molecules step={step} device="desktop" />)
              }
            </div>
          </Grid>
        </Grid>
      </div>
      <FinishPaymentSection
        buttonLabel={'Descargar PDF'}
        enable={true}
        loading={loading}
        processPayment={onSubmit}
      />
    </>

  )
}

export default OxxoPaymentSection
