import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { pageReducer } from './reducers/pageReducer';


import logger from 'redux-logger'

const rootReducer = combineReducers({  
  page: pageReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware), applyMiddleware(logger))
);
