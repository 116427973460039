import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './RegisteredPlateDialog.css';

const RegisteredPlateDialog = ({ state: { open, data = {} }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="registered-plate-dialog"
    >
      <div className="registered-plate-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.RED_PLATE} alt="" />
          </Grid>
          <Grid item >
            <p className="registered-plate-dialog-title">
              {
                data.plate ? 
                  'Esta placa ya está registrada' : 
                  'Este NIV ya está registrado'
              }
            </p>
          </Grid>
          <Grid item >
            <p className="registered-plate-dialog-subtitle">
            {
                data.plate ? 
                  'Verifica el número de tu placa y si hay algún error, comunícate con nosotros y con gusto te atenderemos.' : 
                  'Verifica el NIV y si hay algún error, comunícate con nosotros y con gusto te atenderemos.'
              }
              
            </p>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button className="alerta-purple-button" onClick={onClose}>
              <span className="alerta-purple-button-text">Regresar</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default RegisteredPlateDialog
