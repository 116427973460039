import { Button, Grid } from "@mui/material";
import "./AttentionSection.css";

const AttentionSection = () => {
  return (
    <div className="home-attention-section">
      <p className="title">Con nuestro “Centro de Atención Digital”, pregúntanos lo que quieras </p>
      <p className="subtitle">Nos pondremos en contacto contigo lo más pronto posible</p>
      <Grid container item spacing={2} justifyContent="center">
        <Grid container item xs={12} md={4} justifyContent="center">
          <Button type="submit" className="alerta-purple-button" onClick={() => window.location.href = "mailto:hola@alertamiauto.com"}>
            <span className="alerta-purple-button-text">Contáctanos</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default AttentionSection;
