import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";

const DeleteVehicleDialog = ({ state: { open, data = {} }, onSuccess, onClose }) => {
  const { id, name, estate, complete } = data;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="register-car-close-dialog-container"
    // style={{ width: "450px", margin: "0 auto" }}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12} container justifyContent="flex-end">
          <img
            onClick={onClose}
            src={Images.DIALOG_CLOSE_ICON}
            style={{
              marginRight: "15px",
              marginTop: "15px",
              cursor: "pointer",
            }}
          ></img>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          className="register-car-close-dialog-title"
        >
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "15px",
              fontWeight: "bold",
              lineHeight: "20px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            ¿Estás seguro que deseas eliminar el <br />
            siguiente vehículo?
          </p>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignContent="center" style={{margin: '20px 0px'}}>
            <img
              src={Images.DECLINED_ICON}
              style={{
                marginRight: "5px",
              }}
            ></img>
            <span
              style={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "16px",
                color: "#707C89",
              }}
            >
              {name}: {estate}
            </span>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Button className="alerta-purple-button" onClick={() => { onSuccess(id, complete) }}>
            <span className="alerta-purple-button-text">Confirmar</span>
          </Button>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button className="alerta-white-button" onClick={onClose} style={{marginBottom: '20px'}}>
            <span className="alerta-white-button-text">Cancelar</span>
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteVehicleDialog
