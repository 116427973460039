import axios from "axios";
import { URL_API } from "../util/Configuration";
import { authHeader, handlerError, handlerResponse, service } from "./helpers/serviceConfig"

export const validateToken = async (req) => {
  return axios.get(`${URL_API}/token/validate`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const createUser = async (req) => {
  return axios.post(`${URL_API}/user`, req)
    .then(handlerResponse)
    .catch(handlerError);
}

export const updateUser = (id, req) => {
  return axios.patch(`${URL_API}/user/${id}`, req, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const sendVerificationEmail = (id) => {
  return axios.get(`${URL_API}/user/${id}/sendcode`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const validateEmailCode = ({ id, code }) => {
  return axios.get(`${URL_API}/user/${id}/validatecode/${code}`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const login = (email) => {
  return axios.post(`${URL_API}/user/login`, { email })
    .then(handlerResponse)
    .catch(handlerError);
}

export const validateLoginCode = ({email, token, code}) => {
  return axios.get(`${URL_API}/login/${token}/validate/${code}`, { email })
    .then(handlerResponse)
    .catch(handlerError);
}

export const getUser = (id) => {
  return axios.get(`${URL_API}/user/${id}/complete`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const getUserNotificationsConfig = (id) => {
  return axios.get(`${URL_API}/user/${id}/alerts`, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}

export const updateUserNotificationsConfig = (id, body) => {
  return axios.post(`${URL_API}/user/${id}/alerts`, body, authHeader())
    .then(handlerResponse)
    .catch(handlerError);
}


export const setUserOnHold = (body) => {
  return service('post', `${URL_API}/useronhold`, { auth: true, body })
}
