import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts";
import { appTypes, blogTypes } from "../reducers/types";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterSection from "../component/sections/FooterSection";
import "./BlogPage.css";
import { getBlogList } from "../services/BlogServices";
import { blogCategories } from "../data/blogCategories";
import BlogArticleSection from "../component/sections/BlogArticleSection";

const BlogPage = ({ history }) => {
  const { app: { blog }, dispatch } = useContext(AppContext);
  const { handleSubmit, control, setError } = useForm();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Nuestro Blog de <span>noticias, consejos y tips</span> para tu auto / motocicleta",
        back: () => history.goBack()
      }
    })
  }, [])

  useEffect(async () => {
    const { success, data } = await getBlogList();
    if (success) {
      dispatch({
        type: blogTypes.setBlogList,
        payload: data.blogs
      });
      setLoading(false)
    }
  }, []);

  return (
    <div className="blog-page">
      <div className="container" style={{ minHeight: '40vh'}}>
        {/* <div className="blog-input-container">
          <Controller
            name="search"
            control={control}
            render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
              <TextField
                label={''}
                fullWidth
                variant="outlined"
                value={value}
                onChange={(e) => onChange(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                error={!!error}
                helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <button type="button" className='register-car-detail-search-plates-button'
                      onClick={() => handlerSearch()}
                    >
                      Buscar
                      {loadingSearch && <CircularProgress className="register-car-detail-search-plates-button-loading" size={20} color='inherit' />}
                    </button>
                  </InputAdornment>,
                }}
              />
            )}
          />
        </div> */}
        <div className="blog-articles-container">
          {
            loading ? (
              <div className="view-loading-container">
                <CircularProgress size={60} />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={9} >
                  {
                    blog.map((article, i) => i == 0 && (<BlogArticleSection {...article} />))
                  }
                </Grid>
                <Grid container item xs={12} md={3} flexDirection='column' justifyContent='space-between' order={{ xs: -1, sm: 'initial' }}>
                  {
                    blogCategories.map(({ code, name }) => (
                      <Grid item key={`category_${code}`}>
                        <div className="blog-category" onClick={() => history.push('/blog/categoria/' + code )}>
                          {name}
                        </div>
                      </Grid>
                    ))
                  }
                </Grid>
                {
                  blog.map((article, i) => i != 0 && (
                    <Grid key={`article_${article.id}`} item xs={12} md={6}>
                      <BlogArticleSection {...article} />
                    </Grid>
                  ))
                }
              </Grid>
            )
          }
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default BlogPage;
