import './ServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import useHeight from '../../hooks/useHeight';
import CirculationCardSection from './circulationCard/CirculationCardSection';
import moment from 'moment';
import { toEstateTitleCase } from '../../util/helpers';

const CirculationCardServiceMainSection = ({ vehicle }) => {
  const [ref, height] = useHeight();
  let { estatePlate, estate, services: { tarjeta_circulacion } } = vehicle;
  const foreigner = toEstateTitleCase(estatePlate) != estate;
  const expiration = moment(tarjeta_circulacion.circulation_card_expiration, 'YYYY-MM-DD');
  const expire = tarjeta_circulacion.expire;
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Tarjeta de circulación</span>
      </div>
      {
        foreigner ? (
          <>
            <div className="alert-content-section" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <p className="alert-title">¡Tu placa no está registrada en el sistema de la Ciudad de México!</p>
            </div>
            <div className="content-section">
              <span className="main-label verification-label" style={{ fontSize: '10px', padding: '0px 20px' }}>
                En cuanto tengamos a tu Estado disponible en nuestro sistema, actualizaremos la información y te notificamos para que estés enterado.
              </span>
            </div>
          </>
        ) : (
          <div className="service-overflow" style={{ height: `${height}px` }}>
            <div className="service-main-content">
              <CirculationCardSection
                expiration={expiration}
                expire={expire}
              />
            </div>
            <div className="service-main-footer">
              <MarkdownPreview source={tarjeta_circulacion.template_description} />
            </div>
          </div>

        )
      }
    </div>
  )
}

export default CirculationCardServiceMainSection
