import { useContext, useEffect } from "react";
import FooterSection from "../../component/sections/FooterSection";
import BasicSingleReportSection from "../../component/sections/singleReport/BasicSingleReportSection";
import PremiumSingleReportSection from "../../component/sections/singleReport/PremiumSingleReportSection";
import { AppContext } from "../../contexts";
import alertaServices from "../../data/alertaServices";
import { appTypes } from "../../reducers/types";
import { Images } from "../../util";
import "./ExamplePremiumSingleReportPage.css";

const example = {
  vehicle: {
    plate: '123ABC',
    type: 0,
    brand: 'HONDA',
    model: 'Pilot XLE',
    year: '2017',
    estate: 'Ciudad de México'
  },
  services: [
    {
      name: 'Pago de Tenencia',
      value: true,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              title: 'Adeudo 2019',
              section: <div></div>
            },
            {
              title: 'Adeudo 2020',
              section: <div></div>
            }
          ]
        }
      ]
    },
    {
      name: 'Infracciones',
      value: false,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              title: 'Folio: 04194949841',
              section: <div></div>
            },
            {
              section: <div></div>
            }
          ]
        }
      ]
    },
    {
      name: 'FotoCívicas',
      value: true,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              section: <div></div>
            },
          ]
        }
      ]
    },
    {
      name: 'Sanción ambiental',
      value: true,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              title: 'Folio: 699980',
              section: <div></div>
            },
          ]
        }
      ]
    },
    {
      name: 'Tarjeta de circulación',
      value: true,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              section: <div></div>
            },
          ]
        }
      ]
    },
    {
      name: 'Renovación de placas',
      value: true,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              section: <div></div>
            },
          ]
        }
      ]
    },
    {
      name: 'Reporte de robo',
      value: null,
      data: [
        {
          title: 'Ciudad de México',
          sections: [
            {
              title: 'Procuraduría General de Justicia (PGJ)',
              section: <div></div>
            },
            {
              title: 'Oficina Coordinadora de Riesgos Asegurados (OCRA)',
              section: <div></div>
            },
            {
              title: 'Registro de Automotores de Procedencia Ilícita (RAPI)',
              section: <div></div>
            },
          ]
        }
      ]
    },
  ]
}

const ExamplePremiumSingleReportPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  const { vehicle, services: example_services } = example;
  const services = alertaServices.map((service) => {
    const example_service = example_services.find(({ name }) => name == service.name);
    return { ...service, ...example_service };
  });

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        listMenu: true,
        burger: false,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Ejemplo de Informe vehicular <span>Premium</span>",
        back: () => history.goBack()
      }
    })
  }, [])

  return (
    <div className="basic-single-report-page">
      <div className="container">
        <BasicSingleReportSection
          vehicle={vehicle}
          services={services}
        >
          <PremiumSingleReportSection services={services.filter(({ value }) => value != null)} />
        </BasicSingleReportSection>
        <p style={{ marginTop: "60px", textAlign: "center" }}>
          <span
            style={{
              fontFamily: "Roboto",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#383881",
              marginRight: "8px",
            }}
            onClick={() => history.push("/contratar_alerta")}
          >
            Contratar una Alerta
          </span>
          <img src={Images.ARROW_RIGHT}></img>
        </p>
        <p
          style={{
            marginTop: "20px",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "17px",
            color: "#707C89",
            marginBottom: "50px",
          }}
        >
          * Las Alertas se renuevan automáticamente cada año y se pueden <br />
          cancelar en cualquier momento
        </p>
      </div>
      <FooterSection />
    </div>
  );
};

export default ExamplePremiumSingleReportPage;
