import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterSection from "../component/sections/FooterSection";
import "./BlogCategoryPage.css";
import { useParams } from "react-router-dom";
import BlogArticleSection from "../component/sections/BlogArticleSection";
import { blogCategories } from "../data/blogCategories";

const BlogCategoryPage = ({ history }) => {
  const {
    app: { blog },
    dispatch,
  } = useContext(AppContext);
  const { category } = useParams();
  const { name } = blogCategories.find(({ code }) => code == category);
  const list = blog.filter((article) => article.category == category);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
      },
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: `Categoría: <span>${name}</span>`,
        back: () => history.push("/blog"),
      },
    });
  }, []);

  return (
    <div className="blog-page">
      <div className="container" style={{ minHeight: '40vh'}}>
        <div className="blog-articles-container">
          <Grid container spacing={3}>
            {list.map((article, i) => (
              <Grid key={`article_${article.id}`} item xs={12} md={6}>
                <BlogArticleSection {...article} />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default BlogCategoryPage;
