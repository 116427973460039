
const FooterEnvironmentalSanctionSection = () => {
  return (
    <div className="service-main-footer">
      <p className="footer-content">
        Si usted desea corregir o aclarar un pago ya realizado, puede comunicarse
        a la Administración Tributaria del Estado más cercana a su domicilio.
      </p>
      <p className="footer-content">
        La presente información es mostrada sin perjuicio de las facultades de
        comprobación de las autoridades fiscales.
      </p>
    </div>
  )
}

export default FooterEnvironmentalSanctionSection