import './ServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import useHeight from '../../hooks/useHeight';
import PlateRenewSection from './plateRenew/PlateRenewSection';
import moment from 'moment';

const PlateRenewServiceMainSection = ({ vehicle }) => {
  const [ref, height] = useHeight();
  let { services: { renovacion_placa } } = vehicle;
  const loading = Object.entries(renovacion_placa).length == 0;
  const expiration = !loading && moment(renovacion_placa.fechaFinVigenciaFormat, 'DD-MM-YYYY');
  const expire = !loading && expiration.isBefore(moment());
  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Renovación de placa</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        <div className="service-main-content">
          <PlateRenewSection
            expiration={expiration}
            expire={expire}
          />
        </div>
        <div className="service-main-footer">
          <MarkdownPreview source={renovacion_placa.template_description} />
        </div>
      </div>
    </div>
  )
}

export default PlateRenewServiceMainSection
