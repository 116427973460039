import HTMLReactParser from "html-react-parser";

const pointsCases = {
  10: {
    color: '#18833F',
    label: '¡Felicidades!',
    text: 'Conservas todos tus puntos',
    sanctions: false
  },
  9: {
    color: '#FD982A',
    label: 'Perdiste un punto',
    text: 'No cometas más infracciones',
    sanctions: false
  },
  8: {
    color: '#F7631B',
    label: 'Perdiste dos puntos',
    text: 'No cometas más infracciones',
    sanctions: false
  },
  7: {
    color: '#DD1F28',
    label: 'Perdiste tres puntos',
    text: 'Debes realizar el curso básico en línea',
    sanctions: true
  },
  6: {
    color: '#DD1F28',
    label: 'Perdiste cuatro puntos',
    text: 'Sanciones anteriores +<br>Debes realizar el curso intermedio en línea',
    sanctions: true
  },
  5: {
    color: '#DD1F28',
    label: 'Perdiste cinco puntos',
    text: 'Sanciones anteriores +<br>Debes realizar el curso de sensibilización',
    sanctions: true
  },
  4: {
    color: '#DD1F28',
    label: 'Perdiste seis puntos',
    text: 'Sanciones anteriores +<br>Debes realizar dos horas de trabajo comunitario',
    sanctions: true
  },
  3: {
    color: '#DD1F28',
    label: 'Perdiste siete puntos',
    text: 'Sanciones anteriores +<br>Debes realizar dos horas de trabajo comunitario',
    sanctions: true
  },
  2: {
    color: '#DD1F28',
    label: 'Perdiste ocho puntos',
    text: 'Sanciones anteriores +<br>Debes realizar dos horas de trabajo comunitario',
    sanctions: true
  },
  1: {
    color: '#DD1F28',
    label: 'Perdiste nueve puntos',
    text: 'Sanciones anteriores +<br>Debes realizar dos horas de trabajo comunitario',
    sanctions: true
  },
  0: {
    color: '#DD1F28',
    label: 'Perdiste +diez puntos',
    text: 'Sanciones anteriores +<br>Debes realizar dos horas de trabajo comunitario<br>por cada punto que pierdas',
    sanctions: true
  },
}

const FotocivicasServiceSection = ({ puntos, infracciones, premium }) => {
  const { color, label, text } = pointsCases[puntos];
  return (
    <>
      <div className={`fotocivicas-section ${premium ? 'premium' : ''}`}>
        {
          !premium && (
            <div className="content-section">
              <span className="main-label">
                Por cada una de las dos primeras infracciones que cometas recibirás una amonestación y perderás un punto.
                A partir de la tercera infracción perderás el número de puntos indicado en el Reglamento de Tránsito CDMX 2021
                y deberás cumplir con las sanciones acumuladas. Ver más
              </span>
            </div>
          )
        }
        <div className="current-points">
          <div className="title">
            {
              !premium && <p className="label">Tu puntaje actual:</p>
            }
            <div className="points"
              style={{ backgroundColor: color }}
            >
              <span>{puntos}</span>
            </div>
          </div>
          <div className="label" style={{ color: color }}>{label}</div>
          <div className="text">{HTMLReactParser(text)}</div>
          <div className="status">{infracciones != 0 ? '¡Para poder verificar debes de cumplir las sanciones!' : '¡Si puedes verificar!'}</div>
          {
            infracciones != 0 && (
              <div className="sanctions" >
                <Button className="alerta-purple-button"
                  onClick={() => window.open("https://tramites.cdmx.gob.mx/fotocivicas/public/", "_blank")}
                >
                  <span className="alerta-purple-button-text">Ver sanciones</span>
                </Button>
              </div>
            )
          }
        </div>
        <p className="points-footer">
          *  Las sanciones cívicas pueden aparecer hasta 3 a 4 semanas después de haberse impuesto.
        </p>
      </div>
      {/* {
        infracciones.map(({ folio, sancion, fecha, tipo, fundamentos, motivos }) => (
          <div className="service-main-content">
            <p className="content-title">Folio: {folio}</p>
            <div className="content-section">
              <span className="main-label">Sanción</span>
              <span className="main-variable">{sancion}</span>
            </div>
            <div className="content-section">
              <span className="main-label">Tipo</span>
              <span className="main-variable">{tipo}</span>
            </div>
            <div className="content-section">
              <span className="main-label">Fecha</span>
              <span className="main-variable">{fecha}</span>
            </div>
            <div className="content-section">
              <span className="main-label">Fundamento</span>
              <span className="main-variable">{fundamentos}</span>
            </div>
            <div className="penality-article">
              <div className="content-section">
                <span className="main-label">
                  <span>Motivo:</span><br />
                  <span className="main-sublabel">
                    {motivos}
                  </span>
                </span>
              </div>
            </div>
          </div >
        ))
      } */}
    </>
  )
}

export default FotocivicasServiceSection