import { Switch, Route, Router } from "react-router-dom";
import { useEffect, useReducer, useRef } from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Moment from 'react-moment';
import 'moment/locale/es-mx';


import "./index.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/react-modal-video/css/modal-video.min.css";

import HomePage from "../page/HomePage";
import Routes from "../router/Routes";
import HeaderSection from "../component/sections/HeaderSection";
import appReducer from "../reducers/appReducer";
import { AppContext } from "../contexts";
import { createBrowserHistory } from "history";
import { Helmet } from "react-helmet";

const history = createBrowserHistory();
library.add(fas, far);
Moment.globalLocale = 'fr';
export default () => {
  const [app, dispatch] = useReducer(appReducer, {
    home: {
      header: useRef(null),
      how: useRef(null),
      why: useRef(null),
      opinion: useRef(null),
      doubts: useRef(null),
      footer: useRef(null),
    },
    header: {},
    titles: {}
  }, (state) => {
    let appStorage = JSON.parse(localStorage.getItem('appStorage')) || {};
    return { ...state, ...appStorage };
  });

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
    script.async = true
    document.body.appendChild(script)
  }, []);

  useEffect(() => {
    const { user, vehicles, payment, notifications, estates, estateList, blog, singleReport } = app;
    localStorage.setItem('appStorage', JSON.stringify({
      user,
      estateList,
      estates,
      vehicles,
      payment,
      notifications,
      blog,
      singleReport
    }));
  }, [app])
  document.body.classList.add("ready");
  return (
    <AppContext.Provider value={{ app, dispatch }}>
      <Helmet>
        <title>Alertamiauto</title>
        <meta name="description" content="Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com" />
        <meta property="og:image" content="https://www.alertamiauto.com/assets/images/logo/share-icon.png" />
        <meta property="og:image:alt" content="Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com" />
        <meta property="og:site_name" content="Alertamiauto" />
        <meta property="og:type" content="object" />
        <meta property="og:title" content="Alertamiauto" />
        <meta property="og:url" content="https://www.alertamiauto.com/" />
        <meta property="og:description" content="Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com" />
        <meta name="twitter:image:src" content="https://www.alertamiauto.com/assets/images/logo/share-icon.png" />
        <meta name="twitter:site" content="@alertamiauto" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Alertamiauto" />
        <meta name="twitter:description" content="Olvídate de las multas o recargos y registra tus vehículos en www.alertamiauto.com" />
      </Helmet>
      <div className="App">
        <Router history={history}>
          <div>
            <HeaderSection />
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/" component={Routes} />
            </Switch>
          </div>
        </Router>
      </div>
    </AppContext.Provider>
  );
};
