import parse from "html-react-parser";
import { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import { AppContext } from "../../contexts";
import { Card_Step_Molecules } from "../molecules/Card_Step_Molecules";
import HomePrincipalServicesSection from "./HomePrincipalServicesSection";
import "./HowSection.css";

const HowSection = () => {
  const { app: { home: { how } } } = useContext(AppContext);
  const text = useSelector((store) => store.page.body.how);
  const payments = useSelector((store) => store.page.payments);

  const renderCircleCardStep = (steps) => {
    return steps.map((step, index) => (
      <Card_Step_Molecules
        key={`circleCardStepHowSection${index}`}
        step={step}
        mode="circle"
        payments={payments}
      />
    ));
  };
  const renderBoxCardStep = (steps) => {
    return steps.map((step, index) => (
      <Card_Step_Molecules
        key={`boxCardStepHowSection${index}`}
        step={step}
        mode="box"
        payments={payments}
      />
    ));
  };
  return (
    <div ref={how} className="how-section" >
      <article className="principal-how-organism">
        <div className="wrap-general wrap-how-organism">
          <div className="content-title">
            <p className="title">¿Cómo funciona Alertamiauto.com?</p>
          </div>
          <div className="content-subtitle">
            <p className="subtitle">
              ¡Muy fácil!<br />
              Registra las placas de tu auto o motocicleta y evita multas o recargos.<br />
              El registro es 100% digital y te tardas 5 minutos.
            </p>
          </div>
          <div className="wrap-steps how-section-steps">
            <div className="content-steps">
              {renderCircleCardStep(text.circle_steps)}
            </div>
          </div>
          <HomePrincipalServicesSection />
          {/* <div className="wrap-step-title">
            <Fade direction="up" triggerOnce>
              <p className="text big">
                ¿Qué servicios obtengo si contrato una Alerta?
              </p>
            </Fade>
          </div>
          <div className="wrap-steps">
            <div className="content-steps services">
              {renderBoxCardStep(text.box_steps1)}
            </div>
          </div>
          <div className="wrap-steps">
            <div className="content-steps services">
              {renderBoxCardStep(text.box_steps2)}
            </div>
          </div>
          <div className="container-note">
            <p className="note">{parse(text.note)}</p>
          </div> */}
        </div>
      </article>
    </div>
  );
};

export default HowSection;