import { useState, useEffect, useContext } from "react";
import { Card, CardContent, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { Images } from "../util";
import "./RegisterCarPage.css";
import { AppContext } from "../contexts";
import { vehicleTypes, appTypes } from "../reducers/types";
import PendingVehiclesDialog from "../component/dialogs/PendingVehiclesDialog";
import DeleteVehicleDialog from "../component/dialogs/DeleteVehicleDialog";
import { deleteVehicle } from "../services/VehicleServices";
import BeforeExitMessage from "../helpers/BeforeExitMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterCarPage = ({ history }) => {
  const { app: { user, vehicles }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.register_car);
  const [activeOpt, setActivateOpt] = useState(false);
  const [pendingVehicles, setPendingVehicles] = useState({ open: false });
  const [showDeleteVehicleDialog, setShowDeleteVehicleDialog] = useState({ open: false });

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        subtitle: texts.subtitle_desktop,
        back: () => history.push('/find_estate')
      }
    })
  }, [])

  useEffect(() => {
    if (vehicles.filter((vehicle) => vehicle.complete).length > 0)
      setActivateOpt(true)
    if (vehicles.length == 0)
      history.push('/find_estate')
  }, [vehicles])

  const handlerShowDeleteVehicle = (vehicle) => {
    setShowDeleteVehicleDialog({
      open: true,
      data: vehicle
    });
  };

  const handlerDeleteVehicle = async (id, complete) => {
    const { success, message } = complete ? await deleteVehicle(id) : { success: true };
    if (success) {
      dispatch({
        type: vehicleTypes.deleteVehicle,
        payload: id
      })
      setShowDeleteVehicleDialog({ open: false });
    } else {
      console.error(message);
    }
  }

  const handlerShowPendingVehicles = (vehicles) => {
    setPendingVehicles({
      open: true,
      data: vehicles
    });
  };

  const handlerPendingVehicles = (vehicles) => {
    vehicles.forEach((vehicle) => {
      dispatch({
        type: vehicleTypes.deleteVehicle,
        payload: vehicle.id
      })
    })
    history.push("/register_data_contact");
  }

  const sendRegisterCars = () => {
    const pendingVehicles = vehicles.filter(({ complete }) => !complete)
    if (pendingVehicles.length != 0)
      handlerShowPendingVehicles(pendingVehicles);
    else {
      history.push(user.registered ? "/payment" : "/register_data_contact");
    }
  };

  return (
    <>
      <div className="register-car-container">
        <div className="register-car-section-cards">
          <Grid className="register-car-card-container" container spacing={2} alignItems="center" justifyContent="center" >
            {vehicles.map(({ id, name, complete, type, estate, estatePlate, plate }, index) => {
              return (
                <Grid key={index} item xs={6} md={3}>
                  <Card className="register-car-card">
                    <CardContent className={`register-car-card-content-${complete ? 'active' : 'inactive'}`}
                      onClick={() => {
                        history.push(`/register_car_detail/${id}`);
                      }}
                    >
                      <div className="register-car-card-title-images">
                        <img className="card-img-car"
                          src={type == 0 ?
                            (complete ? Images.ACTIVE_CAR : Images.INACTIVE_CAR) :
                            (complete ? Images.ACTIVE_MOTO : Images.INACTIVE_MOTO)
                          }
                        />
                        <img className="card-img-icon"
                          src={Images.DECLINED_ICON}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlerShowDeleteVehicle({ id, name, estate, complete });
                          }}
                        />
                      </div>
                      <p className="register-car-card-title">
                        {estate}
                      </p>
                      <p className="register-car-card-text">
                        {
                          complete ?
                            (estate.toUpperCase() != estatePlate.toUpperCase() ? 'Foraneo - ' : '') + plate
                            : name
                        }
                      </p>
                    </CardContent>
                  </Card>
                  {
                    complete ? (
                      <div style={{ height: "24px", background: "#f9f9f9" }} className="register-label">
                        <img src={Images.ACCOUNT_DONE_BLUE} />
                        <span>Registrado</span>
                      </div>
                    ) : (
                      <div style={{ height: "24px", background: "#f9f9f9" }} className="register-label" > </div>
                    )
                  }
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      {
        activeOpt && (
          <div className="register-car-content-button">
            <Button className="alerta-purple-button" onClick={sendRegisterCars} >
              <span className="alerta-purple-button-text">Siguiente</span>
            </Button>
          </div>
        )
      }
      <PendingVehiclesDialog
        state={pendingVehicles}
        onSuccess={handlerPendingVehicles}
        onClose={() => setPendingVehicles({ open: false })}
      />
      <DeleteVehicleDialog
        state={showDeleteVehicleDialog}
        onSuccess={handlerDeleteVehicle}
        onClose={() => setShowDeleteVehicleDialog({ open: false })}
      />
      <BeforeExitMessage />
    </>
  );
};

export default RegisterCarPage;
