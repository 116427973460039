import { Grid } from '@mui/material'
import { useHistory } from 'react-router-dom';
import { Images } from '../../../util';
import './SingleReportPremiumSection.css'

const SingleReportPremiumSection = ({ payment }) => {
  const history = useHistory();
  return (
    <div className='premium-section'>
      <div className='premium-container'>
        <Grid container>
          <Grid container item xs={4} sm={2}>
            <img className='premium-img' src={Images.ALERTA_PREMIUM} alt="Logo Premium" />
          </Grid>
          <Grid item xs={8} sm={6}>
            <p className='premium-title'>
              <span>Informe Vehicular Premium</span>
              <img src={Images.PREMIUM_CROW} alt="Corona" />
            </p>
            <p className='premium-subtitle'>
              ¿Te gustaría recibir un informe más completo?<br />
              El informe premium viene con un detalle más completo de cada uno de los servicios.
              <br className='mobile' /><span onClick={() => history.push('/ejemplo_informe_premium')}>Ver ejemplo</span>
            </p>
          </Grid>
          <Grid item xs={4} className='mobile'></Grid>
          <Grid item xs={8} sm={4}>
            <div className='premium-price-container'>
              <p className='premium-price'>$99 pesos / IVA incluido</p>
              <button className='premium-button' onClick={() => history.push(payment ? '/pago_informe_premium' : '/informe_premium')}>Contratar</button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SingleReportPremiumSection