import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import useHeight from '../../hooks/useHeight';
import { toEstateTitleCase } from '../../util/helpers';
import ServiceErrorSection from './ServiceErrorSection';
import './ServiceMainSection.css';
import TenueCDMXSection from './tenue/TenueCDMXSection';
import TenueEDOMEXSection from './tenue/TenueEDOMEXSection';

const TenureServiceMainSection = ({ vehicle, selected, setServiceControl }) => {
  let { estatePlate, estate, services: { tenencia_refrendo } } = vehicle;
  const foreigner = toEstateTitleCase(estatePlate) != estate;
  const loading = Object.entries(tenencia_refrendo).length == 0;
  const tenencia = loading ? [] : tenencia_refrendo;
  const reference = loading ? {} : Object.values(tenencia[0])[0];
  const noDebts = loading || foreigner ? false : Object.keys(tenencia[0])[0] == 0;
  const error = loading || noDebts ? false : reference.ejercicio != 0 && reference.tenencia == undefined;
  const [current, setCurrent] = useState(setServiceControl ? tenencia[0] : selected)
  const [year, setYear] = useState(null)
  const [tenureData, setTenureData] = useState({})
  const [ref, height] = useHeight();

  useEffect(() => {
    if (setServiceControl)
      setServiceControl(
        <Grid container spacing={1}>
          {
            tenencia.map((item) => {
              const year_name = Object.keys(item)[0];
              return (
                <Grid container item xs={2}>
                  <span className={`tenure-year ${year == year_name ? 'selected' : ''}`}
                    onClick={(e) => setCurrent(item)}
                  >
                    {year_name}
                  </span>
                </Grid>
              )
            })
          }
        </Grid>
      )
  }, [year])

  useEffect(() => {
    if (current) {
      const year = Object.keys(current)[0];
      const data = current[year]
      setYear(year);
      setTenureData(data)
    } 
  }, [current])

  useEffect(() => {
    setCurrent(selected);
  }, [selected])

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>
          {
            Object.entries(tenureData).length == 2 ? 'Adeudos ' + year : 'Tenencia / Refrendo'
          }
        </span>
      </div>
      {
        error ? (
          <ServiceErrorSection type={'main'} />
        ) : foreigner ? (
          <>
            <div className="alert-content-section" style={{ marginBottom: '10px', marginTop: '20px' }}>
              <p className="alert-title">¡Tu placa no está registrada en el sistema de la Ciudad de México!</p>
            </div>
            <div className="content-section">
              <span className="main-label verification-label" style={{ fontSize: '10px', padding: '0px 20px' }}>
                En cuanto tengamos a tu Estado disponible en nuestro sistema, actualizaremos la información y te notificamos para que estés enterado.
              </span>
            </div>
          </>
        ) : (
          noDebts ? (
            <>
              <div className="success-content-section">
                <p className="success-title">
                  <span>Tu vehículo no presenta adeudos</span>
                  <FontAwesomeIcon icon="check-circle" size="lg" />
                </p>
              </div>
              <div className="service-main-footer">
                <p className="footer-content">
                  Si usted desea corregir o aclarar un pago ya realizado, puede comunicarse
                  a la Administración Tributaria del Estado más cercana a su domicilio.
                </p>
                <p className="footer-content">
                  La presente información es mostrada sin perjuicio de las facultades de
                  comprobación de las autoridades fiscales.
                </p>
              </div>
            </>
          ) : (
            <div className="service-overflow" style={{ height: `${height}px` }}>
              <div className="service-main-content">
                {
                  Object.entries(tenureData).length == 2 ?
                    <TenueEDOMEXSection
                      year={year}
                      data={tenureData}
                    /> :
                    <TenueCDMXSection
                      year={year}
                      data={tenureData}
                      buttons={true}
                    />
                }
              </div>
            </div>
          )
        )
      }
    </div>
  )
}

export default TenureServiceMainSection
