import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  Grid, InputAdornment, MenuItem, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format";
import monthNames from '../../data/monthNames';
import { daysInMonth, getYears } from '../../util/helpers';

import './SetMaintenanceServiceSection.css';
const years = getYears(2015, 2030);
const days = daysInMonth(1, 2021);

const SetMaintenanceServiceSection = ({ control }) => {
  return (
    <div className="maintenance-service-section">
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid className="register-car-detail" item xs={12}>
            <p className="register-car-detail-title">
              Ingresa la fecha del servicio / mantenimiento
            </p>
          </Grid>
          <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
            <Controller
              name="serviceDay"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  select
                  size="small"
                  label="Día"
                  className={value && "register-car-detail-select-selected"}
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                >
                  {days.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
            <Controller
              name="serviceMonth"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  select
                  size="small"
                  label="Mes"
                  className={value && "register-car-detail-select-selected"}
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                >
                  {monthNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid className="register-car-detail-select-content" container item xs={4} alignContent="flex-end">
            <Controller
              name="serviceYear"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  select
                  size="small"
                  label="Año"
                  className={value && "register-car-detail-select-selected"}
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                >
                  {years.map((name) => (
                    <MenuItem key={name} value={name} autoFocus={name == new Date().getFullYear()}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid className="register-car-detail" item xs={12}>
          </Grid>
          <Grid className="register-car-detail-select-content" item xs={12}>
            <Controller
              name="serviceKm"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberFormat
                  customInput={TextField}
                  thousandSeparator={true}
                  decimalScale={0}
                  label="Kilometraje actual"
                  className={"register-car-detail-service-km " + (value && "register-car-detail-select-selected")}
                  value={value}
                  inputProps={{ inputMode: "decimal", }}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SetMaintenanceServiceSection
