import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import FooterSection from "../../component/sections/FooterSection";
import BasicSingleReportSection from "../../component/sections/singleReport/BasicSingleReportSection";
import PremiumSingleReportSection from "../../component/sections/singleReport/PremiumSingleReportSection";
import TenueCDMXSection from "../../component/sections/tenue/TenueCDMXSection";
import TenueEDOMEXSection from "../../component/sections/tenue/TenueEDOMEXSection";
import { AppContext } from "../../contexts";
import { appTypes } from "../../reducers/types";
import { getSingleReportPremium } from "../../services/singleReportService";
import { Images } from "../../util";
import "./PremiumSingleReportPage.css";
import PenalityCDMXSection from "../../component/sections/penality/PenalityCDMXSection";
import PenalityEDOMEXSection from "../../component/sections/penality/PenalityEDOMEXSection";
import FotocivicasServiceSection from "../../component/sections/fotocivicas/FotocivicasServiceSection";
import NoPenalityEnvironmentalSanctionSection from "../../component/sections/environmentalSanction/NoPenalityEnvironmentalSanctionSection";
import FooterEnvironmentalSanctionSection from "../../component/sections/environmentalSanction/FooterEnvironmentalSanctionSection";
import EnvironmentalSanctionSection from "../../component/sections/environmentalSanction/EnvironmentalSanctionSection";
import CirculationCardSection from "../../component/sections/circulationCard/CirculationCardSection";
import moment from "moment";
import PlateRenewSection from "../../component/sections/plateRenew/PlateRenewSection";
import TheftReportSection from "../../component/sections/theftReport/TheftReportSection";
const estateName = {
  cdmx: 'Ciudad de México',
  edomex: 'Estado de México'
}
const PremiumSingleReportPage = ({ history }) => {
  const { app: { singleReport: { vehicle, services } }, dispatch } = useContext(AppContext);
  const [premiumServices, setPremiumServices] = useState(null)
  useEffect(async () => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        listMenu: true,
        burger: false,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Informe vehicular <span>Premium</span>",
        back: () => history.goBack()
      }
    })
    const { success, data: resp } = await getSingleReportPremium(vehicle.id);
    if (success) {
      setPremiumServices(services.map((service) => {
        let data = [];
        switch (service.name) {
          case 'Pago de Tenencia':
            data = resp.tenencia_refrendo ? setTenenciaService(resp.tenencia_refrendo) : false;
            break;
          case 'Infracciones':
            data = resp.multa_transito ? setInfraccionesService(resp.multa_transito) : false;
            break;
          case 'FotoCívicas':
            data = resp.fotocivica ? setFotocivicasService(resp.fotocivica) : false;
            break;
          case 'Sanción ambiental':
            data = resp.medioambiente ? setMedioAmbienteService(resp.medioambiente) : false;
            break;
          case 'Tarjeta de circulación':
            data = resp.tarjeta_circulacion ? setTarjetaCirculacionService(resp.tarjeta_circulacion) : false;
            break;
          case 'Renovación de placas':
            data = resp.plate_renewal ? setRenovacionPlacaService(resp.plate_renewal) : false;
            break;
          case 'Reporte de robo':
            data = resp.robbery_report ? setReporteRoboService(resp.robbery_report) : false;
            break;
        }
        return data ? {
          ...service,
          data
        } : { value: null }
      }));
    }
  }, [])

  const setTenenciaService = (data) => ([{
    title: vehicle.estate,
    sections: data ? data.map((item) => {
      const year = Object.keys(item)[0];
      const data = item[year]
      return {
        title: `Adeudo ${year}`,
        collapsable: true,
        section: Object.entries(data).length == 2 ?
          <TenueEDOMEXSection
            data={data}
          /> :
          <TenueCDMXSection
            year={year}
            data={data}
            buttons={false}
          />
      }
    }) : [{
      section: (
        <>Sin Adeudos</>
      )
    }]
  }])

  const setInfraccionesService = (data) => (
    Object.entries(data).map(([name, data]) => ({
      title: estateName[name],
      sections: data.map((item) => ({
        title: `Folio: ${item.folio}`,
        collapsable: true,
        section: item.folio ?
          <PenalityCDMXSection
            multa={item}
            premium={true}
          /> :
          <PenalityEDOMEXSection
            multa={item}
            premium={true}
          />
      }))
    }))
  )

  const setFotocivicasService = ({ puntos, infracciones }) => ([{
    title: vehicle.estate,
    sections: [{
      title: 'Tu puntaje actual:',
      section: <FotocivicasServiceSection puntos={puntos} infracciones={infracciones} premium={true} />
    }]
  }])

  const setMedioAmbienteService = (data) => ([{
    title: vehicle.estate,
    sections: data[0].folio ? data.map((item) => ({
      title: `Folio: ${item.folio}`,
      collapsable: true,
      section: <EnvironmentalSanctionSection premium={true} {...item} />
    })) : [{
      section: (
        <>
          <NoPenalityEnvironmentalSanctionSection />
          <FooterEnvironmentalSanctionSection />
        </>
      )
    }]
  }])

  const setTarjetaCirculacionService = (data) => ([{
    title: vehicle.estate,
    sections: [{
      section: <CirculationCardSection
        premium={true}
        expiration={moment(data.circulation_card_expiration, 'YYYY-MM-DD')}
        expire={data.expire}
      />
    }]
  }])

  const setRenovacionPlacaService = (data) => ([{
    title: vehicle.estate,
    sections: [{
      section: <PlateRenewSection
        premium={true}
        expiration={moment(data.fechaFinVigenciaFormat, 'DD-MM-YYYY')}
        expire={moment(data.fechaFinVigenciaFormat, 'DD-MM-YYYY').isBefore(moment())}
      />
    }]
  }])

  const setReporteRoboService = ({ PGJ_reporte: PGR, OCRA_reporte: OCRA, RAPI }) => ([
    PGR ? {
      title: 'Procuraduría General de Justicia (PGJ)',
      collapsable: true,
      sections: [{
        section: <TheftReportSection
          service={PGR}
          name={(alert) => `${alert ? '' : 'la '}Procuraduría General de Justicia (PGJ)`}
          footer={(alert) =>
            `El vehículo ${alert ? '' : 'no '}cuenta con reporte de robo, conforme a la información que 
            suministran las Instituciones de Seguros con base en el Artículo 23, 
            Fracción III, Inciso b de la Ley de Registro Público Vehicular.`
          }
        />
      }]
    } : null,
    OCRA ? {
      title: 'Oficina Coordinadora de Riesgos Asegurados (OCRA)',
      collapsable: true,
      sections: [{
        section: <TheftReportSection
          service={OCRA}
          name={(alert) => `${alert ? '' : 'la '}Oficina Coordinadora de Riesgos Asegurados (OCRA)`}
          footer={(alert) =>
            `El vehículo ${alert ? '' : 'no '}cuenta con reporte de robo, conforme a la información que 
          suministran las Instituciones de Seguros con base en el Artículo 23, 
          Fracción III, Inciso b de la Ley de Registro Público Vehicular.`
          }
        />
      }]
    } : null,
    RAPI ? {
      title: 'Registro de Automotores de Procedencia Ilícita (RAPI)',
      collapsable: true,
      sections: [{
        section: <TheftReportSection
          service={RAPI}
          name={(alert) => `${alert ? '' : 'la '}Registro de Automotores de Procedencia Ilícita (RAPI)`}
          footer={(alert) => `El vehículo ${alert ? '' : 'no '}cuenta con reporte de procedencia ilícita.`}
        />
      }]
    } : null
  ])

  return (
    <div className="basic-single-report-page">
      <div className="container">
        <BasicSingleReportSection
          vehicle={vehicle}
          services={services}
        >
          {
            premiumServices ?
              <PremiumSingleReportSection services={premiumServices} /> :
              (
                <div className="view-loading-container">
                  <CircularProgress size={60} />
                </div>
              )
          }
        </BasicSingleReportSection>
        <p style={{ marginTop: "60px", textAlign: "center" }}>
          <span
            style={{
              fontFamily: "Roboto",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#383881",
              marginRight: "8px",
            }}
            onClick={() => history.push("/contratar_alerta")}
          >
            Contratar una Alerta
          </span>
          <img src={Images.ARROW_RIGHT}></img>
        </p>
        <p
          style={{
            marginTop: "20px",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "17px",
            color: "#707C89",
            marginBottom: "50px",
          }}
        >
          * Las Alertas se renuevan automáticamente cada año y se pueden <br />
          cancelar en cualquier momento
        </p>
      </div>
      <FooterSection />
    </div>
  );
};

export default PremiumSingleReportPage;
