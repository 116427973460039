import { useContext, useEffect } from "react";
import SingleReportSection from "../../component/sections/singleReport/SingleReportSection";
import { AppContext } from "../../contexts";
import { appTypes } from "../../reducers/types";
import "./PremiumReportPage.css";

const PremiumReportPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        listMenu: true,
        burger: false,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Informe vehicular <span>PREMIUM</span>",
        subtitle: "¿Quieres saber el estatus actual de tu vehículo con placas de la <b>CDMX</b> o <b>Estado de México</b>? <br className='mobile' />Ingresa tus placas o NIV (No. de Serie) y recibe el informe en tu correo.",
        back: () => history.goBack()
      }
    })
  }, [])


  return (
    <div className="single-report-page">
      <div className="container">
        <SingleReportSection premium={true}/>
      </div>
    </div>
  );
};

export default PremiumReportPage;
