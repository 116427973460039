import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, CircularProgress, TextField } from "@mui/material";
import { Autocomplete } from "@material-ui/lab";
import "./NotFoundEstatePage.css";
import { appTypes } from "../reducers/types";
import { AppContext } from "../contexts";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setUserOnHold } from "../services/UserServices";
import { getEstates } from "../services/EstateServices";

const NotFoundEstatePage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.not_found_estate);
  const { handleSubmit, control, setError, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [activateButton, setActivateButton] = useState(true);
  const [estates, setEstates] = useState(null);

  useEffect(async () => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        subtitle: texts.subtitle_desktop,
        back: () => history.push('/find_estate')
      }
    })
    if (user) {
      setValue('email', user.email);
    }
    const { success, data } = await getEstates(false);
    if (success)
      setEstates(data.states)

  }, [])

  const onSubmit = async ({ email, state }) => {
    setLoading(true)
    const { success } = await setUserOnHold({
      email,
      state: state.id
    });
    setLoading(false)
    if (success) {
      history.push("/estate_data_recived");
    } else {
      setError("email", {
        message: 'Ha ocurrido un error al guardar tu información'
      });
    }
  };

  return (
    <div className="not-found-estate-container">
      <form onSubmit={handleSubmit(onSubmit)} className="not-found-estate-form-container">
        <div className="not-found-estate-input-container">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Debes llenar el campo de 'Correo Electrónico'",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'El correo electrónico no es valido'
              }
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                value={value}
                value={value.toLowerCase()}
                onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                error={!!error}
                helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
              />
            )}
          />
        </div>
        <div className="not-found-estate-input-container">
          {
            estates == null ? (
              <div className="view-loading-container">
                <CircularProgress size={30} />
              </div>
            ) : (
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{
                  required: "Debes seleccionar tu estado",
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    options={estates}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona tu Estado"
                        variant="outlined"
                        fullWidth
                        error={!!error}
                        helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled"
                        }}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />

                )}
              />
            )
          }

        </div>
        <div className="not-found-estate-content-button">
          <Button
            disabled={!activateButton}
            className="alerta-purple-button"
            type="submit"
          >
            <span className="alerta-purple-button-text">Siguiente</span>
            {
              loading && (
                <span className="alerta-purple-button-progress">
                  <CircularProgress
                    size={30}
                    color='inherit'
                  />
                </span>
              )
            }
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NotFoundEstatePage;
