import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { Animated } from 'react-animated-css'
import { Images } from '../../util'
import './HomeAdditionalServicesSection.css'
const homeAdditionalServices = [
  { id: 1, label: 'Contrata un Seguro', details: "Contrata tu Seguro de auto con el mejor precio del mercado" },
  { id: 2, label: 'Reglamento de Tránsito', details: "Tenemos un buscador inteligente para que puedas consultar el Reglamento de Tránsito en cualquier momento." },
  { id: 3, label: 'Traza tu Ruta', details: "Planea tus viajes y obtén  la duración y costo de casetas / combustible" },
  { id: 4, label: 'Asistencia Vial', details: "Si necesitas una grúa o asistencia vial, puedes contratar este servicio." },
  { id: 5, label: 'Corralón', details: "No te preocupes, aquí te ayudamos a encontrarlo" },
  { id: 6, label: 'Blog', details: "Encuentra las últimas noticias, consejos y tips para tu auto / motocicleta" },
  { id: 7, label: 'Vende tu Auto', details: "Véndelo hoy mismo al mejor precio del mercado" },
  { id: 8, label: 'Corralones', details: "¿Se llevó la grúa tu vehículo? nosotros te ayudamos a encontrarlo" },
]

const HomeAdditionalServicesSection = () => {
  const [expanded, setExpanded] = useState('panel1')
  const [showMore, setShowMore] = useState(false)

  const renderAdditionalService = (id, label, details) => (
    <Accordion
      className="service-container"
      disableGutters={true}
      expanded={expanded === `panel${id}`}
      onChange={() => setExpanded(`panel${id}`)}>
      <AccordionSummary
        className="service-title"
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
        expandIcon={
          <FontAwesomeIcon
            className="service-action-icon"
            size="lg"
            icon={expanded === `panel${id}` ? "minus-square" : ["far", "plus-square"]} />
        }>
        <Typography >{label}</Typography>
      </AccordionSummary>
      <AccordionDetails className="service-details">
        <Typography >{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
  return (
    <div className="home-additional-services-section">
      <Grid container>
        <Grid container item xs={12} md={6}>
          <img style={{ maxWidth: '530px' }} src={Images.ADDITIONAL_SERVICES} alt="Servicos Addicionales" />
        </Grid>
        <Grid container item xs={12} md={6} flexDirection="column" justifyContent="center" alignContent="center">
          <p className="title">Servicios adicionales incluidos con mi Alerta</p>
          <div>
            {
              homeAdditionalServices.filter((q, i) => i < 4).map(({ id, label, details }) => (
                renderAdditionalService(id, label, details)
              ))
            }
            <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={showMore}>
              <div className="show-more-container">
                {
                  homeAdditionalServices.filter((q, i) => i > 4).map(({ id, label, details }) => (
                    renderAdditionalService(id, label, details)
                  ))
                }
                {
                  showMore && (
                    <p className="show-more" onClick={() => setShowMore(!showMore)}>
                      Ver menos <FontAwesomeIcon icon="arrow-right" />
                    </p>
                  )
                }
              </div>
            </Animated>
          </div>
          {
            !showMore && (
              <p className="show-more" onClick={() => setShowMore(!showMore)}>
                Ver más <FontAwesomeIcon icon="arrow-right" />
              </p>
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeAdditionalServicesSection
