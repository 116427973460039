import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './LimitVehiclesTotalDialog.css';

const LimitVehiclesTotalDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="limit-vehicle-total-dialog"
    >
      <div className="limit-vehicle-total-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.CARS_LIMIT} alt="" />
          </Grid>
          <Grid item >
            <p className="limit-vehicle-total-dialog-title">Máximo 20 vehículos</p>
          </Grid>
          <Grid item >
            <p className="limit-vehicle-total-dialog-subtitle">Si tienes una flotilla con más de 20 vehículos, contáctanos para registrarlos.</p>
          </Grid>
          <Grid className="limit-vehicle-total-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onClose}>
              <p className="alerta-purple-button-text">Enterado</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default LimitVehiclesTotalDialog
