import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, Grid, IconButton, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../contexts'
import alertaServices from '../../../data/alertaServices'
import { singleReportTypes } from '../../../reducers/types'
import { checkSingleReport, registerSingleReportByNIV, registerSingleReportByPlate } from '../../../services/singleReportService'
import { Images } from '../../../util'
import NIVTooltip from '../../controls/NIVTooltip'
import InvalidPlateDialog from '../../dialogs/InvalidPlateDialog'
import PlateUsedSingleReportDialog from '../../dialogs/PlateUsedSIngleReportDialog'
import SingleReportPremiumSection from './SingleReportPremiumSection'
import './SingleReportSection.css'

const SingleReportSection = ({ premium }) => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const { handleSubmit, control, clearErrors } = useForm();
  const watch = useWatch({ control });
  const [selected, setSelected] = useState('plate')
  const [loading, setLoading] = useState(false)
  const [enable, setEnable] = useState(false)
  const [inputText, setInputText] = useState({
    label: '',
    error: ''
  })

  const [invalidPlateDialog, setInvalidPlateDialog] = useState({ open: false })
  const [plateUsedSingleReportDialog, setPlateUsedSingleReportDialog] = useState({ open: false })

  useEffect(() => {
    setInputText(selected == 'plate' ? {
      label: 'Ingresa tu número de placa',
      error: 'Debes ingresar el número de placa',
    } : {
      label: 'Ingresa tu No. de Serie o NIV',
      error: 'Debes ingresar el no. de serie o NIV',
    })
    clearErrors(['email', 'plate'])
  }, [selected])

  useEffect(() => {
    const { email, plate } = watch;
    setEnable(email != '' && plate != '')
  }, [watch])

  const onSubmit = async ({ plate, email }) => {
    setLoading(true);
    setEnable(false);
    const { success } = await checkSingleReport(selected == 'plate' ? { plate } : { niv: plate });
    if (success) {
      const { success, data } = selected == 'plate' ? await registerSingleReportByPlate(plate, email) : await registerSingleReportByNIV(plate, email);
      if (success) {
        dispatch({
          type: singleReportTypes.createReport,
          payload: {
            type: premium ? 'premium' : 'basic',
            services: alertaServices.map(({ name, code, status }) => ({ name, code, status })),
            ...data
          }
        });
        history.push('/cargando_informe')
      } else {
        setInvalidPlateDialog({ open: true })
      }
    } else {
      setPlateUsedSingleReportDialog({ open: true })
    }
    setLoading(false);
    setEnable(true);
  }

  return (
    <div className='single-report'>
      <div className='estate-images'>
        <img src={Images.CDMX} alt="CDMX" />
        <img src={Images.EDOMEX} alt="CDMX" />
      </div>
      <div className='btn-container'>
        <button
          className={`search-type-button ${selected == 'plate' ? 'selected' : ''}`}
          onClick={() => setSelected('plate')}
        >
          Consultar por Placa
        </button>
        <button
          className={`search-type-button ${selected == 'niv' ? 'selected' : ''}`}
          onClick={() => setSelected('niv')}
        >
          Consultar por No. de Serie o NIV
        </button>
        {
          selected != 'plate' && (
            <p className='helper-niv'>
              <NIVTooltip
                className='single-report-niv-tooltip'
                title={(
                  <>
                    <img src={Images[`NIV_CIUDAD_DE_MÉXICO`]} />
                    <img src={Images[`NIV_ESTADO_DE_MÉXICO`]} />
                  </>
                )}
              >
                <IconButton sx={{ padding: 0, fontSize: '12px', color: '#17788d' }}>
                  ¿En dónde encuentro el NIV?
                  <FontAwesomeIcon icon='question-circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </IconButton>
              </NIVTooltip>
            </p>
          )
        }
      </div>
      <form className='form-container' onSubmit={handleSubmit(onSubmit)} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes ingresar tu correo electrónico",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'El correo electrónico no es valido'
                }
              }}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  label="Ingresa tu correo para recibir Informe"
                  variant="outlined"
                  name={name}
                  fullWidth
                  value={value.trim().toLowerCase()}
                  onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="plate"
              control={control}
              defaultValue=""
              rules={{
                required: inputText.error
              }}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  name={name}
                  label={inputText.label}
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value?.toUpperCase().replace(/[^A-Z0-9]/g, ''))}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button fullWidth className="alerta-purple-button" disabled={!enable} type={'submit'}>
              Generar Informe
              {
                loading && (
                  <span className="alerta-purple-button-progress">
                    <CircularProgress size={30} color='inherit' />
                  </span>
                )
              }
            </Button>
          </Grid>
        </Grid>
      </form>
      <p className='message'>
        * Al ingresar el número de tu placa o NIV, autorizas que Alertamiauto.com pueda consultar los datos de tu vehículo en el Registro Público vehicular (REPUVE)
      </p>
      <div className='services'>
        <Grid container flexDirection={'row'} columns={10} spacing={2}>
          {
            alertaServices.map(({ name }) => (
              <Grid item xs={5} sm={2}>
                <div className='service-container'>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                      <img src={Images.ALERTA_MI_AUTO_GREEN} alt="alertamiauto logo" />
                    </Grid>
                    <Grid item >
                      <span className='service-text'>{name}</span>
                    </Grid>
                    <Grid item>
                      <FontAwesomeIcon className='service-icon' icon='check' />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </div>
      {
        !premium && <SingleReportPremiumSection />
      }
      <InvalidPlateDialog
        state={invalidPlateDialog}
        onClose={() => setInvalidPlateDialog({ open: false })}
      />
      <PlateUsedSingleReportDialog
        state={plateUsedSingleReportDialog}
        onClose={() => setPlateUsedSingleReportDialog({ open: false })}
      />
    </div >
  )
}

export default SingleReportSection