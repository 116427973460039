import { vehicleTypes, appTypes, estateTypes, paymentTypes, userTypes, blogTypes, singleReportTypes } from './types';

const appReducer = (state = {}, action) => {
  const { home, header, titles } = state;
  switch (action.type) {
    case appTypes.setHomeSection:
      return {
        ...state,
        homeSection: action.payload
      }
      break;
    case appTypes.setMenu:
      return {
        ...state,
        header: action.payload
      }
      break;
    case appTypes.setTitles:
      return {
        ...state,
        titles: action.payload
      }
      break;
    case appTypes.setAddingVehicles:
      return {
        ...state,
        user: {
          ...state.user,
          addingVehicles: true
        },
        estates: undefined,
        payment: undefined,
        vehicles: undefined
      }
      break;

    case userTypes.createUser:
      return {
        home,
        header,
        titles,
        user: action.payload,
      }
      break;
    case userTypes.updateUser:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
      break;
    case userTypes.deleteUser:
      return {
        home,
        header,
        titles,
      }
      break;

    case estateTypes.createEstate:
      if (state.estates) {
        const estate = state.estates.find((estate) => estate.id == action.payload.id);
        return {
          ...state,
          estates: estate ? state.estates.map((estate) => {
            if (estate.id == action.payload.id)
              return {
                ...estate,
                ...action.payload
              }
            return estate;
          }) : [
            ...state.estates,
            action.payload
          ]
        }
      } else {
        return {
          ...state,
          estates: [action.payload]
        }
      }
      break;
    case estateTypes.deleteEstate:
      return {
        ...state,
        estates: state.estates ? state.estates.filter((estate) => estate.id != action.payload) : []
      }
      break;
    case estateTypes.deleteEstates:
      delete state.estates;
      return state;
      break;
    case estateTypes.setEstateList:
      return {
        ...state,
        estateList: action.payload
      }
      break;

    case vehicleTypes.createVehicles:
      return {
        ...state,
        vehicles: action.payload
      }
      break;
    case vehicleTypes.createVehicle:
      return {
        ...state,
        vehicles: [
          ...state.vehicles,
          action.payload
        ]
      }
      break;
    case vehicleTypes.updateVehicle:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              ...action.payload,
              id: action.payload.newId ? action.payload.newId : action.payload.id,
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.deleteVehicles:
      delete state.vehicles;
      return state
      break;
    case vehicleTypes.deleteVehicle:
      return {
        ...state,
        vehicles: state.vehicles.filter((vehicle) => vehicle.id != action.payload)
      }
      break;
    case vehicleTypes.setServices:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: action.payload.services
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.addServiceVehicle:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                mantenimiento: {
                  ...vehicle.services.mantenimiento,
                  services: [
                    ...vehicle.services.mantenimiento.services,
                    action.payload.service
                  ]
                }
              }
            }
          return vehicle;
        })
      }
      break;

    case vehicleTypes.createAdditionalUser:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              additional_user: action.payload.additionalUser
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.deleteAdditionalUser:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            delete vehicle.additional_user;
          return vehicle;
        })
      }
      break;

    case paymentTypes.createPayment:
      return {
        ...state,
        payment: action.payload
      }
      break;
    case paymentTypes.updatePayment:
      return {
        ...state,
        payment: {
          ...state.payment,
          ...action.payload
        }
      }
      break;

    case vehicleTypes.updateVerification:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                verificacion: {
                  ...vehicle.services.verificacion,
                  ...action.payload.verificacion
                }
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateNoCirculate:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                no_circula: {
                  ...vehicle.services.no_circula,
                  ...action.payload.no_circula
                }
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateTenure:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                tenencia_refrendo: action.payload.tenencia_refrendo
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateFotocivica:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                fotocivica: action.payload.fotocivica
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateInfracciones:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                multa_transito: action.payload.multa_transito
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateCirculationCard:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                tarjeta_circulacion: action.payload.tarjeta_circulacion
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updatePlateRenew:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                renovacion_placa: action.payload.renovacion_placa
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateTheftReport:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                reporte_robo: action.payload.reporte_robo
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateEnvironmentalSanction:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                medioambiente: action.payload.medioambiente
              }
            }
          return vehicle;
        })
      }
      break;
    case vehicleTypes.updateInsure:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) => {
          if (vehicle.id == action.payload.id)
            return {
              ...vehicle,
              services: {
                ...vehicle.services,
                seguro: action.payload.seguro
              }
            }
          return vehicle;
        })
      }
      break;

    case blogTypes.setBlogList:
      return {
        ...state,
        blog: action.payload
      }
      break;
    case blogTypes.addLike:
      return {
        ...state,
        blog: state.blog.map((article) => {
          if (article.id == action.payload)
            return {
              ...article,
              likes: article.likes + 1
            }
          return article;
        })
      }
      break;
    case blogTypes.addShare:
      return {
        ...state,
        blog: state.blog.map((article) => {
          if (article.id == action.payload)
            return {
              ...article,
              shared: article.shared + 1
            }
          return article;
        })
      }
      break;

    case singleReportTypes.createReport:
      return {
        ...state,
        singleReport: action.payload
      }
      break;
    case singleReportTypes.createService:
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          services: state.singleReport.services.map((service) => {
            if (service.name == action.payload.name)
              return {
                ...service,
                ...action.payload
              }
            return service
          })
        }
      }
      break;
    case singleReportTypes.createPayment:
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          payment: action.payload
        }
      }
      break;
    default:
      return state;
  }
}

export default appReducer;