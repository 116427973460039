import { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Images } from "../util";
import { useSelector } from "react-redux";
import { AppContext } from "../contexts";
import { vehicleTypes, appTypes, estateTypes } from "../reducers/types";
import EstateSection from "../component/sections/EstateSection";
import LimitVehiclesByEstateDialog from "../component/dialogs/LimitVehiclesByEstateDialog";
import LimitVehiclesTotalDialog from "../component/dialogs/LimitVehiclesTotalDialog";
import "./FindEstatePage.css";
import { uuidv4 } from "../util/helpers";
import { getEstates } from "../services/EstateServices";
import BeforeExitMessage from "../helpers/BeforeExitMessage";
import { getPendingVehicles } from "../services/VehicleServices";

const FindEstatePage = ({ history }) => {
  const { app: { user, estates, vehicles }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.find_estate);
  const [showButton, setShowButton] = useState(false);
  const [showLimitVehiclesByEstate, setShowLimitVehiclesByEstate] = useState({ open: false });
  const [showLimitVehiclesTotal, setShowLimitVehiclesTotal] = useState({ open: false });
  const [total, setTotal] = useState(0);
  const [estatesList, setEstatesList] = useState([])

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "¿De qué <span>Estado</span> son tus placas del vehículo que quieres registrar ?",
        subtitle: "Puedes registrar vehículos en más de un Estado.<br>El costo por cada vehículo es de <b>$399 pesos al año</b> (IVA incluido).",
        back: () => history.push('/suscriber')
      }
    })
  }, [])

  useEffect(async () => {
    const { success, data } = await getEstates();
    if (success) {
      dispatch({
        type: estateTypes.setEstateList,
        payload: data.states
      })
      setEstatesList(data.states.map(({ id, name }) => {
        const estateName = name.replace(/ /g, '_').toUpperCase();
        return { id, name, image: Images[`${estateName}_ESTATE`], imageBold: Images[`${estateName}_ESTATE_BOLD`] }
      }));
    }
    if (!vehicles) {
      const { success, data } = await getPendingVehicles(user.id);
      if (success) {
        dispatch({
          type: vehicleTypes.createVehicles,
          payload: data.vehicles.filter(({ membership, is_delete, paymentOxxo }) => membership == null && paymentOxxo == null && !is_delete).map((vehicle) => ({
            ...vehicle,
            complete: true
          }))
        })
      }
    }
  }, [])

  useEffect(() => {
    let total = 0;
    (estates || []).map((estate) => {
      total += parseInt(estate.autos);
      total += parseInt(estate.motos);
    });
    setShowButton(total != 0)
    setTotal(total);
  }, [estates])

  const processEstatesVehicles = (estates) => {
    let selected = [];
    let list = [];
    estates.map(({ id, name, autos, motos }) => {
      selected = [
        ...selected,
        ...generateVehiclesToCreate(id, name, autos, 0),
        ...generateVehiclesToCreate(id, name, motos, 1),
      ]
    })
    const completeVehicles = vehicles ? vehicles.filter(({ complete }) => complete) : [];
    selected.map((vehicle, i) => {
      i++;
      const vehicleIndex = completeVehicles.findIndex(complete => complete.type == vehicle.type && complete.estate == vehicle.estate)
      const newVehicle = { number: i, name: `${vehicle.type == 0 ? 'Auto' : 'Moto'} ${i}` }
      if (vehicleIndex == -1) {
        list = [...list, { ...vehicle, ...newVehicle, id: uuidv4() }]
      } else {
        const complete = completeVehicles.splice(vehicleIndex, 1)[0];
        list = [...list, { ...complete, ...newVehicle }]
      }
    })
    dispatch({
      type: vehicleTypes.createVehicles,
      payload: list
    })
  }

  const generateVehiclesToCreate = (state, estate, vehicles, type) => {
    let list = [];
    for (let i = 1; i <= vehicles; i++) {
      list = [...list, {
        type,
        state,
        estate,
        active: true,
        subscription: true,
        complete: false,
      }]
    }
    return list;
  }

  const sendEstates = () => {
    if (total > 20) {
      setShowLimitVehiclesTotal({ open: true })
    } else {
      processEstatesVehicles(estates)
      history.push("/register_car");
    }
  };

  return (
    <>
      <div className="find-estate-container">
        <div className="container estates-container">
          <Grid container spacing={2} alignItems="center" justifyContent="center" >
            {
              estatesList.map((estate) => (
                <Grid key={estate.id} item xs={6} lg={3}>
                  <EstateSection {...estate} setShowLimitVehiclesByEstate={setShowLimitVehiclesByEstate} />
                </Grid>
              ))
            }
          </Grid>
          <div>
            {
              !showButton && (
                <>
                  <div className="footer">
                    <p>* Si tu placa es de otro Estado, pero te gustaría recibir notificaciones de alguno de los que están activos, lo puedes seleccionar y confirmarlo en la pantalla donde ingresas tu placa.</p>
                  </div>
                  <div className="find-estate-content-link"
                    onClick={() => {
                      history.push('/not_found_estate')
                    }}
                  >
                    <p className="find-estate-link">{texts.link}</p>
                    <img
                      className="find-estate-arrow-img"
                      src={Images.ARROW_RIGHT}
                    ></img>
                  </div>
                </>
              )
            }
          </div>
        </div>
        {
          showButton && (
            <>
              <div className="find-estate-button-container">
                <Button className="alerta-purple-button" onClick={(e) => sendEstates()} >
                  <span className="alerta-purple-button-text">{texts.button}</span>
                </Button>
              </div>
              <div className="footer">
                <p>* Si tu placa es de otro Estado, pero te gustaría recibir notificaciones de alguno de los que están activos, lo puedes seleccionar y confirmarlo en la pantalla donde ingresas tu placa.</p>
              </div>
            </>
          )
        }
      </div>
      <LimitVehiclesByEstateDialog
        state={showLimitVehiclesByEstate}
        onClose={() => setShowLimitVehiclesByEstate({ open: false })}
      />
      <LimitVehiclesTotalDialog
        state={showLimitVehiclesTotal}
        onClose={() => setShowLimitVehiclesTotal({ open: false })}
      />
      <BeforeExitMessage />
    </>
  );
};

export default FindEstatePage;
