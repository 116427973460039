const PenalityEDOMEXSection = ({ multa, premium }) => {
  const {
    CMP_ID: identificador,
    INFRAC_FOLIO: infraccion,
    P_MONTO: monto,
    DESCUENTO: descuento,
    TOTAL: total,
    DESCRIPCION_ARTICULO: articulo,
    INFRAC_M_FECHA_HORA_T: fecha,
    situacion
  } = multa;
  return (
    <>
      {
        !premium && (
          <p className="content-title">Identificador: {identificador}</p>
        )
      }
      <div className="content-section">
        <span className="main-label">Infracción</span>
        <span className="main-variable">{infraccion}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Monto</span>
        <span className="main-variable">{monto}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Descuento</span>
        <span className="main-variable">{descuento}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Total</span>
        <span className="main-variable">{total}</span>
      </div>
      <div className="content-section">
        <span className="main-label">
          <span>Artículo: </span>
          <span className="main-sublabel">{articulo.replace(/-/gi, '').trim()}</span>
        </span>
      </div>
      <div className="content-section">
        <span className="main-label">Fecha y hora de infracción</span>
        <span className="main-variable">{fecha}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Estatus de Infracción</span>
        <span className={`main-variable ${situacion.includes('No pagada') ? 'alert' : ''}`}>{situacion}</span>
      </div>
    </>
  )
}

export default PenalityEDOMEXSection