import { Switch, Route, Redirect, useParams } from "react-router-dom";
import ConfirmCodePage from "../page/ConfirmCodePage";
import EmailValidationCodePage from "../page/EmailValidationCodePage";
import EstateDataRecivedPage from "../page/EstateDataRecivedPage";
import FindEstatePage from "../page/FindEstatePage";
import NotFoundEstatePage from "../page/NotFoundEstatePage";
import PaymentProcessedPage from "../page/PaymentProcessedPage";
import ProcessingPaymentPage from "../page/ProcessingPaymentPage";
import PaymentPage from "../page/PaymentPage";
import RegisterCarDetailPage from "../page/RegisterCarDetailPage";
import RegisterCarPage from "../page/RegisterCarPage";
import RegisterDataContactPage from "../page/RegisterDataContactPage";
import MyAlertsPage from "../page/MyAlertsPage";
import EditAlertPage from "../page/EditAlertPage";
import ConfigNotificationsVehiclePage from "../page/ConfigNotificationsPage";
import { useContext } from "react";
import { AppContext } from "../contexts";
import ValidateRoute from "./ValidateRoute";
import InsurancePage from "../page/InsurancePage";
import ProcessingAlertInfoPage from "../page/ProcessingAlertInfoPage";
import VehicleServicePage from "../page/myAlerts/VehicleServicePage";

const PrivateRoutes = () => {
  const { app: { user, vehicles, payment } } = useContext(AppContext);

  const validatePrev = (prev, validation) => {
    const [isValid, redirect] = prev();
    if (!isValid)
      return [isValid, redirect];
    return validation()
  }

  const validateRegisterCar = () => vehicles ? [true] : [false, '/find_estate'];

  const validateRegisterCarDetail = (params) => {
    return validatePrev(validateRegisterCar, () => {
      const currentVehicle = vehicles.find((vehicle) => vehicle.id == params.vehicleId);
      return currentVehicle ? [true] : [false, '/register_car']
    })
  };

  const validateRegisterDataContact = () => {
    return validatePrev(validateRegisterCar, () => {
      const completeVehicles = vehicles.filter((vehicle) => vehicle.complete);
      return completeVehicles.length > 0 ? [true] : [false, '/register_car']
    })
  };

  const validateEmailValidationCode = () => {
    return validatePrev(validateRegisterDataContact, () => {
      return user && user.email && user.phone ? [true] : [false, '/register_data_contact']
    })
  };

  const validatePayment = () => {
    return validatePrev(validateEmailValidationCode, () => {
      if (payment && payment.id)
        return [false, '/payment_processed'];
      return user && user.email && user.phone ? [true] : [false, '/register_data_contact']
    })
  };

  const validateProcessingPayment = () => {
    return payment && payment.id ? [true] : [false, '/payment']
  };

  const validatePaymentProcessed = () => {
    return validatePrev(validateProcessingPayment, () => {
      return payment && payment.id ? [true] : [false, '/payment']
    })
  };

  const validateProcessingAlertInfo = () => user && user.token && user.registered ? [true] : [false, '/login'];

  const validateMyAlerts = () => user && user.token && user.registered ? [true] : [false, '/login'];

  return (
    <>
      <Switch>
        <Route exact path="/find_estate" component={FindEstatePage} />
        <Route exact path="/not_found_estate" component={NotFoundEstatePage} />
        <Route exact path="/estate_data_recived" component={EstateDataRecivedPage} />

        <ValidateRoute exact path="/register_car" component={RegisterCarPage} validation={validateRegisterCar} />
        <ValidateRoute exact path="/register_car_detail/:vehicleId" component={RegisterCarDetailPage} validation={validateRegisterCarDetail} />

        <ValidateRoute exact path="/register_data_contact" component={RegisterDataContactPage} validation={validateRegisterDataContact} />
        <ValidateRoute exact path="/email_validation_code" component={EmailValidationCodePage} validation={validateEmailValidationCode} />
        <ValidateRoute exact path="/confirm_code" component={ConfirmCodePage} validation={validateEmailValidationCode} />

        <ValidateRoute exact path="/payment" component={PaymentPage} validation={validatePayment} />

        <ValidateRoute exact path="/processing_payment" component={ProcessingPaymentPage} validation={validateProcessingPayment} />
        <ValidateRoute exact path="/payment_processed" component={PaymentProcessedPage} validation={validatePaymentProcessed} />
        <ValidateRoute exact path="/procesando_informacion" component={ProcessingAlertInfoPage} validation={validateProcessingAlertInfo} />

        <ValidateRoute exact path="/my_alerts" component={MyAlertsPage} validation={validateMyAlerts} />
        <ValidateRoute exact path="/alertas" component={MyAlertsPage} validation={validateMyAlerts} />
        <ValidateRoute exact path="/edit_alert/:vehicleId" component={EditAlertPage} validation={validateMyAlerts} />
        <ValidateRoute exact path="/alerta/:vehicleId/:serviceName" component={VehicleServicePage} validation={validateMyAlerts} />

        <ValidateRoute exact path="/config_notifications" component={ConfigNotificationsVehiclePage} validation={validateMyAlerts} />

        <Route exact path="/insurance" component={InsurancePage} />
        {/* <Route exact path="/insurance_recived" component={InsuranceInfoRecivedPage} /> */}
        {/* <Route exact path="/blog_detail" component={BlogDetailPage} /> */}
        {/* <Route exact path="/corralon" component={CorralonPage} /> */}
        {/* <Route exact path="/roadside_assistance" component={RoadsideAssistancePage} /> */}
        {/* <Route exact path="/roadside_assistance_confirmed" component={RoadsideAssistanceConfirmedPage} /> */}
        {/* <Route exact path="/sell_car" component={SellCarPage} /> */}
        {/* <Route exact path="/trace_route" component={TraceRoutePage} /> */}
        {/* <Route exact path="/trace_route_detail" component={TraceRouteDetailPage} /> */}
        {/* <Route exact path="/traffic_regulation" component={TrafficRegulationPage} /> */}
        {/* <Route exact path="/traffic_regulation_detail" component={TrafficRegulationDetailPage} /> */}
        <Redirect to="/not_found" />
      </Switch>
    </>
  )
}

export default PrivateRoutes
