import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import '../ServiceSideSection.css';
import { CircularProgress } from '@mui/material';
import { AppContext } from '../../../contexts';
import InsureServiceMainSection from './InsureServiceMainSection';
import { getInsureVehicleData } from '../../../services/VehicleServices';
import { vehicleTypes } from '../../../reducers/types';
let insureTimer = false;

const InsureServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { niv, services: { seguro } } = vehicle;
  seguro = seguro || {};
  const loading = niv != '' && Object.entries(seguro).length == 0;
  const { poliza, vigencia, estatus } = seguro;

  useEffect(() => {
    console.log(niv);
    insureTimer = false;
    return () => {
      insureTimer = true;
    }
  }, [])

  useEffect(() => {
    if (selected == 'insure')
      setMainPanel(<InsureServiceMainSection vehicle={vehicle} />)
  }, [selected, vehicle])

  useEffect(() => {
    if (open && loading) {
      insureTimer = false;
      getInsureData()
    } else
      insureTimer = true
  }, [open, loading])

  const getInsureData = async () => {
    const { success, data } = await getInsureVehicleData(vehicle.id)
    if (success && Object.entries(data.seguro).length != 0) {
      dispatch({
        type: vehicleTypes.updateInsure,
        payload: {
          id: vehicle.id,
          seguro: data.seguro
        }
      });
    } else {
      setTimeout(() => {
        if (!insureTimer)
          getInsureData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        niv == '' ? (
          <>
            <p className='title'>
              Seguro Vehicular
            </p>
            <span className="side-variable">
              Nuestro sistema no encontró tu NIV o Número de Serie
            </span>
          </>
        ) : loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Seguro Vehicular</p>
            <p>
              <span className="side-label">Póliza</span>
              <span className="side-variable">{poliza}</span>
            </p>
            <p>
              <span className="side-label">Vigencia</span>
              <span className="side-variable">{vigencia}</span>
            </p>
            <p>
              <span className="side-label">Estatus</span>
              <span className={`side-variable ${estatus ? '' : 'alert'}`}>{estatus ? 'Vigente' : 'Vencida'}</span>
            </p>
          </>
        )
      }
    </div >
  )
}

export default InsureServiceSideSection
