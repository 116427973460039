import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import './BlogArticleSection.css'

const BlogArticleSection = ({
  id,
  image,
  title,
  subtitle,
  createdAt,
  likes,
}) => {
  let history = useHistory();
  const date = moment(createdAt).isValid() ? moment(createdAt) : moment();
  return (
    <div className="blog-article" onClick={() => history.push("/blog/" + id)}>
      <div className="blog-article-photo">
        <img src={image} alt="" />
      </div>
      <div className="blog-article-footer">
        <Grid container>
          <Grid item xs={11} md={10}>
            <p className="blog-article-title">{title}</p>
            <p className="blog-article-subtitle">{subtitle}</p>
            <p className="blog-article-date">
              {date.format("DD[ de ]MMMM[ de ]YYYY")}
            </p>
          </Grid>
          <Grid container item xs={1} md={2} justifyContent="flex-end">
            <div className="blog-likes-container">
              <FontAwesomeIcon className="blog-article-icon" icon="heart" />
              <p className="blog-article-likes">{likes}</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BlogArticleSection;
