import moment from "moment"

const EnvironmentalSanctionSection = ({ premium, folio, situacion, sancion, motivo, fecha, fundamento }) => {
  return (
    <div className="service-main-content">
      {
        !premium && <p className="content-title">Folio: {folio}</p>
      }
      <div className="content-section">
        <span className="main-label">Monto UMAs</span>
        <span className="main-variable">{sancion}</span>
      </div>
      <div className="content-section">
        <span className="main-label">Estatus</span>
        <span className={`main-variable ${situacion.includes('No pagada') ? 'alert' : ''}`}>{situacion.includes('No pagada') ? 'No pagada' : situacion}</span>
      </div>
      <div className="penality-article">
        <div className="content-section">
          <span className="main-label">
            <span>Artículo: </span>
            <span className="main-sublabel">{fundamento}</span>
          </span>
        </div>
        <div className="content-section">
          <span className="main-label">
            <span>Motivo:</span><br />
            <span className="main-sublabel">{motivo}</span>
          </span>
        </div>
      </div>
      <div className="content-section">
        <span className="main-label">Fecha de sanción</span>
        <span className="main-variable">{moment(fecha).format('DD[ de ]MMMM[ de ]YYYY')}</span>
      </div>
    </div>
  )
}

export default EnvironmentalSanctionSection