import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts";
import { Images } from "../../util";
import "./FooterSection.css";

const FooterSection = () => {
  const { app: { home: { how, why, opinion, footer } } } = useContext(AppContext);
  const handlerClickToSection = (section, offset = 0) => {
    window.scrollBy({ behavior: "smooth", top: section.current.offsetTop - window.scrollY - offset })
  }
  return (
    <footer ref={footer} id="footer-section" className="principal">
      <div className="container wrap-general wrap-footer-organism">
        <div className="section-container">
          <img src={Images.LOGO_ALERTA_WHITE} />

          <p className="description">
            Somos una plataforma digital que te notifica
            en tiempo real sobre el pago de tenencia,
            verificación, no circula y otros servicios que
            te ayudarán a evitar multas y recargos.
          </p>

          <p className="description">
            ¡Contrata una Alerta y registra tu<br /> automóvil o motocicleta hoy mismo!
          </p>
        </div>
        <div className="section-container">
          <p className="title-ul">Links</p>
          <ul>
            <li>
              <Link to="/contratar_alerta">Contratar Alerta</Link>
            </li>
            <li onClick={() => handlerClickToSection(how, 130)}>¿Cómo funciona?</li>
            <li onClick={() => handlerClickToSection(why, 130)}>¿Porqué contratar Alerta?</li>
            <li onClick={() => handlerClickToSection(opinion, 130)}>Opinión de clientes</li>
            <li>
              <Link to="/doubts">Preguntas frecuentes</Link>
            </li>
          </ul>
        </div>
        <div className="section-container">
          <p className="title-ul">Legales</p>
          <ul>
            <li>
              <Link to="/aviso_privacidad">Aviso de Privacidad</Link>
            </li>
            <li>
              <Link to="/terminos_condiciones">Términos y Condiciones</Link>
            </li>
          </ul>
        </div>
        <div className="section-container">
          <p className="title-ul">Contacto</p>
          <p className="email">
            <a
              href="mailto:hola@alertamiauto.com"
              style={{ color: "#aaaaaa", textDecoration: "none" }}
            >
              hola@alertamiauto.com
            </a>
          </p>
          <div className="content-img">
            <img
              className="margin"
              src={Images.FACEBOOK}
              onClick={() => window.open("https://www.facebook.com/alertamiauto/", "_blank")}
            />
            <img
              className="margin"
              src={Images.TWITTER}
              onClick={() => window.open("https://twitter.com/alertamiauto", "_blank")}
            />
            <img
              className="margin"
              src={Images.INSTAGRAM}
              onClick={() => window.open("https://www.instagram.com/alertamiauto/", "_blank")}
            />
            <img
              src={Images.LINKEDIN}
              onClick={() => window.open("https://www.linkedin.com/company/77168974/admin/", "_blank")}
            />
          </div>
        </div>
      </div>
      <div className="container wrap-general wrap-footer-organism disclaimer">
        <p>
          <span className="after-year">2022 </span>© Alerta Digital SAPI de CV.
          Todos los derechos. <span className="before-year">2022</span>
        </p>
      </div>
    </footer>
  );
};

export default FooterSection;