import { useContext, useEffect } from "react";
import BasicSingleReportSection from "../../component/sections/singleReport/BasicSingleReportSection";
import SingleReportPremiumSection from "../../component/sections/singleReport/SingleReportPremiumSection";
import { AppContext } from "../../contexts";
import BeforeExitMessage from "../../helpers/BeforeExitMessage";
import { appTypes } from "../../reducers/types";
import "./BasicSingleReportPage.css";

const BasicSingleReportPage = ({ history }) => {
  const { app: { singleReport: { vehicle, services } }, dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        listMenu: true,
        burger: false,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "Informe vehicular <span>básico</span>",
        back: () => history.push('/informe')
      }
    })
    console.log(services);
  }, [])

  return (
    <>
      <div className="basic-single-report-page">
        <div className="container">
          <BasicSingleReportSection
            vehicle={vehicle}
            services={services}
          />
          <div className="basic-single-report-message">
            <p>* La información mostrada en este informe fue encontrada en los distintos sitios públicos del Gobierno.</p>
            <p>** El reporte de robo incluye: PGJ, OCRA y RAPI.</p>
          </div>
          <SingleReportPremiumSection payment={true}/>
        </div>
      </div>
      <BeforeExitMessage />
    </>
  );
};

export default BasicSingleReportPage;
