import { useState, useEffect, useContext } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "../../../contexts";
import "./PaymentAlertaDataSection.css";

const PaymentAlertaDataSection = ({ price, amount, yearsState }) => {
  const { app: { vehicles } } = useContext(AppContext);
  const totalVehicles = vehicles.length;
  const [years, setYears] = yearsState;
  const [plates, setPlates] = useState({})

  useEffect(() => {
    let list = {};
    vehicles.forEach(({ estate, plate }) => {
      list[estate] = estate in list ? [...list[estate], plate] : [plate]
    })
    setPlates(list);
  }, [])

  return (
    <>
      <Grid container>
        <Grid item>
          <NumberFormat
            className="payment-data-amount"
            value={amount}
            displayType={'text'}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </Grid>
        <Grid item>
          <TextField
            className="payment-alerta-data-years"
            select
            value={years}
            onChange={(e) => setYears(e.target.value)}
            variant="outlined"
            sx={{
              padding: '0px',
              '& .MuiInputBase-input': {
                padding: '5px 10px'
              }
            }}
          >
            {[1, 2, 3, 4, 5].map((name) => (
              <MenuItem key={name} value={name}>
                {name + ' Año'}{name != 1 && 's'}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <p className="payment-data-description">
        Suscripción Anual - ${price} x {totalVehicles} Alerta{totalVehicles > 1 && 's'}
      </p>
      <p className="payment-data-iva">
        IVA Incluido. MXN
      </p>
      <div className="payment-alerta-plates-container">
        {
          Object.entries(plates).map((list) => {
            const estate = list[0];
            const plates = list[1];
            return (
              <div className="plates-estate-container">
                <p className="plates-estate-title">
                  Placas {estate.includes('Ciudad') ? 'de la' : 'del'} {estate}
                </p>
                <Grid container spacing={2} flexDirection={'row'}>
                  {
                    plates.map((plate) => (
                      <Grid item xs={4}>
                        <FontAwesomeIcon className='plates-success-icon' icon='check' />
                        <span className="plates-text">{plate}</span>
                      </Grid>
                    ))
                  }
                </Grid>
              </div>)
          })
        }
      </div>
    </>
  );
};

export default PaymentAlertaDataSection;
