import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './LimitVehiclesByEstateDialog.css';

const LimitVehiclesByEstateDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="limit-vehicle-estate-dialog"
    >
      <div className="limit-vehicle-estate-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.CARS} alt="" />
          </Grid>
          <Grid item >
            <p className="limit-vehicle-estate-dialog-title">¿Tienes una Flotilla?</p>
          </Grid>
          <Grid item >
            <p className="limit-vehicle-estate-dialog-subtitle">Si tienes más de 10 vehículos, contáctanos para darte un mejor precio.</p>
          </Grid>
          <Grid className="limit-vehicle-estate-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onClose}>
              <p className="alerta-purple-button-text">Enterado</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default LimitVehiclesByEstateDialog
