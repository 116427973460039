export function getYears(start, end) {
  var list = [];
  for (var i = start; i <= end; i++) {
    list.push(i);
  }
  return list.reverse();
}

export function daysInMonth(month, year) {
  return Array(new Date(year, month, 0).getDate()).fill().map((v, i) => i + 1)
};

export function scrollToElement(element, offset = 0) {
  if (element && element.current)
    window.scrollBy({ behavior: "smooth", top: element.current.offsetTop - window.scrollY - offset })
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function toEstateTitleCase(str) {
  return toTitleCase(str).replace(
    [' De '],
    [' de ']
  );
}

export function getGummedColor(termination) {
  termination = termination.replace('ó', 'o');
  const terminations = {
    '5 o 6': '#FDDC02',
    '7 o 8': '#F05DAD',
    '3 o 4': '#F54065',
    '1 o 2': '#94D601',
    '9 o 0': '#54B8FC',
  }
  return terminations[termination];
}