import { useState, useContext, useEffect } from "react";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import parse from "html-react-parser";
import { AppContext } from "../contexts";
import { appTypes, userTypes } from "../reducers/types";
import { useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RegisterDataContactPage.css";
import { isMobile } from "react-device-detect";
import { updateUser } from "../services/UserServices";
import moment from "moment";
import Moment from "react-moment";
import BeforeExitMessage from "../helpers/BeforeExitMessage";

const RegisterDataContactPage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.register_data_contact);
  const [showMessage, setShowMessage] = useState(false);
  const [activateSpinner, setActivateSpinner] = useState(false);
  const [activateButton, setActivateButton] = useState(false);
  const { handleSubmit, control, setError, setValue } = useForm({ defaultValues: { email: '', phone: '' } })
  const watcher = useWatch({ control });
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        subtitle: texts.subtitle_desktop,
        back: () => history.push('/register_car')
      }
    })
    setValue('email', user?.email || '');
    setValue('phone', user?.phone || '');
  }, [])

  useEffect(() => {
    const { email, phone } = watcher;
    setActivateButton(email != '' && phone.trim().length == 10)
  }, [watcher])

  const onSubmit = async (userData) => {
    setShowMessage(false);
    setActivateSpinner(true);
    const { success, message } = await updateUser(user.id, userData);
    if (success) {
      const attemps = user.emailAttemps ? user.emailAttemps + 1 : 1;
      dispatch({
        type: userTypes.updateUser,
        payload: {
          emailAttemps: attemps,
          timeToResendEmail: attemps >= 3 ? moment().add(1, 'm') : null
        }
      })
      dispatch({
        type: userTypes.updateUser,
        payload: userData
      })
      history.push("/email_validation_code");
    } else {
      setShowMessage(true);
      if (message == 'email_exists')
        setError("email", {
          message: "El correo electrónico ya está registrado en una cuenta"
        });
      else if (message == 'phone_exists')
        setError("phone", {
          message: "El número celular ya está registrado en una cuenta"
        });
      else
        setError("email", {
          message: "Ha ocurrido un error en el registro, verifica tu conexión y vuelve a intentarlo"
        });
    }
    setActivateSpinner(false);
  };

  const onHandlerTimer = (time) => {
    if (time == '00') {
      dispatch({
        type: userTypes.updateUser,
        payload: {
          emailAttemps: 0,
          timeToResendEmail: null
        }
      })
    }
  }
  const toFilterTime = (time) => {
    return time.replace('-', '');
  }

  return (
    <div className="register-data-contact-container">
      <div style={{ position: "relative" }}>
        <form onSubmit={handleSubmit(onSubmit)} className="register-data-contact-form-container">
          <div className="register-data-contact-content-input">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes llenar el campo de 'Correo Electrónico'",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'El correo electrónico no es valido'
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  label="Correo electrónico"
                  variant="outlined"
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
          </div>
          <div className="register-data-contact-content-input">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes llenar el campo de 'Número celular'",
                pattern: {
                  value: /^\d{10}$/,
                  message: 'El número celular no es válido'
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberFormat
                  customInput={TextField}
                  format="##########"
                  label="Número celular (10 dígitos)"
                  variant="outlined"
                  fullWidth
                  autoFocus={!isMobile}
                  value={value}
                  onChange={onChange}
                  inputProps={{ inputMode: "tel", }}
                  error={!!error}
                  helperText={error && <span><FontAwesomeIcon icon='exclamation-circle' /> {error.message}</span>}
                />
              )}
            />
            {
              showMessage && (
                <div className="register-data-contact-find-alerts-container">
                  <div>
                    <p className="register-data-contact-find-alerts-p-title">
                      {parse(texts.note_title_desktop)}
                    </p>

                    <p className="register-data-contact-find-alerts-p-subtitle">
                      {parse(texts.note_body_desktop)}
                    </p>
                  </div>
                  <div className="register-data-contact-find-alerts-container-button">
                    <button className="register-data-contact-find-alerts-button">
                      {parse(texts.note_button_desktop)}
                    </button>
                  </div>
                </div>
              )
            }
          </div>
          <div className="register-data-remminder">
            <p>{parse(texts.reminder_tag)}</p>
          </div>
          <div className="register-data-contact-button-container">
            {
              user.timeToResendEmail ? (
                <div style={{ color: '#434362', fontSize: '14px' }}>
                  Solicita otro envío en
                  <Moment durationFromNow trim style={{ margin: '0px 3px' }}
                    format="mm:ss"
                    interval={1000}
                    onChange={onHandlerTimer}
                    filter={toFilterTime}
                  >{user.timeToResendEmail}</Moment>
                  segs
                </div>
              ) : (
                <Button disabled={!activateButton} className="register-data-contact-button" type="submit" >
                  <span className="register-data-contact-button-text">
                    Siguiente
                  </span>
                  {
                    activateSpinner && (
                      <span className="register-data-contact-button-progress">
                        <CircularProgress size={30} color='inherit' />
                      </span>
                    )
                  }
                </Button>
              )
            }
          </div>
        </form>
      </div>
      <BeforeExitMessage />
    </div>
  );
};

export default RegisterDataContactPage;
