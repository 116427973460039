import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './DigitalPaymentDialog.css';

const DigitalPaymentDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="digital-payment-dialog"
    >
      <div className="digital-payment-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.DIGITAL_PAYMENT} alt="" />
          </Grid>
          <Grid item >
            <p className="digital-payment-dialog-title">Pagos con Tarjetas Digitales</p>
          </Grid>
          <Grid item >
            <p className="digital-payment-dialog-subtitle">Algunos Bancos solamente permiten pagos con sus tarjetas digitales. Te sugerimos utilices este método de pago.  </p>
          </Grid>
          <Grid className="digital-payment-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onClose}>
              <p className="alerta-purple-button-text">Enterado</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default DigitalPaymentDialog
