import { useContext, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import "./ContractAlertPage.css";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import { Redirect } from "react-router";

const ContractAlertPage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  if (user && user.registered) {
    dispatch({ type: appTypes.setAddingVehicles });
    return (<Redirect to="/find_estate" />)
  }
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "<span>¡Hola!</span><br>¿Tienes vehículos registrados con nosotros?",
        back: () => history.push('/')
      }
    })

  }, [])

  return (
    <div className="register-container">
      <Grid container spacing={1} item xs={12} className="txt-center">
        <Grid item xs={12} className="mrg-15">
          <Button
            className="mrg-15"
            onClick={() => {
              history.push("/login");
            }}
            fullWidth
          >
            <span>SI</span>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => {
              history.push("/suscriber");
            }}
          >
            <span>NO</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContractAlertPage;
