import { useContext, useEffect, useRef, useState } from 'react';
import './ServiceMainSection.css';
import UpdateVerificationServiceMainSection from './UpdateVerificationServiceMainSection';
import './VerificationServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { setVerificationVehicle, updateVehicle } from '../../services/VehicleServices';
import { vehicleTypes } from '../../reducers/types';
import { AppContext } from '../../contexts';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHeight from '../../hooks/useHeight';
const verificentros = {
  'CIUDAD DE MÉXICO': 'https://verificentros.sedema.cdmx.gob.mx/DVC/',
  'ESTADO DE MÉXICO': 'https://sma.edomex.gob.mx/directorio-verificentros-talleres-pirec'
}

const VerificationServiceMainSection = ({ vehicle }) => {
  let { dispatch } = useContext(AppContext);
  const { id, estatePlate, services: {
    verificacion: { hologram, first_period, second_period, template_description, first, second, first_period_months, second_period_months } }
  } = vehicle;

  const methods = useForm();
  const watch = useWatch({ control: methods.control, name: ['hologram', 'hologramMonth', 'hologramYear'] });
  const [update, setUpdate] = useState(false)
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [verificated, setVerificated] = useState(false)
  const [penality, setPenality] = useState(false)
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [ref, height] = useHeight();

  const firstPeriod = first == null ? true : first;
  const secondPeriod = second == null ? true : second;
  const inFirstPeriod = first_period_months.includes(moment().month() + 1);
  const inSecondPeriod = second_period_months.includes(moment().month() + 1);

  useEffect(() => {
    const [hologram, hologramMonth, hologramYear] = watch;
    if (hologram == 'exempt')
      setDisableUpdate(false)
    else
      setDisableUpdate(!(hologram && hologramMonth && hologramYear && true))
  }, [watch])

  const hadlerSetVerification = () => {
    setShowUpdateButton(true)
    setUpdate(true);
    setVerificated(true);
  }

  const handlerEdit = () => {
    setShowUpdateButton(true)
    setUpdate(true);
  }

  const handlerUpdate = () => {
    methods.handleSubmit(onSubmit)();
  }

  const handlerBack = () => {
    setShowUpdateButton(false)
    setUpdate(false);
    setVerificated(false);
  }

  const onSubmit = async (form) => {
    let { hologram, hologramMonth, hologramYear } = form;
    if (hologram == 'exempt') {
      hologramMonth = null;
      hologramYear = null;
    } else {
      if (!(hologram && hologramMonth && hologramYear))
        return;
    }
    const { success, data, message } = await updateVehicle(id, form);
    if (success) {
      dispatch({
        type: vehicleTypes.updateVehicle,
        payload: data
      })
      if (verificated) {
        handlerSetVerificated()
      } else {
        setUpdate(false);
        setShowUpdateButton(false);
        methods.reset()
      }
    } else {
      console.error(message);
    }
  }

  const handlerSetVerificated = async () => {
    const { success, message } = await setVerificationVehicle(id);
    if (success) {
      dispatch({
        type: vehicleTypes.updateVerification,
        payload: {
          id,
          verificacion: {
            first: true,
            second: true
          }
        }
      })
      setUpdate(false);
      setVerificated(false);
      setShowUpdateButton(false);
      methods.reset()
    } else {
      console.error(message);
    }
  }

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Verificación</span>
        {
          ((firstPeriod && secondPeriod) || verificated) && (
            <div>
              {
                showUpdateButton ? (
                  <>
                    <button className='back' onClick={handlerBack}>
                      Regresar
                    </button>
                    <button className='update' disabled={disableUpdate} onClick={handlerUpdate}>
                      Actualizar
                    </button>
                  </>
                ) : (
                  <button onClick={handlerEdit}>Editar</button>
                )
              }
            </div>
          )
        }
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          update ? (
            <FormProvider {...methods}>
              <UpdateVerificationServiceMainSection />
            </FormProvider>
          ) : (
            <>
              <div className="service-main-content">
                <div className="content-section">
                  <span className="main-label">Holograma</span>
                  <span className="main-variable">{hologram == 'exempt' ? 'Exento' : hologram}</span>
                </div>
                {
                  hologram != 'exempt' && (
                    <>
                      <div className="content-section">
                        <span className="main-label">1er Periodo de verificación</span>
                        <span className={`main-variable ${!firstPeriod ? 'verification-in-period' : ''}`}>
                          {first == true && <><FontAwesomeIcon size='lg' icon="check-circle" /> Verificado</>}
                          {first == false && (inFirstPeriod ? first_period : <><FontAwesomeIcon size='lg' icon="exclamation-circle" /> Vencido</>)}
                          {first == null && first_period}
                        </span>
                      </div>
                      <div className="content-section">
                        <span className="main-label">2do Periodo de verificación</span>
                        <span className={`main-variable ${!secondPeriod ? 'verification-in-period' : ''}`}>
                          {second == true && <><FontAwesomeIcon size='lg' icon="check-circle" /> Verificado</>}
                          {second == false && (inSecondPeriod ? second_period : <><FontAwesomeIcon size='lg' icon="exclamation-circle" /> Vencido</>)}
                          {second == null && second_period}
                        </span>
                      </div>
                    </>
                  )
                }
                {
                  penality ? (
                    <div className="verification-penality-section">
                      <div className="service-main-title">
                        <span>Multa por verificación extemporánea</span>
                      </div>
                      <div className="content-section">
                        <span className="main-label">Importe</span>
                        <span className="main-variable">$1,792.40</span>
                      </div>
                      <div className="content-section">
                        <span className="main-label">Línea de Captura</span>
                        <span className="main-variable">505XXXXD10AGDEY41T6R</span>
                      </div>
                      <div className="content-section">
                        <span className="main-label">Válida hasta el</span>
                        <span className="main-variable">16 de diciembre del 2021</span>
                      </div>
                      <div className="content-section verification-done-button-container">
                        <button className="main-button" onClick={() => setPenality(!penality)}>Formato de Pago</button>
                      </div>
                      <div className="content-section">
                        <span className="main-label verification-label verification-penality">
                          <b>Nota:</b> Posterior al pago de la multa, el vehículo únicamente podrá circular a un
                          verificentro después de dos días hábiles a que el pago se haya realizado, y verificar la
                          unidad en un plazo no mayor a 30 días naturales contados a partir del pago de la misma.
                        </span>
                      </div>
                      {
                        !(firstPeriod && secondPeriod) &&
                        <div className="content-section verification-done-button-container">
                          <button className="main-button" onClick={hadlerSetVerification}>Ya realicé mi Verificación</button>
                        </div>
                      }
                    </div>
                  ) : (
                    !(firstPeriod && secondPeriod) &&
                    <div className="content-section verification-done-button-container">
                      <button className="main-button" onClick={hadlerSetVerification}>Ya realicé mi Verificación</button>
                    </div>
                  )
                }
              </div>
              <div className="info-content-section">
                <p className="info-title">Directorio de Verificentros</p>
                <div className="content-section">
                  <span className="main-label verification-label">Para agendar una cita, consulta el directorio de Verificentros y los documentos que debes de tener vigentes.</span>
                </div>
                <div className="content-section">
                  <button className="info-button" onClick={() => window.open(verificentros[estatePlate], '_blank')}>Directorio de Verificentros</button>
                </div>
              </div>
              <div className="service-main-footer">
                <MarkdownPreview source={template_description} />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default VerificationServiceMainSection
