import { URL_API } from "../util/Configuration";
import { service } from "./helpers/serviceConfig"

export const getBlogList = (skip = 0, limit = 20) => {
  return service('get', `${URL_API}/blog/list?skip=${skip}&limit=${limit}`, {})
}

export const addLike = (id) => {
  return service('get', `${URL_API}/blog/${id}/like`, {})
}

export const addShare = (id) => {
  return service('get', `${URL_API}/blog/${id}/share`, {})
}
