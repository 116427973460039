import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid } from "@mui/material"

const ServiceErrorSection = ({ type }) => {
  return (
    type == 'main' ?
      (
        <>
          <div className="alert-content-section" style={{ marginTop: '20px' }}>
            <p className="alert-title">
              <div>
                <FontAwesomeIcon icon="times-circle" size="lg" style={{marginRight: '10px'}} />
                <span>
                  El servicio no responde, se actualizará automáticamente en pocos minutos.
                </span>
              </div>
            </p>
          </div>
          <div className="info-content-section">
            <p className="info-title">
              ¿Porqué puede pasar esto?
            </p>
            <div className="content-section">
              <span className="main-label">
                Cuando el sitio de gobierno de donde viene la información falla, la conexión a nuestro sistema también falla.
              </span>
            </div>
            <div className="content-section">
              <span className="main-label">
                Desarrollamos tecnología para que se actualice en poco tiempo y al momento de que el sitio de gobierno vuelva a funcionar, nosotros actualizamos la información de este servicio.
              </span>
            </div>
          </div>
          <div className="content-section">
            <span className="main-label" style={{ textAlign: 'center', fontSize: '10px' }}>
              Si tienes dudas o te gustaría obtener más información, escríbenos a: <a href="mailto:hola@alertamiauto.com">hola@alertamiauto.com</a> y con mucho gusto te ayudamos.
            </span>
          </div>
        </>
      )
      : (
        <div className="without-info-container">
          <p className='title'>
            El servicio no responde, se actualizará en pocos minutos.
          </p>
        </div>
      )
  )
}

export default ServiceErrorSection