import { Grid } from "@mui/material";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import alertaServices from "../../../data/alertaServices";
import { Images } from "../../../util";
import "./PaymentPremiumReportDataSection.css";

const PaymentPremiumReportDataSection = ({ amount }) => {
  return (
    <div>
      <Grid container>
        <Grid item>
          <NumberFormat
            className="payment-data-amount"
            value={amount}
            displayType={'text'}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </Grid>
      </Grid>
      <p className="payment-data-description">
        <span>Informe Vehicular Premium</span>
        <img src={Images.PREMIUM_CROW} alt="Corona" />
      </p>
      <p className="payment-data-iva">
        IVA Incluido. MXN
      </p>
      <div className="payment-report-services-container">
        <Grid container spacing={1} >
          {
            alertaServices.map(({ name }) => (
              <Grid item xs={12}>
                <FontAwesomeIcon className='success-icon' icon='check' />
                <span>{name}</span>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default PaymentPremiumReportDataSection;
