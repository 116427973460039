import { URL_API } from "../util/Configuration"
import { service } from "./helpers/serviceConfig"

const getSingleReportToken = () => {
  const { singleReport } = JSON.parse(localStorage.getItem('appStorage')) || {};
  return singleReport?.token;
}

export const checkSingleReport = (data) => {
  return service('post', `${URL_API}/vehicle/report/check`, {
    auth: false,
    body: data
  })
}

export const registerSingleReportByPlate = (plate, email) => {
  return service('post', `${URL_API}/vehicle/report/plate/record`, {
    auth: false,
    body: {
      plate,
      email
    }
  })
}

export const registerSingleReportByNIV = (niv, email) => {
  return service('post', `${URL_API}/vehicle/report/niv/record`, {
    auth: false,
    body: {
      niv,
      email
    }
  })
}

export const sendBasicSingleReportEmail = (vehicleId) => {
  return service('post', `${URL_API}/vehicle/report/email`, {
    auth: true,
    body: {
      id: vehicleId
    },
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportMedioAmbiente = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/medioambiente`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportRobberyReport = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/robberyreport`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportTarjetaCirculacion = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/tarjetacirculacion`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportPlaterenewal = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/platerenewal`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportInfracciones = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/infracciones`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportTenenciaRefrendo = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/tenenciarefrendo`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportFotocivica = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status/fotocivica`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportBasic = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report/status`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}

export const getSingleReportPremium = (vehicleId) => {
  return service('get', `${URL_API}/vehicle/${vehicleId}/report`, {
    auth: true,
    config: { token: getSingleReportToken() }
  })
}


export const createCreditCarPaymentReport = async (userId, body) => {
  return service('post', `${URL_API}/payment/report/card/token/user/${userId}`, {
    auth: true,
    config: { token: getSingleReportToken() },
    body
  })  
}

export const createOxxoPaymentReport = async (userId, body) => {
  return service('post', `${URL_API}/payment/report/oxxo/user/${userId}`, {
    auth: true,
    config: { token: getSingleReportToken() },
    body
  })  
}

export const createPaypalPaymentReport = async (userId, body) => {
  return service('post', `${URL_API}/payment/report/paypal/user/${userId}`, {
    auth: true,
    config: { token: getSingleReportToken() },
    body
  })  
}

export const createCouponPaymentReport = async (userId, body) => {
  return service('post', `${URL_API}/payment/report/coupon/user/${userId}`, {
    auth: true,
    config: { token: getSingleReportToken() },
    body
  })  
}