import { getSingleReportFotocivica, getSingleReportInfracciones, getSingleReportMedioAmbiente, getSingleReportPlaterenewal, getSingleReportRobberyReport, getSingleReportTarjetaCirculacion, getSingleReportTenenciaRefrendo } from "../services/singleReportService";

const alertaServices = [
  {
    name: 'Pago de Tenencia',
    status: ['Sin adeudos', 'Con adeudos'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportTenenciaRefrendo(vehicleId);
      // console.log('Pago de Tenencia', data);
      return { success, value: data?.tenencia_refrendo }
    },

  },
  {
    name: 'Infracciones',
    status: ['Sin infracciones', 'Con infracciones'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportInfracciones(vehicleId);
      // console.log('Infracciones', data);
      return { success, value: data?.multa_transito }
    },
  },
  {
    name: 'FotoCívicas',
    status: ['Sin infracciones', 'Con infracciones'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportFotocivica(vehicleId);
      // console.log('FotoCívicas', data);
      return { success, value: data?.fotocivica }
    },
  },
  {
    name: 'Sanción ambiental',
    status: ['Sin sanciones', 'Con sanciones'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportMedioAmbiente(vehicleId);
      // console.log('Sanción ambiental', data);
      return { success, value: data?.medioambiente }
    },
  },
  {
    name: 'Tarjeta de circulación',
    status: ['Vigente', 'Vencida'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportTarjetaCirculacion(vehicleId);
      // console.log('Tarjeta de circulación', data);
      return { success, value: data?.tarjeta_circulacion }
    },
  },
  {
    name: 'Renovación de placas',
    status: ['Vigente', 'Vencida'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportPlaterenewal(vehicleId);
      // console.log('Renovación de placas', data);
      return { success, value: data?.plate_renewal }
    },
  },
  {
    name: 'Reporte de robo',
    status: ['Sin reporte', 'Con reporte'],
    service: async (vehicleId) => {
      const { success, data } = await getSingleReportRobberyReport(vehicleId);
      // console.log('Reporte de robo', data);
      return { success, value: data?.robbery_report }
    },
  },
]

export default alertaServices;