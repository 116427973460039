import PropTypes from "prop-types";
import { Fade } from "react-awesome-reveal";

import "./index.css";

var parse = require("html-react-parser");

export const Card_Step_Molecules = ({
  step,
  mode,
  isMobile
}) => {
  return (
    <>
      {mode === "box" ? (
        <Fade direction="up" triggerOnce className="wrap-card-step-molecules-container" >
          <div className="wrap-card-step-molecules" style={{width: '100%'}}>
            <div className="content-card-step-molecules" style={{width: '100%'}}>
              <img className="img-card-step" src={step.img_url} />
              <p className="title-card-step">
                {isMobile ? parse(step.title_mobile) : parse(step.title)}
              </p>
              <p className="message-card-step">
                {isMobile
                  ? parse(step.message_mobile)
                  : parse(step.message ? step.message : "")}
              </p>
            </div>
          </div>
        </Fade>
      ) : (
        <Fade direction="up" triggerOnce>
          <div className="wrap-card-step-molecules-circle">
            <div className="content-card-step-molecules-circle">
              <img className="img-card-step-circle" src={step.img_url} />
              <p className="title-card-step-circle">
                {isMobile ? parse(step.title_mobile) : parse(step.title)}
              </p>
            </div>
          </div>
        </Fade>
      )
      }

    </>
  );
};

Card_Step_Molecules.propTypes = {
  /**
   * Object step for text
   */
  step: PropTypes.object,
  /**
   * Array payments
   */
  payments: PropTypes.array,
  /**
   * height card
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * width card
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Flag is movile
   */
  isMobile: PropTypes.bool,
};

Card_Step_Molecules.defaultProps = {
  step: {},
  payments: [],
  height: 490,
  width: 370,
  isMobile: false,
};
