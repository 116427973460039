import React from "react";
import PropTypes from "prop-types";

import "./index.css";

import { Images } from "../../../util";
var parse = require("html-react-parser");

export const Steps_Payment_Oxxo_Molecules = ({ step, device }) => {
  return (
    <div className="steps-payment-oxxo-container">
      <p className="title-card-step">
        <span style={{ backgroundImage: `url(${Images.CIRCLES_STEPS})` }}>
          {step.number}
        </span>
        {device === "desktop"
          ? parse(step.message_desktop)
          : parse(step.message_mobile)}
      </p>
    </div>
  );
};

Steps_Payment_Oxxo_Molecules.propTypes = {
  /**
   * Object step for text
   */
  step: PropTypes.object,
  /**
   * Array payments
   */
  payments: PropTypes.array,
  /**
   * height card
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * width card
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Flag is movile
   */
  isMobile: PropTypes.bool,
};

Steps_Payment_Oxxo_Molecules.defaultProps = {
  step: {},
  payments: [],
  height: 490,
  width: 370,
  isMobile: false,
};
