import { useSelector } from "react-redux";
import parse from "html-react-parser";

import { Images } from "../util";
import "./EmailValidationCodePage.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts";
import { appTypes, userTypes } from "../reducers/types";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { sendVerificationEmail } from "../services/UserServices";
import BeforeExitMessage from "../helpers/BeforeExitMessage";

export const EmailValidationCodePage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.email_validation_code);
  const [activateSpinner, setActivateSpinner] = useState(false);
  const { handleSubmit } = useForm()

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        back: () => history.push('/register_data_contact')
      }
    })
  }, [])

  const onSubmit = async () => {
    setActivateSpinner(true);
    const { success } = await sendVerificationEmail(user.id);
    if (success) {
      history.push('/confirm_code')
    }
    setActivateSpinner(false);
  };

  return (
    <div className="email-validation-code-container">
      <div className="email-validation-code-content-email">
        <p>{user.email}</p>
      </div>
      <div className="email-validation-code-content-subtitle">
        <p>{parse(texts.subtitle_desktop)}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="email-validation-code-form-container">
        <div className="email-validation-code-button-container">
          <Button className="email-validation-code-button" type="submit" >
            <span className="email-validation-code-button-text">
              Enviar Correo
            </span>
            {
              activateSpinner && (
                <span className="email-validation-code-button-progress">
                  <CircularProgress size={30} color='inherit' />
                </span>
              )
            }
          </Button>
        </div>
      </form>
      <div className="email-validation-code-content-link" onClick={() => history.push('/register_data_contact')}>
        <img
          className="email-validation-code-img"
          src={Images.ARROW_LEFT}
        ></img>
        <p className="email-validation-code-link">{texts.link}</p>
      </div>
      <BeforeExitMessage />
    </div>
  );
};

export default EmailValidationCodePage;
