import './ServiceMainSection.css';
import './FotocivicasServiceMainSection.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import useHeight from '../../hooks/useHeight';
import FotocivicasServiceSection from './fotocivicas/FotocivicasServiceSection';

const FotocivicasServiceMainSection = ({ vehicle, foreigner }) => {
  const [ref, height] = useHeight();
  let { services: { fotocivica } } = vehicle;
  const puntos = fotocivica.puntos || 0;
  const infracciones = 10 - puntos < 0 ? abs(10 - puntos) + 10 : 10 - puntos;

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Programa de FotoCívicas (CDMX)</span>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        <div className="service-main-footer">
          {
            foreigner ? (
              <>
                <div className="alert-content-section" style={{ marginBottom: '10px', marginTop: '20px' }}>
                  <p className="alert-title">¡Tu placa no está registrada en el sistema de la Ciudad de México!</p>
                </div>
                <div className="content-section">
                  <span className="main-label verification-label" style={{ fontSize: '10px', padding: '0px 20px' }}>
                    Recuerda que el programa de FotoCívicas solo aplica para las placas de la Ciudad de México.
                    Si crees que hay un error, escribenos un correo a <a href="mailto:hola@alertamiauto.com">hola@alertamiauto.com</a>
                  </span>
                </div>
              </>
            ) : (
              puntos == 0 ? (
                <div className="alert-content-section" style={{ marginBottom: '10px' }}>
                  <p className="alert-title">¡No hemos encontrado tu placa en el programa de FotoCívicas</p>
                </div>
              ) : (
                <FotocivicasServiceSection puntos={puntos} infracciones={infracciones} />
              )
            )
          }
          <MarkdownPreview source={fotocivica.template_description} />
        </div>
      </div>
    </div >
  )
}

export default FotocivicasServiceMainSection
