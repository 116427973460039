import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { useState } from "react";
import { Animated } from "react-animated-css";
import { Images } from "../../../util";
import "./PremiumSingleReportSection.css";
import '../ServiceMainSection.css';

const PremiumSingleReportSection = ({ services }) => {
  return (
    <div className="premium-report-container">
      {
        services.filter(({ value }) => value != null).map(({ name, value, data }) => (
          <>
            <PremiumServiceTitle name={name} value={value} />
            {
              data.filter((item) => item != null).map(({ title, collapsable, sections }) => (
                <PremiumServiceSubtitle
                  title={title}
                  collapsable={collapsable}
                  sections={sections}
                />
              ))
            }
          </>
        ))
      }
    </div>
  );
};

const PremiumServiceTitle = ({ name, value }) => {
  return (
    <div className={`premium-report-service-container`}>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item style={{ paddingLeft: '5px', display: 'flex' }}>
          <img className='premium-report-service-image' src={Images.ALERTA_MI_AUTO_GRAY} alt="alertamiauto logo" />
        </Grid>
        <Grid item style={{ margin: '0 auto 0 20px' }}>
          <span className='premium-report-service-name'>{name}</span>
        </Grid>
        <Grid item style={{ paddingRight: '5px' }}>
          {
            value == null ? '' : value ? (
              <FontAwesomeIcon className='premium-report-service-icon-success' icon='check' />
            ) : (
              <FontAwesomeIcon className='premium-report-service-icon-danger' icon='times-circle' />
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

const PremiumServiceSubtitle = ({ title, collapsable, sections }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {
        title && (
          <div className={`premium-service-title ${collapsable ? 'collapsable' : ''}`} onClick={() => setOpen(!open)}>
            {title}
            {
              collapsable && <FontAwesomeIcon className="icon" icon={`caret-${open ? 'down' : 'right'}`} size="lg" />
            }
          </div>
        )
      }
      {
        collapsable ? (
          <div className="premium-service-section-animate">
            <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0} isVisible={open} >
              {
                sections.map(({ title, collapsable, section }) => (
                  <PremiumServiceSection
                    title={title}
                    collapsable={collapsable}
                    section={section}
                  />
                ))
              }
            </Animated>
          </div>
        ) : (
          sections.map(({ title, collapsable, section }) => (
            <PremiumServiceSection
              title={title}
              collapsable={collapsable}
              section={section}
            />
          ))
        )
      }
    </>
  )
}

const PremiumServiceSection = ({ collapsable, title, section }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="premium-service-section">
      {
        title && (
          <div className={`premium-service-subtitle ${collapsable ? 'collapsable' : ''}`} onClick={() => setOpen(!open)}>
            {title}
            {
              collapsable && <FontAwesomeIcon className="icon" icon={`caret-${open ? 'down' : 'right'}`} size="lg" />
            }
          </div>
        )
      }
      {
        collapsable ? (
          <div className="premium-service-section-animate service-main-section">
            <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={0} isVisible={open} >
              <div className="premium-service-section-container">
                {section}
              </div>
            </Animated>
          </div>
        ) : (
          <div className="service-main-section">
            {section}
          </div>
        )
      }
    </div>
  )
}

export default PremiumSingleReportSection;
