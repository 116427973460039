import { useContext, useEffect, useRef, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import { Images } from "../util";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import "./ConfirmCodePage.css";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { validateEmailCode } from "../services/UserServices";
import BeforeExitMessage from "../helpers/BeforeExitMessage";

const ConfirmCodePage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);
  const texts = useSelector((store) => store.page.register_flow.confirm_code);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      code0: '', code1: '', code2: '', code3: ''
    }
  });
  const [validating, setValidating] = useState(false);

  const inputRefs = [0, 1, 2, 3].map((position) => {
    const code = useWatch({ control, name: `code${position}` });
    useEffect(() => {
      if (code) {
        if (!Number.isInteger(parseInt(code))) {
          setValue(`code${position}`, '')
          return;
        }
        if (position == 0 && code?.length > 1) {
          code.split('').map((val, position) => setValue(`code${position}`, val))
          return;
        } else if (code?.length > 1) {
          setValue(`code${position}`, code.slice(-1))
          return;
        }
        const currentCode = inputRefs.map((input) => input.current.value).join('');
        if (currentCode.length == 4)
          handleSubmit(onSubmit)();
        else
          inputRefs[position == 3 ? 0 : position + 1].current.focus();
      }
    }, [code])
    return useRef()
  })

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: user && user.registered,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: texts.title_desktop,
        subtitle: texts.subtitle_desktop + '<span>' + user.email + '</span><br/><br/>Revisa tu bandeja de SPAM si no te llega.',
        back: () => history.push('/email_validation_code')
      }
    })
  }, [])

  const onSubmit = async (inputs) => {
    const code = Object.values(inputs).join('');
    inputRefs.map((input) => input.current.disabled = true)
    setValidating(true);
    const { success, message } = await validateEmailCode({ id: user.id, code });
    if (success) {
      history.push("/payment");
    } else if (message == 'invalid_code') {
      reset()
      inputRefs.map((input) => input.current.disabled = false)
    }
    setValidating(false);
  };

  return (
    <div className="confirm-code-container">
      <form className="confirm-code-form-container" onSubmit={handleSubmit(onSubmit)}>
        {
          inputRefs.map((ref, i) => (
            <div key={`code${i}`} className="confirm-code-form-content-code">
              <Controller
                name={`code${i}`}
                control={control}
                defaultValue=""
                rules={{ maxLength: 1 }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    className="confirm-code-form-code"
                    variant="outlined"
                    autoFocus={!isMobile && i == 0}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    inputProps={{ inputMode: "decimal", }}
                  />
                )}
              />
            </div>
          ))
        }
      </form>
      <div className="confirm-code-validating-container">
        {
          validating && (
            <span className="confirm-code-validating">
              <CircularProgress size={30} />
            </span>
          )
        }
      </div>
      <div className="confirm-code-form-container-link" onClick={() => history.push("/register_data_contact")}>
        <p className="confirm-code-form-link">{texts.link}</p>
        <img className="confirm-code-form-img" src={Images.ARROW_RIGHT} />
      </div>
      <BeforeExitMessage />
    </div>
  );
};

export default ConfirmCodePage;
