import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './ConfirmRemoveAdditionalUserDialog.css';

const ConfirmRemoveAdditionalUserDialog = ({ state: { open, data = [] }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="confirm-remove-additional-user-dialog"
    >
      <div className="confirm-remove-additional-user-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item xs={12} container justifyContent="flex-end">
            <img className="close-icon" onClick={onClose} src={Images.DIALOG_CLOSE_ICON} />
          </Grid>
          <Grid item >
            <p className="confirm-remove-additional-user-dialog-title">
              ¿Estás seguro que deseas eliminar a este usuario adicional?
            </p>
          </Grid>
          <Grid item >
            <p className="confirm-remove-additional-user-dialog-subtitle">
              Podrás agregar un nuevo usuario adicional en cualquier momento
            </p>
          </Grid>
          <Grid className="confirm-remove-additional-user-dialog-button-container">
            <Button className="alerta-purple-button" onClick={onSuccess}>
              <p className="alerta-purple-button-text">Confirmar</p>
            </Button>
            <Button className="alerta-white-button" onClick={onClose}>
              <p className="alerta-white-button-text">Regresar</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default ConfirmRemoveAdditionalUserDialog
