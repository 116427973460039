import axios from "axios";

export const service = async (method, url, { body, auth = false, config }) => {
  config = auth ? authHeader(config) : config;
  return axios({
    method: method,
    url: url,
    data: body,
    ...config
  }).then(handlerResponse)
    .catch(handlerError);
}

export const authHeader = (config = {}) => {
  const { user } = JSON.parse(localStorage.getItem('appStorage')) || {};
  const token = config.token ? config.token : user.token
  return {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${token}`,
    }
  }
}

export const handlerResponse = (response) => {
  console.log('Success: ', response.data);
  return response.data;
}

export const handlerError = (error) => {
  if (error.response?.data) {
    console.error('Error:', error.response.data);
    return error.response.data;
  }
  if (error.isAxiosError) {
    console.error('Error:', error);
    return {
      message: error.message ? error.message : "Error"
    }
  }
  if (error.code)
    return {
      message: error.message
    }

}