import { Box, Grid, Slide, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../contexts";
import { Images } from "../../util";
import "./OpinionSection.css";

const opinions = [
  [
    {
      id: 1,
      name: 'Andrés García',
      description: '“A raíz de que contraté una Alerta, pude organizarme y ya no me multan por verificar tarde”',
      occupation: 'Arquitecto',
      image: Images.OPINION_1
    },
    {
      id: 2,
      name: 'Susana Jiménez',
      description: '“Tener una Alerta me da mucha tranquilidad cuando mi coche no circula, la última vez se lo llevaron al corralón.”',
      occupation: 'Ama de casa',
      image: Images.OPINION_2
    },
    {
      id: 3,
      name: 'Pedro Robles',
      description: '“Que buena solución digital, a partir de que tengo una Alerta los policías no me sacan mordidas.”',
      occupation: 'Emprendedor',
      image: Images.OPINION_3
    }
  ],
  [
    {
      id: 4,
      name: 'Víctor Flores',
      description: '“Se me fue la verificación de mi coche, pague más de $1700 pesos de multa. Ahora ya tengo mi Alerta.”',
      occupation: 'Ingeniero Civil',
      image: Images.OPINION_4
    },
    {
      id: 5,
      name: 'Benjamín Gómez',
      description: '“El mejor servicio para mi es que te avisen cuanto no circulas y mejor aún cuando hay doble no circula.”',
      occupation: 'Banca Privada',
      image: Images.OPINION_5
    },
    {
      id: 6,
      name: 'Adriana Vallejo R.',
      description: '“Que bueno que exista un servicio digital que te avise cuanto te pongan una infracción de tránisto.”',
      occupation: 'Estudiante',
      image: Images.OPINION_6
    }
  ],
  [
    {
      id: 7,
      name: 'Laura Patricia Hinojosa',
      description: '“Odio pagar la Tenencia, pero me ayuda tener una Alerta que me recuerde este y otros servicios para mi coche.”',
      occupation: 'Ejecutiva de Cuenta',
      image: Images.OPINION_7
    },
    {
      id: 8,
      name: 'Luis Enrique Macías',
      description: '“Me detuvo un policía y hasta ese momento me di cuenta que mi tarjeta de circulación estaba vencida.”',
      occupation: 'Profesor',
      image: Images.OPINION_8
    },
    {
      id: 9,
      name: 'Mónica del Villar',
      description: '“Ya hacía falta tener un servicio en México que te avise si tienes FotoCívicas, Multas, etc.”',
      occupation: 'Artista',
      image: Images.OPINION_9
    }
  ]
]

const OpinionSection = () => {
  const { app: { home: { opinion } } } = useContext(AppContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div ref={opinion} className="home-opinion-section">
      <p className="title">Opinión de nuestros clientes</p>
      <p className="subtitle">
        Descubre los comentarios de algunos de nuestros clientes que contrataron <b>alertamiauto.com</b>
      </p>
      <Grid container flexDirection="column">
        <img className="quotes-start" src={Images.QUOTES_START} alt="Quotes Start" />
        <Box sx={{ width: '100%' }}>
          {
            opinions.map((opinion, index) => (
              <div className="opinion-panel" role="tabpanel" hidden={value !== index} id={`opinion-panel-${index}`} aria-labelledby={`opinion-tab-${index}`} >
                {
                  // value === index && (
                  <Slide direction="left" in={value === index} >
                    <Grid container spacing={3}>
                      {
                        opinion.map(({ name, description, occupation, image }) => (
                          <Grid container item xs={12} md={4} >
                            <div className="opinion-card">
                              <Grid container>
                                <Grid container item>
                                  <span className="description">{description}</span>
                                </Grid>
                                <Grid container item>
                                  <Grid container item xs={3}>
                                    <img src={image} style={{maxWidth: '50px', maxHeight: '50px', borderRadius: '25px'}}/>
                                  </Grid>
                                  <Grid container item xs={9}>
                                    <span className="name">{name}</span>
                                    <span className="occupation">{occupation}</span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Slide>

                  // )
                }
              </div>
            ))
          }
          <Box>
            <Tabs className="opinion-tabs-control" value={value} onChange={handleChange} aria-label="opinions" centered>
              {
                opinions.map((opinion, index) => (
                  <Tab className="opinion-tab-control" id={`opinion-tab-${index}`} aria-controls={`opinion-panel-${index}`} />
                ))
              }
            </Tabs>
          </Box>
        </Box>
        <img className="quotes-end" src={Images.QUOTES_END} alt="Quotes End" />
      </Grid>

    </div>
  );
};
export default OpinionSection;
