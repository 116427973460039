import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts";
import { appTypes, blogTypes } from "../reducers/types";
import { CircularProgress, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterSection from "../component/sections/FooterSection";
import "./BlogDetailPage.css";
import { useParams } from "react-router-dom";
import MarkdownPreview from "@uiw/react-markdown-preview";
import BlogArticleSection from "../component/sections/BlogArticleSection";
import { addLike, addShare } from "../services/BlogServices";
import ShareAlertaDialog from "../component/dialogs/ShareAlertaDialog";
import { Helmet } from "react-helmet";

const BlogDetailPage = ({ history }) => {
  const {
    app: { blog },
    dispatch,
  } = useContext(AppContext);
  const { articleId } = useParams();
  let { image, title, text, likes, shared, category } = blog.find(
    (article) => article.id == articleId
  );
  const [likeLoading, setLikeLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const url = 'https://www.alertamiauto.com/blog/' + articleId;
  const [showShareAlertaDialog, setShowShareAlertaDialog] = useState({
    open: false,
    data: {
      title: 'Compartir articulo',
      linkToCopy: url,
      whatsapp: {
        url: url,
        title
      },
      facebook: {
        url: url,
        quote: title,
        hashtag: '#alertamiauto'
      },
      twitter: {
        url: url,
        title: title,
        hashtags: ['multas', 'recargos', 'alertamiauto', 'cdmx'],
        related: ['@alertamiauto'],
      },
      email: {
        url: url,
        subject: 'Alertamiauto.com',
        body: title,
      }
    }
  })

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
      },
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: "",
        back: () => history.push("/blog"),
      },
    });
  }, []);

  const handlerLike = async () => {
    if (likeLoading)
      return;
    setLikeLoading(true)
    const { success } = await addLike(articleId);
    if (success)
      dispatch({
        type: blogTypes.addLike,
        payload: articleId
      });
    setLikeLoading(false)
  }

  const handlerShare = async () => {
    if (shareLoading)
      return;
    setShowShareAlertaDialog({ ...showShareAlertaDialog, open: true })
    setShareLoading(true)
    const { success } = await addShare(articleId);
    if (success)
      dispatch({
        type: blogTypes.addShare,
        payload: articleId
      });
    setShareLoading(false)
  }

  return (
    <>
      <div className="blog-page">
        <Helmet>
          <title>{`Alertamiauto: ${title}`}</title>
          <meta name="description" content={title} />
          <meta property="og:image" content={image} />
          <meta property="og:image:alt" content={title} />
          <meta property="og:site_name" content={title} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={url} />
          <meta property="og:description" content={title} />
          <meta name="twitter:image:src" content={image} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={title} />
        </Helmet>
        <div className="container">
          <div className="blog-detail-container">
            <p className="blog-detail-title">{title}</p>
            <div className="blog-detail-photo">
              <img src={image} alt="" />
            </div>
            <div className="blog-detail-action-container">
              <div className="blog-detail-action" onClick={handlerLike}>
                <FontAwesomeIcon
                  className="blog-detail-action-icon"
                  icon="heart"
                />
                <p className="blog-detail-action-title">Me Gusta</p>
                {
                  likeLoading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <p className="blog-detail-action-counter">{likes}</p>
                  )
                }
              </div>
              <div className="blog-detail-action" onClick={handlerShare}>
                <FontAwesomeIcon
                  className="blog-detail-action-icon"
                  icon="share"
                />
                <p className="blog-detail-action-title">Compatir</p>
                {
                  shareLoading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <p className="blog-detail-action-counter">{shared}</p>
                  )
                }
              </div>
            </div>
            <div className="blog-detail-text">
              <MarkdownPreview source={text} />
            </div>
          </div>
          <div className="blog-detail-additional-container">
            <p className="blog-detail-additional-title">Artículos relacionados</p>
            <Grid container spacing={4}>
              {blog
                .filter((article) => article.category == category)
                .sort(() => 0.5 - Math.random())
                .map(
                  (article, i) =>
                    i < 2 && (
                      <Grid key={`article_${article.id}`} item xs={12} md={6}>
                        <BlogArticleSection {...article} />
                      </Grid>
                    )
                )}
            </Grid>
          </div>
        </div>
        <FooterSection />
      </div>
      <ShareAlertaDialog
        state={showShareAlertaDialog}
        onClose={() => setShowShareAlertaDialog({ ...showShareAlertaDialog, open: false })}
      />
    </>
  );
};

export default BlogDetailPage;
