import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTMLReactParser from 'html-react-parser';
import { toTitleCase } from '../../../util/helpers';

const TheftReportSection = ({ service, name, footer }) => {
  if(service == undefined)
    return <></>;
  const status = service ? toTitleCase(service.value.replace('ESTADO VEHÍCULO: ', '')) : '-';
  const offence = service ? typeof service.delito == 'string' && service.delito.replace("DELITO: ", "") : '-';
  const alert = service && service.alert;
  name = name(alert);
  footer = footer(alert);
  return (
    <div className='theft-report'>
      {
        alert ? (
          <>
            <div className="service-main-title">
              <span>{HTMLReactParser(name)}</span>
            </div>
            <div className="service-main-content">
              <div className="content-section">
                <span className="main-label">Estatus del vehículo</span>
                <span className="main-variable alert">{status}</span>
              </div>
            </div>
            {
              offense && (
                <div className="service-main-content">
                  <div className="alert-content-section">
                    <span className="alert-title">
                      <b>DELITO:</b> {offense}
                    </span>
                  </div>
                </div>
              )
            }
          </>
        ) : (
          <>
            <div className="success-content-section">
              <p className="success-title">
                <span>Tu vehículo no presenta reporte de robo en {HTMLReactParser(name)}</span>
                <FontAwesomeIcon icon="check-circle" size="lg" style={{ margin: 'auto' }} />
              </p>
            </div>
            <div className="service-main-content">
              <div className="content-section">
                <span className="main-label">Estatus del vehículo</span>
                <span className="main-variable">{status}</span>
              </div>
            </div>
          </>
        )
      }
      <div className="service-main-footer">
        <p className="footer-content">
          {footer}
        </p>
        {
          alert && (
            <p className="footer-content" style={{ color: '#17788D' }}>
              ** La información contenida en alertamiauto.com es de carácter informativo y no tiene efectos legales.
            </p>
          )
        }
      </div>
    </div>
  )
}

export default TheftReportSection