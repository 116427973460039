import { useEffect, useRef, useState } from "react";

const useHeight = () => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null);

  useEffect(() => {
      setHeight(ref.current.offsetHeight - 35)
  }, [ref])

  return [ref, height];
}

export default useHeight