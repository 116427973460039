const carBrands = [
  { id: 1, name: "Acura" },
  { id: 2, name: "Alfa Romeo" },
  { id: 3, name: "AMC" },
  { id: 4, name: "Aston Martin" },
  { id: 5, name: "Audi" },
  { id: 6, name: "Avanti" },
  { id: 7, name: "Bentley" },
  { id: 8, name: "BMW" },
  { id: 9, name: "Buick" },
  { id: 10, name: "Cadillac" },
  { id: 11, name: "Chevrolet" },
  { id: 12, name: "Chrysler" },
  { id: 13, name: "Daewoo" },
  { id: 14, name: "Daihatsu" },
  { id: 15, name: "Datsun" },
  { id: 16, name: "DeLorean" },
  { id: 17, name: "Dodge" },
  { id: 18, name: "Eagle" },
  { id: 19, name: "Ferrari" },
  { id: 20, name: "FIAT" },
  { id: 21, name: "Fisker" },
  { id: 22, name: "Ford" },
  { id: 23, name: "Freightliner" },
  { id: 24, name: "Geo" },
  { id: 25, name: "GMC" },
  { id: 26, name: "Honda" },
  { id: 27, name: "HUMMER" },
  { id: 28, name: "Hyundai" },
  { id: 29, name: "Infiniti" },
  { id: 30, name: "Isuzu" },
  { id: 31, name: "Jaguar" },
  { id: 32, name: "Jeep" },
  { id: 33, name: "Kia" },
  { id: 34, name: "Lamborghini" },
  { id: 35, name: "Lancia" },
  { id: 36, name: "Land Rover" },
  { id: 37, name: "Lexus" },
  { id: 38, name: "Lincoln" },
  { id: 39, name: "Lotus" },
  { id: 40, name: "Maserati" },
  { id: 41, name: "Maybach" },
  { id: 42, name: "Mazda" },
  { id: 43, name: "McLaren" },
  { id: 44, name: "Mercedes-Benz" },
  { id: 45, name: "Mercury" },
  { id: 46, name: "Merkur" },
  { id: 47, name: "MINI" },
  { id: 48, name: "Mitsubishi" },
  { id: 49, name: "Nissan" },
  { id: 50, name: "Oldsmobile" },
  { id: 51, name: "Peugeot" },
  { id: 52, name: "Plymouth" },
  { id: 53, name: "Pontiac" },
  { id: 54, name: "Porsche" },
  { id: 55, name: "RAM" },
  { id: 56, name: "Renault" },
  { id: 57, name: "Rolls-Royce" },
  { id: 58, name: "Saab" },
  { id: 59, name: "Saturn" },
  { id: 60, name: "Scion" },
  { id: 61, name: "smart" },
  { id: 62, name: "SRT" },
  { id: 63, name: "Sterling" },
  { id: 64, name: "Subaru" },
  { id: 65, name: "Suzuki" },
  { id: 66, name: "Tesla" },
  { id: 67, name: "Toyota" },
  { id: 68, name: "Triumph" },
  { id: 69, name: "Volkswagen" },
  { id: 70, name: "Volvo" },
  { id: 71, name: "Yugo" }
]; export default carBrands;