export const appTypes = {
    setHomeSection: '[app] setHomeSection',
    setMenu: '[app] setMenu',
    setTitles: '[app] setTitles',
    setAddingVehicles: '[app] setAddingVehicles',
}

export const userTypes = {
    createUser: '[user] createUser',
    updateUser: '[user] updateUser',
    deleteUser: '[user] deleteUser',
}

export const estateTypes = {
    createEstate: '[estate] createEstate',
    deleteEstate: '[estate] deleteEstate',
    deleteEstates: '[estate] deleteEstates',
    setEstateList: '[estate] setEstateList',
}

export const vehicleTypes = {
    createVehicles: '[vehicle] createVehicles',
    createVehicle: '[vehicle] createVehicle',
    updateVehicle: '[vehicle] updateVehicle',
    updateVehicleId: '[vehicle] updateVehicleId',
    deleteVehicle: '[vehicle] deleteVehicle',
    deleteVehicles: '[vehicle] deleteVehicles',
    createAdditionalUser: '[vehicle] createAdditionalUser',
    deleteAdditionalUser: '[vehicle] deleteAdditionalUser',
    addServiceVehicle: '[vehicle] addServiceVehicle',
    updateNoCirculate: '[vehicle] updateNoCirculate',
    updateVerification: '[vehicle] updateVerification',
    updateTenure: '[vehicle] updateTenure',
    updateFotocivica: '[vehicle] updateFotocivica',
    updateInfracciones: '[vehicle] updateInfracciones',
    updateCirculationCard: '[vehicle] updateCirculationCard',
    updatePlateRenew: '[vehicle] updatePlateRenew',
    updateTheftReport: '[vehicle] updateTheftReport',
    updateEnvironmentalSanction: '[vehicle] updateEnvironmentalSanction',
    updateInsure: '[vehicle] updateInsure',
}

export const paymentTypes = {
    createPayment: '[payment] createPayment',
    updatePayment: '[payment] updatePayment',
}

export const blogTypes = {
    setBlogList: '[blog] setBlogList',
    addLike: '[blog] addLike',
    addShare: '[blog] addShare',
}

export const singleReportTypes = {
    createReport: '[singleReport] createReport',
    createService: '[singleReport] createService',
    createPayment: '[singleReport] createPayment',
}