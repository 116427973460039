import { useEffect, useRef, useState } from 'react';
import AddMaintenanceServiceMainSection from './AddMaintenanceServiceMainSection';
import './ServiceMainSection.css';
import moment from 'moment';
import { toTitleCase } from '../../util/helpers';
import ordinales from 'ordinales-js'
import NumberFormat from 'react-number-format';
import useHeight from '../../hooks/useHeight';

const MaintenanceServiceMainSection = ({ vehicle }) => {
  const { lastService, services: { mantenimiento: { services } } } = vehicle;

  const [showAddService, setShowAddService] = useState(false)
  const [enableSave, setEnableSave] = useState(true)
  const AddServiceForm = useRef(null)
  const [list, setList] = useState(services || [])
  const [ref, height] = useHeight();

  let serviceCounter = parseInt(lastService);

  const handlerSaveButton = () => {
    if (showAddService) {
      AddServiceForm.current.submit();
    } else {
      setShowAddService(!showAddService);
    }
  }

  return (
    <div className="service-main-section" ref={ref}>
      <div className="service-main-title">
        <span>Servicio / Mantenimiento</span>
        <button className={showAddService && 'update'} disabled={!enableSave} onClick={() => handlerSaveButton()}>
          {showAddService ? 'Guardar' : 'Agregar Servicio'}
        </button>
      </div>
      <div className="service-overflow" style={{ height: `${height}px` }}>
        {
          showAddService ? (
            <AddMaintenanceServiceMainSection vehicle={vehicle}
              setEnableSave={setEnableSave}
              AddServiceForm={AddServiceForm}
              setShowAddService={setShowAddService}
              setList={setList}
            />
          ) : (
            <>
              {
                list.map(({ serviceDay, serviceMonth, serviceYear, serviceKm }, i) => {
                  const date = moment(`${serviceDay}-${serviceMonth}-${serviceYear}`, 'DD-MMMM-YYYY');
                  const nextService = moment(date).add({ days: 1, years: 1 })
                  const nextServiceKm = parseInt(serviceKm.replace(',', '')) + 6000;
                  const nextServiceDays = nextService.diff(moment(), 'days');
                  let name = ordinales.toOrdinal(serviceCounter);
                  switch (serviceCounter) {
                    case 1:
                      name = 'Primer'
                      break;
                    case 3:
                      name = 'Tercer'
                      break;
                  }
                  serviceCounter++;
                  return (<div className="service-main-content">
                    <p className="content-title">{toTitleCase(name)} Servicio / Mantenimiento</p>
                    <div className="content-section">
                      <span className="main-label">Último servicio / mantenimiento</span>
                      <span className="main-variable">{date.format('DD[ de ]MMMM[ de ]YYYY')}</span>
                    </div>
                    <div className="content-section">
                      <span className="main-label">Vencimiento</span>
                      <span className={`main-variable ${nextServiceDays < 0 ? 'alert' : ''}`}>{nextServiceDays} días</span>
                    </div>
                    <div className="content-section">
                      <span className="main-label">Próximo servicio / mantenimiento</span>
                      <span className="main-variable">{nextService.format('DD[ de ]MMMM[ de ]YYYY')}</span>
                    </div>
                    <div className="content-section">
                      <span className="main-label">Próximo servicio / mantenimiento</span>
                      <span className="main-variable">
                        <NumberFormat
                          value={nextServiceKm}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={0}
                          suffix=' km (Aprox.)'
                        />
                      </span>
                    </div>
                  </div>);
                })
              }
            </>
          )
        }
      </div>
    </div>
  )
}

export default MaintenanceServiceMainSection
