import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './PlateNotFoundDialog.css';

const PlateNotFoundDialog = ({ state: { open }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="plate-not-found-dialog"
    >
      <div className="plate-not-found-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.REPUVE} alt="" />
          </Grid>
          <Grid item >
            <p className="plate-not-found-dialog-title">Tu placa no está en el REPUVE</p>
          </Grid>
          <Grid item >
            <p className="plate-not-found-dialog-subtitle">
            No te preocupes, puedes registrar tu vehículo ingresando el NIV (Número de Serie) o hacerlo manual.
            </p>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button className="alerta-purple-button" onClick={onClose}>
              <span className="alerta-purple-button-text">Continuar</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default PlateNotFoundDialog
