import { useContext, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Images } from "../util";
import "./NotFoundPage.css";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";

const NotFoundPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true,
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: '',
        back: () => {
          history.goBack()
        }
      }
    })
  }, [])
  return (
    <div className="not-found-container" >
      <Grid container rowSpacing={3}>
        <Grid item container xs={12} justifyContent="center">
          <img src={Images.NOT_FOUND} alt="Not Found Image" />
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography className="not-found-title" variant="h4">
            <span>¡Oh no,</span> algo salió mal!
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography className="not-found-subtitle" variant="subtitle1" textAlign="center">
            No estamos encontrando la página que estás buscando…
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Button className="alerta-purple-button">
            <span className="alerta-purple-button-text" onClick={() => history.push('/')}>Regresar el inicio</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFoundPage;
