import { Dialog, Tooltip, styled, IconButton, tooltipClasses } from "@mui/material";
import './ShareAlertaDialog.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton, FacebookIcon, WhatsappIcon, TwitterIcon, EmailIcon
} from "react-share";
import { useState } from "react";

const LinkCopiedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    border: '1px solid #dadde9',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
    color: '#6C6C6C'
  },
}));

const ShareAlertaDialog = ({ state: { open, data = {} }, onSuccess, onClose }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handlerClickCopyLink = () => {
    navigator.clipboard.writeText(data.linkToCopy);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="share-alerta-dialog"
    >
      <div className="share-alerta-dialog-content" >
        <div className="close-icon-container">
          <FontAwesomeIcon className="close-icon" icon="times" onClick={() => onClose()} />
        </div>
        <p className="title">{data.title}</p>
        <div className="share-icons">
          <WhatsappShareButton
            url={data.whatsapp.url}
            title={data.whatsapp.title}>
            <WhatsappIcon size={32} round />
            <span className="icon-text">WhatsApp</span>
          </WhatsappShareButton>
          <FacebookShareButton
            url={data.facebook.url}
            quote={data.facebook.quote}
            hashtag={data.facebook.hashtag}>
            <FacebookIcon size={32} round />
            <span className="icon-text">Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton
            url={data.twitter.url}
            title={data.twitter.title}
            hashtags={data.twitter.hashtags}
            related={data.twitter.related}
          >
            <TwitterIcon size={32} round />
            <span className="icon-text">Twitter</span>
          </TwitterShareButton>
          <EmailShareButton
            url={data.email.url}
            subject={data.email.subject}
            body={data.email.body}
          >
            <EmailIcon size={32} round />
            <span className="icon-text">Email</span>
          </EmailShareButton>
        </div>
        <div className="link-container">
          <div>
            <span className="note">Vínculo del sitio web</span>
            <p className="link">{data.linkToCopy}</p>
          </div>
          <div className="copy-icon-container">
            <LinkCopiedTooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={linkCopied}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link copiado"
              placement="bottom-end"
            >
              <IconButton size="small" sx={{ padding: 0 }}>
                <FontAwesomeIcon className="copy-icon" icon="copy" onClick={handlerClickCopyLink} />
              </IconButton>
            </LinkCopiedTooltip>
          </div>
        </div>
      </div>
    </Dialog >
  )
}

export default ShareAlertaDialog
