import { useContext, useEffect, useState } from 'react';
import CirculationCardServiceMainSection from './CirculationCardServiceMainSection';
import moment from 'moment';
import './ServiceSideSection.css';
import { getCirculationCardVehicleData } from '../../services/VehicleServices';
import { vehicleTypes } from '../../reducers/types';
import { CircularProgress } from '@mui/material';
import { AppContext } from '../../contexts';
import { toEstateTitleCase } from '../../util/helpers';
let circulationCardTimer = false;

const CirculationCardServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { estatePlate, estate, services: { tarjeta_circulacion } } = vehicle;
  const foreigner = toEstateTitleCase(estatePlate) != estate;
  const expiration = moment(tarjeta_circulacion.circulation_card_expiration, 'YYYY-MM-DD');
  const expire = tarjeta_circulacion.expire;
  const validity = 3;
  const loading = !foreigner && expire == null;

  useEffect(() => {
    circulationCardTimer = false;
    return () => {
      circulationCardTimer = true;
    }
  }, [])

  useEffect(() => {
    if (selected == 'circulation_card')
      setMainPanel(<CirculationCardServiceMainSection vehicle={vehicle} />)
  }, [selected, vehicle])

  useEffect(() => {
    if (open && loading) {
      circulationCardTimer = false;
      getCirculationCardData()
    } else
      circulationCardTimer = true
  }, [open, loading])

  const getCirculationCardData = async () => {
    const { success, data } = await getCirculationCardVehicleData(vehicle.id)
    if (success && data.tarjeta_circulacion.expire != null) {
      dispatch({
        type: vehicleTypes.updateCirculationCard,
        payload: {
          id: vehicle.id,
          tarjeta_circulacion: data.tarjeta_circulacion
        }
      });
    } else {
      setTimeout(() => {
        if (!circulationCardTimer)
          getCirculationCardData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          foreigner ? (
            <>
              <p className="title">Tarjeta de circulación</p>
              <div className="without-info-container">
                <p className='title'>Servicio disponible solo para placas de la Ciudad de México.</p>
              </div>
            </>
          ) : (
            <>
              <p className="title">Tarjeta de circulación</p>
              <p>
                <span className="side-label">Vencimiento</span>
                <span className="side-variable">{expiration.isValid() ? expiration.format('DD[ de ]MMMM[ de ]YYYY') : '-'}</span>
              </p>
              <p>
                <span className="side-label">Vigencia</span>
                <span className="side-variable">{`${validity} año${validity == 1 ? '' : 's'}`}</span>
              </p>
              <p>
                <span className="side-label">Estatus Actual</span>
                <span className={`side-variable ${expire ? 'alert' : ''}`}>{expire ? 'Vencida' : 'Vigente'}</span>
              </p>
            </>
          ))
      }
    </div >
  )
}

export default CirculationCardServiceSideSection
