import { useContext, useEffect } from "react";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";
import questions from "../data/questions";
import "./DoubtsPage.css";

const DoubtsPage = ({ history }) => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: '<span>Preguntas</span> Frecuentes',
        back: () => history.goBack()
      }
    })
  }, [])
  return (
    <div className="doubts-page">
      <div className="container">
        {questions.map(({ id, description, answer }) => (
          <div className="terms_conditions_text_block">
            <h4>{id}. {description}</h4>
            <p>{answer}</p>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoubtsPage;
