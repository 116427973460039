import { useState } from "react";
import PropTypes from "prop-types";

import { Images } from "../../../util";
import "./index.css";

export const Box_Question_Molecules = ({ question: { description, answer } }) => {
  const [show, setShow] = useState(false);

  const renderSubtitle = () => {
    return show ? (
      <div className="content-subtitle">
        <p>{answer} </p>
      </div>
    ) : null;
  };

  return (
    <div
      className={"box-question-molecules " + (show ? "box-show" : "")}
      onClick={() => setShow(!show)}
    >
      <div className="content-title">
        <p>{description}</p>
        {show ? (
          <img src={Images.QUESTION_UP} />
        ) : (
          <img src={Images.QUESTION_DOWN} />
        )}
      </div>
      {renderSubtitle()}
    </div>
  );
};

Box_Question_Molecules.propTypes = {
  question: PropTypes.object,
};

Box_Question_Molecules.defaultProps = {
  question: {},
};
