import { PATH_IMAGE } from "./Configuration";

export const ALERT_MINI = PATH_IMAGE + "/assets/images/logo/alerta_mini.svg";
export const LOGO_ALERTA_BLACK =
  PATH_IMAGE + "/assets/images/logo/alerta_black.svg";
export const LOGO_ALERTA_BLACK_MINI =
  PATH_IMAGE + "/assets/images/logo/alerta_black_mini.svg";
export const LOGO_ALERTA_WHITE =
  PATH_IMAGE + "/assets/images/logo/alerta_white.svg";
export const LOGO_ALERTA_MICASA =
  PATH_IMAGE + "/assets/images/logo/alerta_mi_casa.svg";

export const CLOSE_ALERT = PATH_IMAGE + "/assets/images/icon/close_alert.svg";

export const BACKGROUND_INTRODUCTION =
  PATH_IMAGE + "/assets/images/background/background_introduction.png";

export const PLAY_ICON = PATH_IMAGE + "/assets/images/icon/play.svg";
export const BURGER_MENU =
  PATH_IMAGE + "/assets/images/icon/hamburger_menu.svg";
export const QUESTION = PATH_IMAGE + "/assets/images/icon/question.svg";
export const MORE = PATH_IMAGE + "/assets/images/icon/more.svg";
export const LESS = PATH_IMAGE + "/assets/images/icon/less.svg";
export const STAR = PATH_IMAGE + "/assets/images/icon/star.svg";
export const LOGOUT = PATH_IMAGE + "/assets/images/icon/logout.svg";
export const LOCATION = PATH_IMAGE + "/assets/images/icon/location.svg";
export const OPINION_ARROW =
  PATH_IMAGE + "/assets/images/icon/arrow_opinion.svg";
export const SETTINGS = PATH_IMAGE + "/assets/images/icon/settings.svg";
export const PRINT = PATH_IMAGE + "/assets/images/icon/print.svg";

export const ARROW_RIGHT_HEADER =
  PATH_IMAGE + "/assets/images/icon/arrow_rigth_header.svg";
export const CAR_ALERT_LOGO =
  PATH_IMAGE + "/assets/images/icon/car_alert_header_logo.svg";

export const ARROW_RIGHT = PATH_IMAGE + "/assets/images/icon/arrow_right.svg";
export const ARROW_RIGHT_GREEN =
  PATH_IMAGE + "/assets/images/icon/arrow_right_green.svg";
export const ARROW_LEFT = PATH_IMAGE + "/assets/images/icon/arrow_left.svg";
export const ARROW_BACK = PATH_IMAGE + "/assets/images/icon/arrow_back.svg";
export const CIRCLE_OFF = PATH_IMAGE + "/assets/images/icon/circle_off.svg";
export const CIRCLE_ON = PATH_IMAGE + "/assets/images/icon/circle_on.svg";
export const ACCOUNT_DONE = PATH_IMAGE + "/assets/images/icon/account_done.svg";
export const ACCOUNT_DONE_BLUE =
  PATH_IMAGE + "/assets/images/icon/account_done_blue.svg";
export const ERROR_ALERT = PATH_IMAGE + "/assets/images/icon/error_alert.svg";
export const ERROR_LABEL_ICON =
  PATH_IMAGE + "/assets/images/icon/error_label_icon.svg";
export const QUESTION_UP = PATH_IMAGE + "/assets/images/icon/question_up.svg";
export const QUESTION_DOWN =
  PATH_IMAGE + "/assets/images/icon/question_down.svg";

export const CDMX = PATH_IMAGE + "/assets/images/logo/states/cdmx.svg";
export const JALISCO = PATH_IMAGE + "/assets/images/logo/states/jalisco.svg";
export const EDOMEX = PATH_IMAGE + "/assets/images/logo/states/edomex.svg";
export const OAX = PATH_IMAGE + "/assets/images/logo/states/oaxaca.svg";
export const PUE = PATH_IMAGE + "/assets/images/logo/states/puebla.svg";

export const ACTIVE_ESTATE =
  PATH_IMAGE + "/assets/images/estates/active_estate.svg";

export const CARS =
  PATH_IMAGE + "/assets/images/estates/cars.svg";
export const CARS_LIMIT =
  PATH_IMAGE + "/assets/images/estates/cars_limit.svg";
export const ESTATE_LOGO_CAR =
  PATH_IMAGE + "/assets/images/estates/car_logo.svg";
export const ESTATE_LOGO_MOTO =
  PATH_IMAGE + "/assets/images/estates/moto_logo.svg";
export const COUNTER_UP_ARROW =
  PATH_IMAGE + "/assets/images/estates/counter_up_arrow.svg";
export const COUNTER_DOWN_ARROW =
  PATH_IMAGE + "/assets/images/estates/counter_down_arrow.svg";

export const BACKGROUND_STEPS =
  PATH_IMAGE + "/assets/images/background/background_steps.svg";

export const PAYMENT_OXXO = PATH_IMAGE + "/assets/images/logo/oxxo.svg";
export const CIRCLES_STEPS =
  PATH_IMAGE + "/assets/images/background/circle_steps.svg";

export const PAYMENT_PAYPAL = PATH_IMAGE + "/assets/images/logo/paypal.svg";
export const PAYMENT_VISA = PATH_IMAGE + "/assets/images/logo/visa.svg";
export const PAYMENT_SPEI = PATH_IMAGE + "/assets/images/logo/spei.svg";
export const SHIELD_GREY = PATH_IMAGE + "/assets/images/logo/shield-grey.svg";
export const SHARE_ICON = PATH_IMAGE + "/assets/images/logo/share-icon.png";

export const NOTIFICATION_IMAGE =
  PATH_IMAGE + "/assets/images/background/notification.svg";
export const ATTENTION = PATH_IMAGE + "/assets/images/background/attention.svg";
export const OPINION = PATH_IMAGE + "/assets/images/background/opinion.svg";

//Testimonials
export const TESTIMONIAL_1 =
  PATH_IMAGE + "/assets/images/testimonial/testimonial_1.svg";
export const TESTIMONIAL_2 =
  PATH_IMAGE + "/assets/images/testimonial/testimonial_2.svg";
export const TESTIMONIAL_3 =
  PATH_IMAGE + "/assets/images/testimonial/testimonial_3.svg";
export const TESTIMONIAL_CLIPS =
  PATH_IMAGE + "/assets/images/testimonial/testimonial_clips.svg";  


export const TEM_PHOTO_DESKTOP =
  PATH_IMAGE + "/assets/images/temp/photo_desktop.svg";
export const TEM_PHOTO_MOBILE =
  PATH_IMAGE + "/assets/images/temp/photo_mobile.svg";
export const TEM_PHOTO_CLIPS =
  PATH_IMAGE + "/assets/images/temp/photo_clips.svg";
export const AGENT = PATH_IMAGE + "/assets/images/temp/agent.svg";

export const FACEBOOK = PATH_IMAGE + "/assets/images/icon/facebook.svg";
export const INSTAGRAM = PATH_IMAGE + "/assets/images/icon/instagramlogo.svg";
export const TWITTER = PATH_IMAGE + "/assets/images/icon/twitter.svg";
export const LINKEDIN = PATH_IMAGE + "/assets/images/icon/linkedin.svg";
export const ACTIVE_DOT =
  PATH_IMAGE + "/assets/images/icon/carousel_dot_active.svg";
export const INACTIVE_DOT =
  PATH_IMAGE + "/assets/images/icon/carousel_dot_inactive.svg";

export const CLOSE_MODAL = PATH_IMAGE + "/assets/images/icon/close_modal.svg";

export const INTRODUCTION_BANNER =
  PATH_IMAGE + "/assets/images/home/introductionBanner.png";

// circle steeps landing How
export const REGISTER_FOLIO =
  PATH_IMAGE + "/assets/images/logo/how_icons/register_folio.svg";
export const CONTACT_DATA =
  PATH_IMAGE + "/assets/images/logo/how_icons/contact_data.svg";
export const ANUAL_PAY =
  PATH_IMAGE + "/assets/images/logo/how_icons/anual_pay.svg";

//
export const NOT_CIRCULATE =
  PATH_IMAGE + "/assets/images/logo/how_icons/not_circulate.svg";
export const FOTOCIVICAS =
  PATH_IMAGE + "/assets/images/logo/how_icons/fotocivicas.svg";
export const NOT_CIRCULATE_MOTO =
  PATH_IMAGE + "/assets/images/logo/how_icons/not_circulate_moto.svg";
export const CHECKUP = PATH_IMAGE + "/assets/images/logo/how_icons/checkup.svg";
export const CHECKUP_MOTO =
  PATH_IMAGE + "/assets/images/logo/how_icons/checkup_moto.svg";
export const TENURE = PATH_IMAGE + "/assets/images/logo/how_icons/tenure.svg";
export const INSURE = PATH_IMAGE + "/assets/images/logo/how_icons/insure.svg";
export const TENURE_MOTO =
  PATH_IMAGE + "/assets/images/logo/how_icons/tenure_moto.svg";
export const PENALTY = PATH_IMAGE + "/assets/images/logo/how_icons/penalty.svg";
export const CIRCULATION_CARD =
  PATH_IMAGE + "/assets/images/logo/how_icons/circulation_card.svg";
export const MAINTENANCE =
  PATH_IMAGE + "/assets/images/logo/how_icons/maintenance.svg";
export const MAINTENANCE_MOTO =
  PATH_IMAGE + "/assets/images/logo/how_icons/maintenance_moto.svg";
export const THEFT_REPORT =
  PATH_IMAGE + "/assets/images/logo/how_icons/theft_report.svg";
export const ENVIRONMENTAL_SANCTION =
  PATH_IMAGE + "/assets/images/logo/how_icons/environmental_sanction.svg";

export const WHY_IMG = PATH_IMAGE + "/assets/images/background/why_img.svg";

export const NOTIFICATION_CAR =
  PATH_IMAGE + "/assets/images/background/notification_car.svg";
export const NOTIFICATION_CAR_DESKTOP =
  PATH_IMAGE + "/assets/images/background/notification_car_desktop.svg";
export const NOTIFICATION_MAN =
  PATH_IMAGE + "/assets/images/background/notification_man.svg";
export const NOTIFICATION_WOMAN =
  PATH_IMAGE + "/assets/images/background/notification_woman.svg";

export const NOTIFICATION_ICON_EMAIL =
  PATH_IMAGE + "/assets/images/icon/notifications/icon_email.svg";
export const NOTIFICATION_ICON_WHATS =
  PATH_IMAGE + "/assets/images/icon/notifications/icon_whats.svg";
export const NOTIFICATION_ICON_MSN =
  PATH_IMAGE + "/assets/images/icon/notifications/icon_msn.svg";

// Login
export const BACKGROUND_CUBE =
  PATH_IMAGE + "/assets/images/background/login_background_cube.svg";

export const DOTTED_BOX =
  PATH_IMAGE + "/assets/images/background/dotted_box.svg";

export const DIALOG_SELLO = PATH_IMAGE + "/assets/images/temp/Sello.png";
export const DIALOG_CONSTANCIA =
  PATH_IMAGE + "/assets/images/temp/Constancia.png";

export const DIALOG_CLOSE_ICON =
  PATH_IMAGE + "/assets/images/icon/dialog_close_icon.svg";

// Payments
export const CONEKTA_SECURE = "/assets/images/payment/conekta_secure.png";
export const DIGITAL_PAYMENT = "/assets/images/payment/digital-credit-card.png";
export const ERROR_CARD_PAYMENT = "/assets/images/payment/error_card_payment.png";
export const COUPON_COMPANY = "/assets/images/payment/coupon_company.png";

export const PROCESSING_PAYMENT =
  "/assets/images/payment/processing_payment_img.png";

export const NOT_FOUND = "/assets/images/temp/not_found.svg";

// Register Car
export const ACTIVE_CAR = "/assets/images/registerCar/active_car.svg";
export const INACTIVE_CAR = "/assets/images/registerCar/inactive_car.svg";
export const ACTIVE_MOTO = "/assets/images/registerCar/active_moto.svg";
export const INACTIVE_MOTO = "/assets/images/registerCar/inactive_moto.svg";
export const APPROVE_ICON = "/assets/images/registerCar/approve_icon.svg";
export const DECLINED_ICON = "/assets/images/registerCar/declined_icon.svg";
export const RED_PLATE = "/assets/images/registerCar/red_plate.png";
export const PURPLE_PLATE = "/assets/images/registerCar/purple_plate.png";
export const WHITE_PLATE = "/assets/images/registerCar/white_plate.svg";
export const REPUVE = "/assets/images/registerCar/repuve.png";

// Home
export const NOT_CIRCULATE_IMG = "/assets/images/home/not_circulate.svg";
export const VERIFY_CALENDAR = "/assets/images/myAlerts/calendario-de-verificacion.png";
export const MESSAGE_MAIL = "/assets/images/home/MAIL.png";
export const MESSAGE_SMS = "/assets/images/home/SMS.png";
export const MESSAGE_WHATS = "/assets/images/home/WhatsApp.png";

//Companies
export const ADN40 = "/assets/images/home/adn40.png";
export const EL_UNIVERSAL = "/assets/images/home/elUniversal.png";
export const GRUPO_FORMULA = "/assets/images/home/grupoFormula.png";
export const MILENIO = "/assets/images/home/milenio.png";
export const REFORMA = "/assets/images/home/reforma.png";
export const TV_AZTECA = "/assets/images/home/tvAzteca.png";
export const AUTOMOVIL = "/assets/images/home/automovil.png";
export const AUTOCOSMOS = "/assets/images/home/autocosmos.png";
//Additional Services
export const ADDITIONAL_SERVICES = "/assets/images/home/additionalServices.png";
export const WHY_HOME_SECTION = "/assets/images/home/whyHomeSection.png";
//Opinions
export const OPINION_1 = "/assets/images/home/opinion1.png";
export const OPINION_2 = "/assets/images/home/opinion2.png";
export const OPINION_3 = "/assets/images/home/opinion3.png";
export const OPINION_4 = "/assets/images/home/opinion4.png";
export const OPINION_5 = "/assets/images/home/opinion5.png";
export const OPINION_6 = "/assets/images/home/opinion6.png";
export const OPINION_7 = "/assets/images/home/opinion7.png";
export const OPINION_8 = "/assets/images/home/opinion8.png";
export const OPINION_9 = "/assets/images/home/opinion9.png";
export const QUOTES_START = "/assets/images/home/quotesStart.png";
export const QUOTES_END = "/assets/images/home/quotesEnd.png";

// Insurers

export const INSURER_ABA = "/assets/images/insurers/aba.svg";
export const INSURER_AGUILA = "/assets/images/insurers/aguila.svg";
export const INSURER_ANA = "/assets/images/insurers/ana.svg";
export const INSURER_AXXA = "/assets/images/insurers/axxa.svg";
export const INSURER_GNP = "/assets/images/insurers/gnp.svg";
export const INSURER_HDI = "/assets/images/insurers/hdi.svg";
export const INSURER_MAPFRE = "/assets/images/insurers/mapfre.svg";
export const INSURER_QUALITAS = "/assets/images/insurers/qualitas.svg";
export const INSURER_SURA = "/assets/images/insurers/sura.svg";
export const INSURER_ZURICH = "/assets/images/insurers/zurich.svg";

// Isurance Logo
export const INSURANCE_ARROW_DONE = "/assets/images/icon/purple_done.svg";

// Roadside Logo
export const CART_CRANE = "/assets/images/roadside/cart_crane.svg";
export const FUEL = "/assets/images/roadside/fuel.svg";
export const IKE_LOGO = "/assets/images/roadside/ike_logo.svg";
export const TIRE = "/assets/images/roadside/tire.svg";

// Corralon Logo
export const NOT_FOUND_PLATE = "/assets/images/corralon/not_found_plate.svg";
export const CALL = "/assets/images/corralon/call.svg";
export const WAZE = "/assets/images/corralon/waze.svg";

// Sell Car logos
export const KAVAK = "/assets/images/sellCar/kavak.svg";
export const OXLAUTOS = "/assets/images/sellCar/oxlautos.svg";
export const SOLOAUTOS = "/assets/images/sellCar/soloautos.svg";

// Trace Route logos
export const POINT_BY_POINT = "/assets/images/traceRoute/point_by_point.svg";
export const CAR = "/assets/images/traceRoute/car.svg";

// Blog
export const PRINCIPAL_POST = "/assets/images/temp/posts/principal_post.svg";
export const POST_1 = "/assets/images/temp/posts/posts_1.svg";
export const POST_2 = "/assets/images/temp/posts/posts_2.svg";
export const POST_3 = "/assets/images/temp/posts/posts_3.svg";
export const POST_4 = "/assets/images/temp/posts/posts_4.svg";
export const POST_5 = "/assets/images/temp/posts/posts_5.svg";
export const POST_6 = "/assets/images/temp/posts/posts_6.svg";
export const POST_7 = "/assets/images/temp/posts/posts_7.svg";
export const POST_8 = "/assets/images/temp/posts/posts_8.svg";
export const POST_DETAIL_CAR = "/assets/images/temp/posts/posts_detail_car.svg";
export const POST_DETAIL_CAR_1 = "/assets/images/temp/posts/posts_detail_car_1.svg";

export const ALERTA_MI_AUTO_WHITE = "/assets/images/logo/alertamiautowhite.png";

//PREMIUM
export const ALERTA_MI_AUTO_GREEN = "/assets/images/premium/alertamiautogreen.svg";
export const ALERTA_MI_AUTO_GRAY = "/assets/images/premium/alertamiautogray.svg";
export const ALERTA_PREMIUM = "/assets/images/premium/alerta_premium_logo.svg";
export const PREMIUM_CROW = "/assets/images/premium/premium_crow.svg";

//ESTATES

export const CIUDAD_DE_MÉXICO_ESTATE = PATH_IMAGE + "/assets/images/estates/CIUDAD_DE_MÉXICO_ESTATE.png";
export const CIUDAD_DE_MÉXICO_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/CIUDAD_DE_MÉXICO_ESTATE_BOLD.png";
export const ESTADO_DE_MÉXICO_ESTATE = PATH_IMAGE + "/assets/images/estates/ESTADO_DE_MÉXICO_ESTATE.png";
export const ESTADO_DE_MÉXICO_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/ESTADO_DE_MÉXICO_ESTATE_BOLD.png";
export const HIDALGO_ESTATE = PATH_IMAGE + "/assets/images/estates/HIDALGO_ESTATE.png";
export const HIDALGO_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/HIDALGO_ESTATE_BOLD.png";
export const JALISCO_ESTATE = PATH_IMAGE + "/assets/images/estates/JALISCO_ESTATE.png";
export const JALISCO_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/JALISCO_ESTATE_BOLD.png";
export const MORELOS_ESTATE = PATH_IMAGE + "/assets/images/estates/MORELOS_ESTATE.png";
export const MORELOS_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/MORELOS_ESTATE_BOLD.png";
export const NUEVO_LEON_ESTATE = PATH_IMAGE + "/assets/images/estates/NUEVO_LEON_ESTATE.png";
export const NUEVO_LEON_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/NUEVO_LEON_ESTATE_BOLD.png";
export const PUEBLA_ESTATE = PATH_IMAGE + "/assets/images/estates/PUEBLA_ESTATE.png";
export const PUEBLA_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/PUEBLA_ESTATE_BOLD.png";
export const QUERETARO_DE_ARTEAGA_ESTATE = PATH_IMAGE + "/assets/images/estates/QUERETARO_DE_ARTEAGA_ESTATE.png";
export const QUERETARO_DE_ARTEAGA_ESTATE_BOLD = PATH_IMAGE + "/assets/images/estates/QUERETARO_DE_ARTEAGA_ESTATE_BOLD.png";

//NIV
export const NIV_CIUDAD_DE_MÉXICO = PATH_IMAGE + "/assets/images/niv/NIV_CIUDAD_DE_MÉXICO.png";
export const NIV_JALISCO = PATH_IMAGE + "/assets/images/niv/NIV_JALISCO.png";
export const NIV_ESTADO_DE_MÉXICO = PATH_IMAGE + "/assets/images/niv/NIV_ESTADO_DE_MÉXICO.png";
export const NIV_MORELOS = PATH_IMAGE + "/assets/images/niv/NIV_MORELOS.png";
export const NIV_NUEVO_LEON = PATH_IMAGE + "/assets/images/niv/NIV_NUEVO_LEON.png";
export const NIV_PUEBLA = PATH_IMAGE + "/assets/images/niv/NIV_PUEBLA.png";

//DIALOGS
export const PLATE_ALERT = PATH_IMAGE + "/assets/images/dialog/plate_alert.svg";