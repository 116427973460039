import { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import ContentHeaderSection from "../component/sections/ContentHeaderSection";
import { AppContext } from "../contexts";
import LoginConfirmCodePage from "../page/LoginConfirmCodePage";
import ContractAlertPage from "../page/ContractAlertPage";
import DoubtsPage from "../page/DoubtsPage";
import LoginPage from "../page/LoginPage";
import NotFoundPage from "../page/NotFoundPage";
import PrivacyNoticePage from "../page/PrivacyNoticePage";
import SuscriberPage from "../page/SuscriberPage";
import TermsConditionsPage from "../page/TermsConditionsPage";
import PrivateRoute from "./PrivateRoute";
import PrivateRoutes from "./PrivateRoutes";
import ValidateRoute from "./ValidateRoute";
import BlogPage from "../page/BlogPage";
import BlogDetailPage from "../page/BlogDetailPage";
import BlogCategoryPage from "../page/BlogCategoryPage";
import SingleReportPage from "../page/singleReport/SingleReportPage";
import ExamplePremiumSingleReportPage from "../page/singleReport/ExamplePremiumSingleReportPage";
import SingleReportLoadingPage from "../page/singleReport/SingleReportLoadingPage";
import BasicSingleReportPage from "../page/singleReport/BasicSingleReportPage";
import PremiumSingleReportPage from "../page/singleReport/PremiumSingleReportPage";
import PremiumReportPaymentPage from "../page/singleReport/PremiumReportPaymentPage";
import PremiumReportPage from "../page/singleReport/PremiumReportPage";

const Routes = ({ history, location: { pathname } }) => {
  const { app: { user, singleReport } } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const validateLogin = () => user && user.token && user.registered ? [false, '/alertas'] : [true];
  const validateSingleReportLoading = () => singleReport.token ? [true] : [false, '/informe'];
  const validateBasicSingleReport = () => singleReport.token ? [true] : [false, '/informe'];
  const validatePremiumReportPayment = () => singleReport.token ? [true] : [false, '/informe'];
  const validatePremiumReport = () => singleReport.token && singleReport.payment  ? [true] : [false, '/informe'];
  
  let current_view = history.location.pathname.replace('/', '');
  current_view = current_view == '' ? 'home' : current_view;

  return (
    <div className={`main-section ${current_view}-content`}>
      <ContentHeaderSection />
      <main>
        <section>
          <Switch>
            <Route exact path="/not_found" component={NotFoundPage} />
            <Route exact path="/suscriber" component={SuscriberPage} />
            <Route exact path="/contratar_alerta" component={ContractAlertPage} />
            <Route exact path="/aviso_privacidad" component={PrivacyNoticePage} />
            <Route exact path="/terminos_condiciones" component={TermsConditionsPage} />
            <Route exact path="/doubts" component={DoubtsPage} />
            <Route exact path="/login_confirm_code" component={LoginConfirmCodePage} />
            <Route exact path="/blog" component={BlogPage} />
            <Route exact path="/blog/:articleId" component={BlogDetailPage} />
            <Route exact path="/blog/categoria/:category" component={BlogCategoryPage} />
            <Route exact path="/informe" component={SingleReportPage} />
            <Route exact path="/informe_premium" component={PremiumReportPage}/>
            <Route exact path="/ejemplo_informe_premium" component={ExamplePremiumSingleReportPage} />
            <ValidateRoute exact path="/cargando_informe" component={SingleReportLoadingPage} validation={validateSingleReportLoading} />
            <ValidateRoute exact path="/resultado_informe_basico" component={BasicSingleReportPage} validation={validateBasicSingleReport} />
            <ValidateRoute exact path="/pago_informe_premium" component={PremiumReportPaymentPage} validation={validatePremiumReportPayment} />
            <ValidateRoute exact path="/resultado_informe_premium" component={PremiumSingleReportPage} validation={validatePremiumReport} />
            <ValidateRoute exact path="/login" component={LoginPage} validation={validateLogin} />
            <PrivateRoute path="/" component={PrivateRoutes} />
          </Switch>
        </section>
      </main>
    </div>
  )
}

export default Routes
