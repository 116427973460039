import { useEffect } from "react";

const BeforeExitMessage = () => {
  const alertMessage = (e) => {
    e.preventDefault();
    return e.returnValue = 'Esta página le pide que confirme si desea salir; puede que la información de haya introducido no se guarde.';
  }
  useEffect(() => {
    window.addEventListener("beforeunload", alertMessage);
    return () => window.removeEventListener("beforeunload", alertMessage);
  }, [])
  return null
}

export default BeforeExitMessage