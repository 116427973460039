import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { vehicleTypes } from '../../reducers/types';
import { getInfraccionesVehicleData } from '../../services/VehicleServices';
import PenalityServiceMainSection from './PenalityServiceMainSection';
import './ServiceSideSection.css';
import './PenalityServiceSideSection.css';
let penalityTimer = false;
const PenalityServiceSideSection = ({ vehicle, selected, setMainPanel, open }) => {
  let { dispatch } = useContext(AppContext);
  let { services: { multa_transito } } = vehicle;
  const loading = Object.entries(multa_transito).length == 0;
  const multas = loading ? [] : [...multa_transito.cdmx, ...(multa_transito.edomex ?? [])].filter(({ situacion }) => situacion);
  const noPaid = !loading ? multas.filter(({ situacion }) => situacion.includes('No pagada')).length : 0;
  const paid = !loading ? multas.filter(({ situacion }) => situacion.includes('Pagada')).length : 0;
  const [status, setStatus] = useState(null)

  useEffect(() => {
    penalityTimer = false;
    return () => {
      penalityTimer = true;
    }
  }, [])

  useEffect(() => {
    if (open && loading) {
      penalityTimer = false;
      getPenalityData();
    } else
      penalityTimer = true;
  }, [open, loading])

  useEffect(() => {
    if (selected == 'penality') {
      setMainPanel(<PenalityServiceMainSection vehicle={vehicle} status={status} />)
    } else {
      setStatus(null)
    }
  }, [selected, status])

  const getPenalityData = async () => {
    let { success, data } = await getInfraccionesVehicleData(vehicle.id)
    if (success && Object.entries(data.multa_transito).length != 0) {
      dispatch({
        type: vehicleTypes.updateInfracciones,
        payload: {
          id: vehicle.id,
          multa_transito: data.multa_transito
        }
      });
    } else {
      setTimeout(() => {
        if (!penalityTimer)
          getPenalityData();
      }, 1000 * 5);
    }
  }

  return (
    <div className="service-side-section">
      {
        loading ? (
          <div className="loading-container">
            <p className='title'>¡Estamos procesando tu información, espera un momento!</p>
            <div className="loading">
              <CircularProgress className="loading-icon" size={30} />
            </div>
          </div>
        ) : (
          <>
            <p className="title">Infracciones al reglamento de tránsito</p>
            <p
              className={`penality-status ${status == 'No pagada' ? 'selected' : ''}`}
              onClick={() => noPaid != 0 && setStatus('No pagada')}
            >
              <span className='side-label'>No pagadas</span>
              <span className={`${noPaid != 0 ? 'alert' : ''} side-variable`}>{noPaid == 0 ? 'No tienes ' : 'Tienes ' + noPaid} multa{noPaid == 1 ? '' : 's'}</span>
            </p>
            <p
              className={`penality-status ${status == 'Pagada' ? 'selected' : ''}`}
              onClick={() => paid != 0 && setStatus('Pagada')}
            >
              <span className='side-label'>Pagadas</span>
              <span className="side-variable">{paid == 0 ? 'No tienes ' : 'Tienes ' + paid} multa{paid == 1 ? '' : 's'}</span>
            </p>
          </>
        )
      }
    </div>
  )
}

export default PenalityServiceSideSection
