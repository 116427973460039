import { Grid } from "@mui/material";
import { Images } from "../../../util";
import './SelectPaymentTypeSection.css';

const SelectPaymentTypeSection = ({ setPaymentType, card, paypal, oxxo, spei }) => {
  return (
    <div className="payment-section">
      <Grid container flexDirection={'column'} rowSpacing={2}>
        <Grid item>
          <p className="payment-subtitle">Selecciona el método de pago:</p>
        </Grid>
        {
          card && (
            <Grid item container>
              <div className="payment-type-section" onClick={() => setPaymentType("card")} >
                <p>Tarjeta de Crédito / Débito</p>
                <img src={Images.PAYMENT_VISA}></img>
              </div>
            </Grid>
          )
        }
        {
          paypal && (
            <Grid item container>
              <div className="payment-type-section" onClick={() => setPaymentType("paypal")} >
                <p>Pago con PayPal</p>
                <img src={Images.PAYMENT_PAYPAL}></img>
              </div>
            </Grid>
          )
        }
        {
          oxxo && (
            <Grid item container>
              <div className="payment-type-section" onClick={() => setPaymentType("oxxo")} >
                <p>Pago en efectivo (México)</p>
                <img src={Images.PAYMENT_OXXO}></img>
              </div>
            </Grid>
          )
        }
        {
          spei && (
            <Grid item container>
              <div className="payment-type-section" onClick={() => setPaymentType("spei")} >
                <label>Transferencia Interbancaria (SPEI)</label>
                <img src={Images.PAYMENT_SPEI}></img>
              </div>
            </Grid>
          )
        }
      </Grid>
    </div>
  )
}

SelectPaymentTypeSection.defaultProps = {
  card: true,
  paypal: true,
  oxxo: true,
  spei: false
}

export default SelectPaymentTypeSection
