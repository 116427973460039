import { Animated } from "react-animated-css";
import { useContext, useState } from "react";
import { Box_Question_Molecules } from "../molecules/Box_Question_Molecules";
import "./DoubtsSection.css";
import { AppContext } from "../../contexts";
import questions from "../../data/questions";
import { Fade } from "react-awesome-reveal";

const DoubtsSection = () => {
  const { app: { home: { doubts } } } = useContext(AppContext);
  const [showMoreQuestions, setShowMoreQuestions] = useState(false);
  const renderQuestions = (questions) => {
    return questions.map((question) => (
      <Box_Question_Molecules key={`question${question.id}DoubtsSection`} question={question} />
    ));
  };

  return (
    <div ref={doubts} id="doubts-section">
      <Fade direction="up" triggerOnce>
        <article className="principal-doubts-organism">
          <div className="wrap-general wrap-doubts-organism">
            <div className="content-title">
              <p className="title">¿Sigues teniendo dudas?</p>
            </div>
            <div className="content-subtitle">
              <p className="subtitle">
                No te preocupes, te dejamos algunas preguntas y respuestas para ayudarte a responder tus dudas.
              </p>
            </div>
            <div className="wrap-questions">
              <div className="content-questions">
                {renderQuestions(questions.filter((q, i) => i < 6))}
              </div>
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={showMoreQuestions}>
                <div className="content-questions">
                  {renderQuestions(questions.filter((q, i) => i > 6))}
                </div>
              </Animated>
            </div>
            <p className="footer" onClick={() => {
              setShowMoreQuestions(!showMoreQuestions);
            }}>
              {showMoreQuestions ? '- Ver menos preguntas' : '+ Ver más preguntas'}
            </p>
          </div>
        </article>
      </Fade>
    </div>
  );
};

export default DoubtsSection;