import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './PlateUsedSingleReportDialog.css';

const PlateUsedSingleReportDialog = ({ state: { open }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="plate-used-single-report-dialog"
    >
      <div className="plate-used-single-report-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.PLATE_ALERT} alt="" />
          </Grid>
          <Grid item >
            <p className="plate-used-single-report-dialog-title">
              Esta placa ya utilizó la versión gratuita del Informe Vehicular
            </p>
          </Grid>
          <Grid item >
            <p className="plate-used-single-report-dialog-subtitle">
              Puedes contratar un Informe Vehicular Premium con el detalle de cada uno de los servicios para tu vehículo
            </p>
          </Grid>
          <Grid item container className="remove-alert-dialog-button-container" flexDirection={"column"}>
            <Button className="alerta-purple-button" onClick={onSuccess}>
              <p className="alerta-purple-button-text">Contratar</p>
            </Button>
            <Button className="alerta-white-button" onClick={onClose}>
              <p className="alerta-white-button-text">Cancelar</p>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default PlateUsedSingleReportDialog
