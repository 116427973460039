import { useContext, useEffect } from "react";
import { AppContext } from "../contexts";
import { appTypes, userTypes } from "../reducers/types";
import FooterSection from "../component/sections/FooterSection";
import "./PaymentProcessedPage.css";
import ConfigNotificationSection from "../component/sections/ConfigNotificationSection";
import BeforeExitMessage from "../helpers/BeforeExitMessage";

const PaymentProcessedPage = ({ history }) => {
  const { app: { user, payment: { type } }, dispatch } = useContext(AppContext);
  const paymentPending = type == 'oxxo';

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        burger: true,
        agentPhoto: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: 'Hemos recibido <br class="mobile"/> <span> tu ' + (paymentPending ? 'solicitud' : (type == 'discount' ? 'información' : 'pago')) + '</span>',
        subtitle: paymentPending ?
          'Te enviaremos un correo electrónico con la confirmación de tu pago realizado en las tiendas OXXO.' :
          'A partir de este momento tu vehículo queda registrado y recibirás notificaciones de cada uno de nuestros servicios.',
      }
    })
    dispatch({
      type: userTypes.updateUser,
      payload: { addingVehicles: true, registered: true }
    });
  }, [])

  return (
    <>
      <ConfigNotificationSection onSave={() => history.push('/procesando_informacion')} />
      <FooterSection />
      <BeforeExitMessage />
    </>
  );
};

export default PaymentProcessedPage;
