import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { useState } from "react";
import { Images } from "../../../util";
import LinkShareSection from "../LinkShareSection";
import "./BasicSingleReportSection.css";

const imagePath = "/assets/images/vehicleBrands";

const BasicSingleReportSection = ({ vehicle, services, children }) => {
  const [notFoundLogo, setNotFoundLogo] = useState(false)
  const { type, brand, model, year, estate, plate } = vehicle;
  const typeName = type == 0 ? 'CAR' : 'MOTO';
  const logoSRC = `${imagePath}/${brand.toUpperCase().replace(/( |-)/g, '_')}_${typeName}`;
  const notFoundLogoSRC = `${imagePath}/NONE_${typeName}`;
  return (
    <>
      <LinkShareSection />
      <div className="basic-single-report-container">
        <Grid container>
          <Grid container item xs={12} sm={6}>
            <div className="basic-single-report-data">
              <div className="basic-report-brand-img-container">
                {
                  notFoundLogo ? (
                    <img src={`${notFoundLogoSRC}_MOBILE.png`} />
                  ) : (
                    <img src={`${logoSRC}_MOBILE.png`} onError={() => setNotFoundLogo(true)} />
                  )
                }
              </div>
              <div className="basic-report-vehicle-description" >
                <p className="basic-report-vehicle-brand" >
                  {`${brand.toUpperCase()} ${model.toUpperCase()} ${year}`}
                </p>
                <p className="basic-report-vehicle-plate">
                  {`Placas: ${plate}`}
                </p>
                <div className="basic-report-vehicle-location">
                  <img src={Images.LOCATION}></img>
                  <p>{estate}</p>
                  <img className="image-vehicle-type" src={type == 0 ? Images.INACTIVE_CAR : Images.INACTIVE_MOTO}></img>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} sm={6} rowSpacing={1} flexDirection={'column'}>
            {
             services.map(({name, value, status}) => (
                <Grid item>
                  <div className={`basic-report-service-container ${value == null ? 'disabled' : ''}`}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item style={{ paddingLeft: '5px' }}>
                        <img src={value == null ? Images.ALERTA_MI_AUTO_GRAY : Images.ALERTA_MI_AUTO_GREEN} alt="alertamiauto logo" />
                      </Grid>
                      <Grid item style={{ margin: '0 auto 0 10px' }}>
                        <span className='basic-report-service-name'>{name}: </span>
                        <span className={`basic-report-service-status ${value == null ? '' : value ? 'success' : 'danger'}`}>
                          {value == null ? 'No aplica' : status[value ? 0 : 1]}
                        </span>
                      </Grid>
                      <Grid item style={{ paddingRight: '5px' }}>
                        {
                          value == null ? '' : value ? (
                            <FontAwesomeIcon className='basic-report-service-icon-success' icon='check' />
                          ) : (
                            <FontAwesomeIcon className='basic-report-service-icon-danger' icon='times-circle' />
                          )
                        }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
        {
          children
        }
      </div>
    </>
  );
};

export default BasicSingleReportSection;
