import { CircularProgress } from "@mui/material";
import parse from "html-react-parser";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "../contexts";
import BeforeExitMessage from "../helpers/BeforeExitMessage";
import { appTypes } from "../reducers/types";

import { Images } from "../util";
import "./ProcessingPaymentPage.css";

const ProcessingPaymentPage = ({ history }) => {
  const { app: { user }, dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: true,
        burger: true
      }
    });
    dispatch({
      type: appTypes.setTitles,
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      history.push("/payment_processed")
    }, 3000);
  }, [])

  return (
    <div className="processing-payment-container">
      <div className="processing-payment-content-img">
        <img src={Images.PROCESSING_PAYMENT}></img>
      </div>
      <div className="processing-payment-content-title">
        <p>Estamos procesando tu pago.<br /><span>Espera un momento</span></p>
      </div>
      <div className="processing-payment-content-email">
        <CircularProgress size={70} color='inherit' />
      </div>
      <div className="processing-payment-content-subtitle">
        <p>Por favor no actualices ni cierres esta página.</p>
      </div>
      <div className="processing-payment-content-footer">
        <img
          className="processing-payment-footer-img"
          src={Images.CONEKTA_SECURE}
          alt="stripe"
        />
      </div>
      <BeforeExitMessage />
    </div>
  );
};

export default ProcessingPaymentPage;
