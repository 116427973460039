import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../util";
import "./HeaderSection.css";
import { AppContext } from "../../contexts";
import { Box, Drawer, List, ListItem, ListItemText, Divider, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToElement } from "../../util/helpers";
import { userTypes } from "../../reducers/types";

const HeaderSection = () => {
  const {
    app: {
      header: { fixed, listMenu, burger, alerts, agentPhoto, contract_alert },
      home: { header, doubts, footer },
      user
    }, dispatch
  } = useContext(AppContext);
  const history = useHistory();
  const [showFixedClass, setFixedClass] = useState(false);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)
  const [activeTab, setActiveTab] = useState("header-section");
  const [showHeadbread, setShowHeadbread] = useState(true)
  let current_view = history.location.pathname.replace('/', '');
  current_view = current_view == '' ? 'home' : current_view;
  const menu = [
    {
      label: 'Inicio',
      page: "/",
      section: header
    },
    {
      label: 'Contratar Alerta',
      page: "/contratar_alerta",
    },
    {
      label: 'Blog',
      page: "/blog",
    },
    {
      label: 'Preguntas Frecuentes',
      page: "/",
      section: doubts,
      offset: 130
    },
    {
      label: 'Contacto',
      onClick: () => window.location.href = "mailto:hola@alertamiauto.com"
    },
  ]

  const hambugerMenu = [
    {
      label: 'Inicio',
      page: "/",
      add: true,
      section: header
    },
    {
      label: 'Mis Alertas',
      page: "/alertas",
      add: alerts,
    },
    {
      label: 'Contratar Alerta',
      page: "/contratar_alerta",
      add: true,
    },
    {
      label: 'Notificaciones',
      page: "/config_notifications",
      add: user && true,
    },
    {
      label: 'Blog',
      page: "/blog",
      add: true,
    },
    {
      label: 'Preguntas Frecuentes',
      page: "/doubts",
      add: true,
    },
    {
      label: 'Términos y Condiciones',
      page: "/terminos_condiciones",
      add: true,
    },
    {
      label: 'Políticas de Privacidad',
      page: "/aviso_privacidad",
      add: true,
    },
    {
      label: 'Contacto',
      page: "/",
      add: true,
      onClick: () => window.location.href = "mailto:hola@alertamiauto.com"
    },
  ]

  useEffect(() => {
    addEventListener('scroll', () => {
      setFixedClass(!(window.scrollY == 0))
    })
  }, []);

  const handlerMenuClick = (page, element, offset = 0) => {
    history.push(page);
    scrollToElement(element, offset)
  }

  const handlerLogout = () => {
    history.push('/');
    dispatch({ type: userTypes.deleteUser });
  }

  return (
    <>
      <div ref={header} id="header-section" className={`header-container ${current_view + '-view'} ${(fixed && showFixedClass) ? "fixed-position" : ''}`} >
        <header>
          {
            showHeadbread && (
              <div className="headband">
                <img
                  className="logo-alerta-mi-casa"
                  src={Images.LOGO_ALERTA_MICASA}
                  // onClick={() => window.open("https://www.alertainmobiliaria.com/")}
                />
                <span>
                  Protege tu propiedad y evita fraudes inmobiliarios: <b>alertamicasa.com</b> (Próximamente)
                  {/* <img
                    className="arrow"
                    onClick={() => window.open("https://www.alertainmobiliaria.com/")}
                    src={Images.ARROW_RIGHT_HEADER}
                  /> */}
                </span>
                <FontAwesomeIcon className="close-alert" icon="times" onClick={() => setShowHeadbread(false)} />
              </div>
            )
          }
          <div className="header-menu">
            <Link to="/" className="alerta-icon-container">
              <img className="alerta_icon" src={Images.LOGO_ALERTA_BLACK} alt="alerta_icon" />
            </Link>
            {/* <div className="back-container mobile">
              <img className="back-image" onClick={() => history.goBack()} src={Images.ARROW_BACK} />
            </div> */}
            <nav className="primary-menu">
              <ul className="menu">
                {
                  listMenu && (
                    menu.map(({ label, page, section, offset, onClick }) => (
                      <li key={'menuHeaderSection' + label} className="desktop">
                        <a className={activeTab == section ? "selected" : ''} onClick={() => onClick ? onClick() : handlerMenuClick(page, section, offset)}>
                          {label}
                        </a>
                      </li>
                    ))
                  )
                }
                {
                  alerts && (
                    <li key={'menu-alertas'} className="button my-alerts-button">
                      <Link to="/alertas">
                        Mis Alertas
                      </Link>
                    </li>
                  )
                }
                {
                  contract_alert && (
                    <li key={'menu-contract-alert'} className="button contract-alert-button desktop">
                      <Link to="/contratar_alerta">
                        Contratar una Alerta
                      </Link>
                    </li>
                  )
                }
                {
                  burger && (
                    <li key={'menu-hamburger-menu'} className="hamburger-menu-button">
                      <img className="hamburger-menu" src={Images.BURGER_MENU} onClick={() => setShowBurgerMenu(true)} />
                    </li>
                  )
                }
              </ul>
            </nav>
          </div>
          {/* {
            agentPhoto && (
              <div className="header-agent-content">
                <img src={Images.AGENT}></img>
              </div>
            )
          } */}
        </header>
      </div>
      {
        burger && (
          <Drawer className="hamburger-menu-container" anchor='right' open={showBurgerMenu} onClose={() => setShowBurgerMenu(false)}>
            <Box sx={{ width: 300 }} role="presentation"
              onClick={() => setShowBurgerMenu(false)}
              onKeyDown={() => setShowBurgerMenu(false)}
            >
              <>
                <h1 className="hamburger-menu-title">Menú</h1>
                <List className="hamburger-menu-list">
                  {
                    hambugerMenu.map(({ label, page, section, add, onClick }, i) => (
                      add && (
                        <>
                          <ListItem buttonkey={`hamburgerMenu${label}HeaderSection`} >
                            <ListItemText primary={label} className="" onClick={() => onClick ? onClick() : handlerMenuClick(page, section)} style={{paddingLeft: '10px'}} />
                          </ListItem>
                          <Divider />
                        </>
                      )
                    ))
                  }
                </List>
                {
                  user && (
                    <Button className="alerta-logout-button" onClick={() => handlerLogout()}>
                      Cerrar Sesión
                    </Button>
                  )
                }
              </>
            </Box>
          </Drawer>
        )
      }
    </>
  );
};

export default HeaderSection;