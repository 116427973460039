import { Grid } from '@mui/material'
import { Images } from '../../util'
import "./HomeCompaniesSection.css"

const companies = [
  // { alt: 'TV_AZTECA', img: Images.TV_AZTECA },
  { alt: 'AUTOMOVIL', img: Images.AUTOMOVIL },
  { alt: 'REFORMA', img: Images.REFORMA },
  // { alt: 'ADN40', img: Images.ADN40 },
  { alt: 'AUTOCOSMOS', img: Images.AUTOCOSMOS },
  { alt: 'EL_UNIVERSAL', img: Images.EL_UNIVERSAL },
  { alt: 'GRUPO_FORMULA', img: Images.GRUPO_FORMULA },
  { alt: 'MILENIO', img: Images.MILENIO }
]
const HomeCompaniesSection = () => {
  return (
    <div className="companies-home-section desktop">
      <Grid container justifyContent="space-between" alignItems="center">
        {
          companies.map(({ alt, img }) => <img src={img} alt={alt} />)
        }
      </Grid>
    </div>
  )
}

export default HomeCompaniesSection
