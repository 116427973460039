import React, { useContext, useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import "./InsurancePage.css";

var parse = require("html-react-parser");

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Images } from "../util";
import { useSelector } from "react-redux";
import FooterSection from "../component/sections/FooterSection";
import { AppContext } from "../contexts";
import { appTypes } from "../reducers/types";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: "0px 0px 2px 0px",
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#37c1e6",
        borderColor: "#37c1e6",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
}))(Switch);

const Login_Active_Button = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    color: "#ffffff",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    // backgroundColor: "#228397",
    backgroundColor: "#D3D3D3",
    borderColor: "#D3D3D3",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#D3D3D3",
      borderColor: "#D3D3D3",
      boxShadow: "none",
      color: "#ffffff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#D3D3D3",
      borderColor: "#D3D3D3",
      color: "#ffffff",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const BorderCircleProgress = withStyles({
  circle: {
    strokeLinecap: "round",
    color: "#FFFFFF",
  },
})(CircularProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

var years = [];
var carYear = [];
var days = [];

const InsurancePage = ({ history }) => {
  let { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: appTypes.setMenu,
      payload: {
        fixed: false,
        alerts: false,
        contract_alert: true,
        burger: true,
        agentPhoto: false
      }
    });
    dispatch({
      type: appTypes.setTitles,
      payload: {
        title: `¿Necesitas un <span>Seguro?</span><br> Te ayudamos a conseguir la mejor cotización`,
        subtitle: '¡Ahorra en tu Seguro! Cotiza ahora mismo y ahorra tiempo y dinero con el mejor Seguro de autos y motos.',
        back: () => {
          history.goBack()
        }
      }
    });
  }, [])


  const texts = useSelector((store) => store.page.additional_services.insurance);
  const [plate, setPlate] = useState("");
  const [mileage, setMileage] = useState(0);
  const [carPlate, setCarPlate] = useState(null);

  const [hologram, setHologram] = useState(null);
  const [circulationCardValidity, setCirculationCardValidity] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [sectionPlates, setSectionPlates] = useState(true);
  const [sectionHologram, setSectionHologram] = useState(false);
  const [sectionCirculation, setSectionCirculation] = useState(false);
  const [sectionService, setSectionService] = useState(false);
  const [sectionInsurance, setSectionInsurance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activateSpinner, setActivateSpinner] = useState(false);
  const [age, setAge] = React.useState(10);
  const [state, setState] = React.useState({
    checkedC: false,
    newCar: false,
  });
  const [validate, setValidate] = useState(true);
  const [dataCar, setDataCar] = React.useState({
    plate: "",
    brand: "",
    model: "",
    version: "",
    year: "",
  });
  const [dataHologram, setDataHologram] = React.useState({
    hologram: "",
    month: 0,
    year: 0,
  });
  const [dataService, setDataService] = React.useState({
    day: 0,
    month: 0,
    year: 0,
    mileage: "",
    newCar: false,
  });
  const [selectedState, setSelectedState] = useState("");
  const [startModule, setStartModule] = useState(false);

  const [brand, setBrand] = useState([]);
  const [model, setModel] = useState([]);
  const [versions, setVersions] = useState([]);
  const [allBrand, setAllBrand] = useState([]);
  const [allModel, setAllModels] = useState([]);
  const [allVersion, setAllVersion] = useState([]);

  const [versionCar, setVersionCar] = useState("");
  const [type, setType] = useState("car");

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const classes = useStyles();

  const saveCar = async (e) => {
    if (dataService.newCar === true) {
      setDataService({ ...dataService, mileage: 0 });
    }

    if (plate.length === 0) {
      return;
    }
    if (dataService.day === 0) {
      return;
    }
    if (dataService.month === 0) {
      return;
    }
    if (dataService.year === 0) {
      return;
    }
    if (dataService.mileage.length === 0) {
      return;
    }

    if (dataHologram.hologram.length === 0) {
      return;
    }
    if (dataHologram.month.length === 0) {
      return;
    }
    if (dataHologram.year === 0) {
      return;
    }

    let body = {
      plate: plate,
      // lastServiceDate: `${dataService.year}-${dataService.month}-${dataService.day}`,
      lastServiceDate: `${dataService.year}-09-${dataService.day}`,
      mileage: dataService.mileage,
      newVehicle: dataService.newCar,
      // lastVerificationDate: `${dataHologram.year}-${dataHologram.month}-01`,
      lastVerificationDate: `${dataHologram.year}-09-01`,
      verificationHologram: dataHologram.hologram,
      version: versionCar,
      completed: true,
    };

    let alertId = JSON.parse(sessionStorage.getItem("alert")).id;

    const register = await carService.registerCar(body, alertId);

    history.push("/insurance_recived");
    return;
    e.preventDefault();

    setActivateSpinner(true);
    setTimeout(() => {
      setActivateSpinner(false);
    }, 3000);
  };

  const CssTextFieldError = useMemo(() => {
    return withStyles({
      root: {
        "& label.Mui-focused": {
          color: "#CA0F4A",
        },

        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#CA0F4A",
          },
        },
        "& .MuiFormHelperText-root": {
          color: "#CA0F4A",
          margin: "0px",
          textAlign: "end",
        },
      },
    })(TextField);
  }, []);

  const CssTextField = useMemo(() => {
    return withStyles({
      root: {
        "& label.Mui-focused": {
          color: "#383881",
        },

        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#383881",
          },
        },
        "& .MuiFormHelperText-root": {
          color: "#CA0F4A",
          margin: "0px",
          textAlign: "end",
        },
      },
    })(TextField);
  }, []);

  const searchPlate = () => {
    if (plate) {
      setSectionPlates(true);
      setLoading(true);

      setTimeout(() => {
        setCarPlate({
          marca: "Honda2",
          modelo: "PILOT3",
          añoModelo: "2012",
          clase: "Camioneta",
          tipo: "SUV",
          version: "EX, T. AUT., 6CIL., 240 HP, A",
        });
        setLoading(false);
      }, 3000);
    } else {
      setSectionPlates(false);
    }
  };

  const handleChange = (event) => {
    setDataService({ ...dataService, newCar: event.target.checked });
    setState({ ...state, checkedC: event.target.checked });
  };

  const handleChangeSelect = (event, code) => {
    switch (code) {
      case "hologramMonth":
        setDataHologram({ ...dataHologram, month: event.target.value });
        break;
      case "hologramYear":
        setDataHologram({ ...dataHologram, year: event.target.value });
        break;
      case "serviceDay":
        setDataService({ ...dataService, day: event.target.value });
        break;
      case "serviceMonth":
        setDataService({ ...dataService, month: event.target.value });
        break;
      case "serviceYear":
        setDataService({ ...dataService, year: event.target.value });
        break;

      default:
        break;
    }
  };

  const saveVersionCar = async (event) => {
    let version = event.target.outerText;
    let versionId = allVersion.find((item) => item.name === version);
    setVersionCar(versionId.id);
  };

  const searchVersion = async (event) => {
    let model = event.target.outerText;
    let modelId = allModel.find((item) => item.name === model);

    const getVersion = await carService.getCarVersion(modelId.id);

    let tempVersion = getVersion.response.data.result;
    setAllVersion(tempVersion);
    let listVersion = [];
    tempVersion.map((item) => {
      listVersion.push(item.name);
    });
    setVersions(listVersion);
  };

  const searchModel = async (event) => {
    if (dataCar.brand.length === 0) {
      return;
    }

    let brandId = allBrand.find((item) => item.name === dataCar.brand);

    let yearCar = event.target.outerText;

    const getModels = await carService.getCarModel(brandId.id, yearCar);

    let tempModel = getModels.response.data.result;

    setAllModels(tempModel);

    let listModel = [];

    tempModel.map((item) => {
      listModel.push(item.name);
    });

    setModel(listModel);
  };

  const getBrands = async () => {
    try {
      const brands = await carService.getCarBrands();

      let tempBrand = brands.response.data.result;
      setAllBrand(tempBrand);
      let list = [];
      tempBrand.map((item) => {
        list.push(item.name);
      });
      setBrand(list);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const renderInsurer = (img) => {
    return (
      <Grid className="insurance-container-logo">
        <img src={img}></img>
      </Grid>
    );
  };

  useEffect(() => {
    if (!startModule) {
      setStartModule(true);
      for (
        let i = new Date().getFullYear() - 20, len = new Date().getFullYear();
        i <= len;
        i++
      ) {
        years.push(i);
        carYear.push(JSON.stringify(i));
      }
      for (let a = 1; a <= 31; a++) {
        days.push(JSON.stringify(a));
      }
      years.reverse();
      carYear.reverse();

      getBrands();
    }
  });

  return (
    <>
      <div className="insurance-container">
        <div style={{ position: "relative" }}>
          <Grid item xs={12} container>
            <Grid
              item
              xs={3}
              style={{ marginRight: "15px" }}
              onClick={() => {
                setType("car");
              }}
            >
              <CardContent
                className={
                  type === "car"
                    ? "insurance-content-active"
                    : "insurance-content-inactive"
                }
              >
                <Typography className="insurance-title-images">
                  <img
                    className="card-img-car"
                    src={type === "car" ? Images.ACTIVE_CAR : Images.INACTIVE_CAR}
                  ></img>
                </Typography>
                <Typography className="insurance-title">
                  {/* {e.state} */}
                  Seguro de Auto / SUV
                </Typography>
              </CardContent>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => {
                setType("moto");
              }}
            >
              <CardContent
                className={
                  type === "moto"
                    ? "insurance-content-active"
                    : "insurance-content-inactive"
                }
              >
                <Typography className="insurance-title-images">
                  <img
                    className="card-img-car"
                    src={
                      type === "moto" ? Images.ACTIVE_MOTO : Images.INACTIVE_MOTO
                    }
                  ></img>
                </Typography>
                <Typography className="insurance-title">
                  {/* {e.state} */}
                  Seguro de Moto
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Grid
            container
            className={
              sectionPlates
                ? "insurance-section-container active"
                : "insurance-section-container inactive"
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {validate === false ? (
                  <>
                    <CssTextFieldError
                      variant="outlined"
                      margin="normal"
                      label="Número de placa"
                      className="insurance-email"
                      onChange={(text) => {
                        setPlate(text.target.value.toUpperCase());
                      }}
                      inputProps={{
                        style: {
                          paddingLeft: 20,
                          fontSize: "16px",
                          color: "#434362",
                        },
                      }}
                      value={plate}
                    />
                    <label className="insurance-label-error ">
                      <img src={Images.ERROR_LABEL_ICON}></img>
                      No encontramos el correo en nuestro registro
                    </label>
                  </>
                ) : (
                  <>
                    <CssTextField
                      variant="outlined"
                      margin="normal"
                      label="Número de placa"
                      className="insurance-email"
                      onChange={(text) => {
                        setPlate(text.target.value.toUpperCase());
                      }}
                      inputProps={{
                        style: {
                          paddingLeft: 20,
                          fontSize: "16px",
                          color: "#434362",
                        },
                      }}
                      value={plate}
                    />
                  </>
                )}
                {loading ? (
                  <button
                    className="insurance-search-plates-button-loading"
                    disabled={loading ? true : false}
                  >
                    <p>Buscando</p>
                    <BorderCircleProgress size={20} />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      searchPlate();
                    }}
                    className="insurance-search-plates-button"
                    disabled={loading ? true : false}
                  >
                    <p>Buscar</p>
                  </button>
                )}
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <p className="insurance-switch-text">Registro manual</p>
                <AntSwitch
                  className="insurance-switch"
                  checked={state.checkedC}
                  onChange={handleChange}
                  name="checkedC"
                />
              </Grid>
            </Grid>
            {state.checkedC ? (
              <>
                <Grid
                  className="register-car-detail-plates-container"
                  container
                  spacing={2}
                >
                  <Grid item container xs={6}>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-brand"
                        classes={(classes, { paper: classes.paper })}
                        options={brand}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            brand: event.target.outerText,
                          });
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Marca"
                              variant="outlined"
                              value={dataCar.brand}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  brand: event.target.outerText,
                                });
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-car-years"
                        classes={(classes, { paper: classes.paper })}
                        options={carYear}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            year: event.target.outerText,
                          });
                          searchModel(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Año"
                              variant="outlined"
                              value={dataCar.year}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  year: event.target.outerText,
                                });
                                searchModel(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Año Modelo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={age}
                      onChange={handleChange}
                      label="Año Modelo"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-model"
                        classes={(classes, { paper: classes.paper })}
                        options={model}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            model: event.target.outerText,
                          });
                          searchVersion(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Modelo"
                              variant="outlined"
                              value={dataCar.model}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  model: event.target.outerText,
                                });
                                searchVersion(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-version"
                        classes={(classes, { paper: classes.paper })}
                        options={versions}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            version: event.target.outerText,
                          });
                          saveVersionCar(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Version"
                              variant="outlined"
                              value={dataCar.version}
                              onChange={(event) => {
                                setSelectedState(event.target.outerText);
                                saveVersionCar(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Versión
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={age}
                      onChange={handleChange}
                      label="Versión"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} className="insurance-data-contact">
                  <p>Datos de conductor del vehículo</p>
                </Grid>
                <Grid
                  className="insurance-plates-container"
                  container
                  spacing={2}
                >
                  <Grid item container xs={6}>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-brand"
                        classes={(classes, { paper: classes.paper })}
                        options={brand}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            brand: event.target.outerText,
                          });
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Genero"
                              variant="outlined"
                              value={dataCar.brand}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  brand: event.target.outerText,
                                });
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-car-years"
                        classes={(classes, { paper: classes.paper })}
                        options={carYear}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            year: event.target.outerText,
                          });
                          searchModel(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Número celular (10 dígitos)"
                              variant="outlined"
                              value={dataCar.year}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  year: event.target.outerText,
                                });
                                searchModel(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-model"
                        classes={(classes, { paper: classes.paper })}
                        options={model}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            model: event.target.outerText,
                          });
                          searchVersion(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Edad (Años)"
                              variant="outlined"
                              value={dataCar.model}
                              onChange={(event) => {
                                setDataCar({
                                  ...dataCar,
                                  model: event.target.outerText,
                                });
                                searchVersion(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mb-15">
                      <Autocomplete
                        id="combo-box-version"
                        classes={(classes, { paper: classes.paper })}
                        options={versions}
                        // getOptionLabel={(option) => option.title}
                        fullWidth
                        onChange={(event) => {
                          setDataCar({
                            ...dataCar,
                            version: event.target.outerText,
                          });
                          saveVersionCar(event);
                        }}
                        renderInput={(params) => {
                          return (
                            <CssTextField
                              {...params}
                              label="Código Postal"
                              variant="outlined"
                              value={dataCar.version}
                              onChange={(event) => {
                                setSelectedState(event.target.outerText);
                                saveVersionCar(event);
                              }}
                              fullWidth
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  className="insurance-button-container"
                >
                  <Login_Active_Button
                    onClick={saveCar}
                    style={{
                      backgroundColor: "#5A549D",
                    }}
                    className="insurance-button"
                    type="submit"
                  >
                    <span className="insurance-button-text">Cotizar Seguro</span>
                    {activateSpinner ? (
                      <span className="insurance-button-progress">
                        <BorderCircleProgress size={30} />
                      </span>
                    ) : null}
                  </Login_Active_Button>
                </Grid>
              </>
            ) : (
              <>
                {carPlate !== null ? (
                  <>
                    <Grid
                      className="insurance-plates-container"
                      container
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <Grid className="insurance-plates-content" item xs={12}>
                          <p className="insurance-plates-title">Marca:</p>
                          <p className="insurance-plates-text">
                            {carPlate ? carPlate.marca : "N/A"}
                          </p>
                        </Grid>
                        <Grid className="insurance-plates-content" item xs={12}>
                          <p className="insurance-plates-title">Modelo:</p>
                          <p className="insurance-plates-text">
                            {carPlate ? carPlate.modelo : "N/A"}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid className="insurance-plates-content" item xs={12}>
                          <p className="insurance-plates-title">Año Modelo:</p>
                          <p className="insurance-plates-text">
                            {carPlate ? carPlate.añoModelo : "N/A"}
                          </p>
                        </Grid>
                        <Grid className="insurance-plates-content" item xs={12}>
                          <p className="insurance-plates-title">Versión:</p>
                          <p className="insurance-plates-text">
                            {carPlate ? carPlate.version : "N/A"}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="insurance-data-contact">
                      <p>Datos de conductor del vehículo</p>
                    </Grid>
                    <Grid
                      className="insurance-plates-container"
                      container
                      spacing={2}
                    >
                      <Grid item container xs={6}>
                        <Grid item xs={12} className="mb-15">
                          <Autocomplete
                            id="combo-box-brand"
                            classes={(classes, { paper: classes.paper })}
                            options={brand}
                            // getOptionLabel={(option) => option.title}
                            fullWidth
                            onChange={(event) => {
                              setDataCar({
                                ...dataCar,
                                brand: event.target.outerText,
                              });
                            }}
                            renderInput={(params) => {
                              return (
                                <CssTextField
                                  {...params}
                                  label="Genero"
                                  variant="outlined"
                                  value={dataCar.brand}
                                  onChange={(event) => {
                                    setDataCar({
                                      ...dataCar,
                                      brand: event.target.outerText,
                                    });
                                  }}
                                  fullWidth
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} className="mb-15">
                          <Autocomplete
                            id="combo-box-car-years"
                            classes={(classes, { paper: classes.paper })}
                            options={carYear}
                            // getOptionLabel={(option) => option.title}
                            fullWidth
                            onChange={(event) => {
                              setDataCar({
                                ...dataCar,
                                year: event.target.outerText,
                              });
                              searchModel(event);
                            }}
                            renderInput={(params) => {
                              return (
                                <CssTextField
                                  {...params}
                                  label="Número celular (10 dígitos)"
                                  variant="outlined"
                                  value={dataCar.year}
                                  onChange={(event) => {
                                    setDataCar({
                                      ...dataCar,
                                      year: event.target.outerText,
                                    });
                                    searchModel(event);
                                  }}
                                  fullWidth
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={6}>
                        <Grid item xs={12} className="mb-15">
                          <Autocomplete
                            id="combo-box-model"
                            classes={(classes, { paper: classes.paper })}
                            options={model}
                            // getOptionLabel={(option) => option.title}
                            fullWidth
                            onChange={(event) => {
                              setDataCar({
                                ...dataCar,
                                model: event.target.outerText,
                              });
                              searchVersion(event);
                            }}
                            renderInput={(params) => {
                              return (
                                <CssTextField
                                  {...params}
                                  label="Edad (Años)"
                                  variant="outlined"
                                  value={dataCar.model}
                                  onChange={(event) => {
                                    setDataCar({
                                      ...dataCar,
                                      model: event.target.outerText,
                                    });
                                    searchVersion(event);
                                  }}
                                  fullWidth
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} className="mb-15">
                          <Autocomplete
                            id="combo-box-version"
                            classes={(classes, { paper: classes.paper })}
                            options={versions}
                            // getOptionLabel={(option) => option.title}
                            fullWidth
                            onChange={(event) => {
                              setDataCar({
                                ...dataCar,
                                version: event.target.outerText,
                              });
                              saveVersionCar(event);
                            }}
                            renderInput={(params) => {
                              return (
                                <CssTextField
                                  {...params}
                                  label="Código Postal"
                                  variant="outlined"
                                  value={dataCar.version}
                                  onChange={(event) => {
                                    setSelectedState(event.target.outerText);
                                    saveVersionCar(event);
                                  }}
                                  fullWidth
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      justify="center"
                      className="insurance-button-container"
                    >
                      <Login_Active_Button
                        onClick={saveCar}
                        style={{
                          backgroundColor: "#5A549D",
                        }}
                        className="insurance-button"
                        type="submit"
                      >
                        <span className="insurance-button-text">
                          Cotizar Seguro
                        </span>
                        {activateSpinner ? (
                          <span className="insurance-button-progress">
                            <BorderCircleProgress size={30} />
                          </span>
                        ) : null}
                      </Login_Active_Button>
                    </Grid>
                  </>
                ) : null}
              </>
            )}
          </Grid>

          <Grid item xs={12} className="insurance-title-logos">
            <p>Trabajamos con las mejores Aseguradoras de México</p>
          </Grid>

          <Grid item xs={12} container justify="space-between">
            {renderInsurer(Images.INSURER_QUALITAS)}
            {renderInsurer(Images.INSURER_GNP)}
            {renderInsurer(Images.INSURER_AXXA)}
            {renderInsurer(Images.INSURER_ANA)}
            {renderInsurer(Images.INSURER_ABA)}
          </Grid>
          <Grid item xs={12} container justify="space-between">
            {renderInsurer(Images.INSURER_MAPFRE)}
            {renderInsurer(Images.INSURER_AGUILA)}
            {renderInsurer(Images.INSURER_HDI)}
            {renderInsurer(Images.INSURER_ZURICH)}
            {renderInsurer(Images.INSURER_SURA)}
          </Grid>
          <Grid item xs={12} className="insurance-link">
            <p
              onClick={() => {
                history.push("/contratar_alerta");
              }}
            >
              Contratar una Alerta
            </p>
            <img
              src={Images.ARROW_RIGHT}
              onClick={() => {
                history.goBack();
              }}
            ></img>
          </Grid>
          <Grid item xs={12} className="insurance-note">
            <p>
              * Las Alertas se renuevan automáticamente cada año y se pueden{" "}
              <br />
              cancelar en cualquier momento
            </p>
          </Grid>

        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default InsurancePage;
