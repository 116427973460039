import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PenalityCDMXSection from './penality/PenalityCDMXSection';
import PenalityEDOMEXSection from './penality/PenalityEDOMEXSection';

const PenalityEstateServiceSection = ({ name, status, multas }) => {
  const noPenality = multas.length != 0 && multas[0].situacion == undefined ? true : multas.length == 0;
  if (status && multas[0].situacion)
    multas = multas.filter(({ situacion }) => situacion.includes(status))

  return (
    <>
      <div className="service-main-title sticky-header">
        <span>Infracciones en {name}</span>
      </div>
      {
        noPenality ? (
          <div className="success-content-section">
            <p className="success-title">
              <span>Tu vehículo no presenta infracciones en {name}</span>
              <FontAwesomeIcon icon="check-circle" size="lg" />
            </p>
          </div>
        ) : (
          multas.map((multa) => (
            <div className="service-main-content">
              {multa.folio ? <PenalityCDMXSection multa={multa} /> : <PenalityEDOMEXSection multa={multa} />}
            </div >
          ))
        )
      }
      <div className="service-main-footer">
        <p className="footer-content">
          Si usted desea corregir o aclarar un pago ya realizado, puede comunicarse
          a la Administración Tributaria del Estado más cercana a su domicilio.
        </p>
        <p className="footer-content">
          La presente información es mostrada sin perjuicio de las facultades de
          comprobación de las autoridades fiscales.
        </p>
      </div>
    </>
  )
}

export default PenalityEstateServiceSection