import { useFormContext } from 'react-hook-form';
import SetVehicleHologramSection from './SetVehicleHologramSection';
import './ServiceMainSection.css';

const UpdateVerificationServiceMainSection = () => {
  const { handleSubmit } = useFormContext()
  return (
    <div className="service-main-content">
      <div className="update-no-circulate">
        <p>
          ¿Ya verificaste tu auto? <br />
          Actualiza tu holograma y la fecha de verificación
        </p>
        <form onSubmit={handleSubmit} >
          <SetVehicleHologramSection />
        </form>
      </div>
    </div >
  )
};

export default UpdateVerificationServiceMainSection
