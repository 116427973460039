import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NoPenalityEnvironmentalSanctionSection = () => {
  return (
    <div className="success-content-section">
      <p className="success-title">
        <span>Tu vehículo no presenta sanciones en la Ciudad de México</span>
        <FontAwesomeIcon icon="check-circle" size="lg" />
      </p>
    </div>
  )
}

export default NoPenalityEnvironmentalSanctionSection