import { Button, Dialog, Grid } from "@mui/material";
import { Images } from "../../util";
import './ForeignVehicleDialog.css';

const ForeignVehicleDialog = ({ state: { open, data = {} }, onSuccess, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="foreign-vehicle-dialog"
    >
      <div className="foreign-vehicle-dialog-content" >
        <Grid container rowSpacing={2} direction={"column"} >
          <Grid item >
            <img src={Images.PURPLE_PLATE} alt="" />
          </Grid>
          <Grid item >
            <p className="foreign-vehicle-dialog-title">Tu placa es de {data.plateEstate}</p>
          </Grid>
          <Grid item >
            <p className="foreign-vehicle-dialog-subtitle">Hemos identificado que la registraste en {data.vehicleEstate}.</p>
            <p className="foreign-vehicle-dialog-subtitle">¿Quieres recibir las notificaciones de {data.vehicleEstate}? </p>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button className="alerta-purple-button" onClick={() => { onSuccess(data) }}>
              <span className="alerta-purple-button-text">Continuar</span>
            </Button>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button className="alerta-white-button" onClick={() => { onClose() }} style={{ marginBottom: '20px' }}>
              <span className="alerta-white-button-text">Regresar</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default ForeignVehicleDialog
