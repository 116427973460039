const TenueCDMXSection = ({ year, data, buttons }) => {
  const paymentFormatPre = 'https://data.finanzas.cdmx.gob.mx/formato_lc/formatos/FormatoGeneral/Genera?';
  const {
    tenencia, actualiza_ten, recargo_ten, total_tenencia,
    derecho, actuliza_derecho, recargo_derecho, total_derechos,
    subsidio, actualizacion, recargos,
    lineacaptura, vigencia, total,
  } = data;
  const concepts = [
    { label: 'Tenencia', amount: `$ ${tenencia}` },
    { label: 'Actualización Tenencia', amount: `$ ${actualiza_ten}` },
    { label: 'Recargo Tenencia', amount: `$ ${recargo_ten}` },
    { label: 'Total Tenencia', amount: `$ ${total_tenencia}` },
    { space: 20 },
    { label: 'Derechos', amount: `$ ${derecho}` },
    { label: 'Actualización Derechos', amount: `$ ${actuliza_derecho}` },
    { label: 'Recargo Derechos', amount: `$ ${recargo_derecho}` },
    { label: 'Total Derechos', amount: `$ ${total_derechos}` },
    { space: 20 },
    { label: 'TOTAL DEL CÁLCULO' },
    { space: 10 },
    { label: 'Impuesto', amount: `$ ${tenencia}` },
    { label: 'Subsidio', amount: `$ ${subsidio}` },
    { label: 'Derechos', amount: `$ ${derecho}` },
    { label: 'Actualización', amount: `$ ${actualizacion}` },
    { label: 'Recargos', amount: `$ ${recargos}` },
    { label: 'Total a pagar', amount: `$ ${total}` },
    { space: 20 },
    { label: 'Línea de captura', amount: lineacaptura },
    { label: 'Valido hasta', amount: vigencia },
  ];
  return (
    <>
      {
        buttons && (
          <p className="content-title">Adeudos {year}</p>
        )
      }
      {
        concepts.map(({ label, amount, space }, i) => (
          space ? (
            <div style={{ height: `${space}px` }}></div>
          ) : (
            <div key={`tenure-concepts-${i}`} className="content-section">
              <span className="main-label">{label}</span>
              {amount && <span className="main-variable">{amount}</span>}
            </div>
          )
        ))
      }
      {
        buttons && (
          <>
            <div className="content-section verification-done-button-container">
              <button
                onClick={() => window.open(`${paymentFormatPre}
                          lc=${data.lineacaptura}
                          &dag_id=${data.dagid}
                          &lcCB=${data.lineacapturaCB}
                          &aniob=${moment(data.vigencia).year()}`,
                  '_blank')}
                className="main-button"
              >
                Descargar Formato de Pago
              </button>
            </div>
            <div className="content-section verification-done-button-container">
              <form action='https://data.finanzas.cdmx.gob.mx/pagos/confirmaPagoTenencia' target='_blank' method='POST'>
                <input type="hidden" name="ejercicio" value={data.ejercicio} />
                <input type="hidden" name="lineacaptura" value={data.lineacaptura} />
                <input type="hidden" name="dagid" value={data.dagid} />
                <input type="hidden" name="lineacapturaCB" value={data.lineacapturaCB} />
                <input type="hidden" name="placas" value={data.placas} />
                <input type="hidden" name="fechavencimiento" value={data.fechavencimiento} />
                <input type="hidden" name="total" value={data.total} />
                <input type="hidden" name="lc" value={data.lc} />
                <button
                  className="main-button"
                  type='submit'
                >
                  Pago en Línea
                </button>
              </form>

            </div>
          </>
        )
      }

    </>
  )
}

export default TenueCDMXSection